import { PureComponent } from 'react';

import { observable } from 'mobx';
import { observer } from 'mobx-react';
import cn from 'classnames';
import moment from 'moment';
import dayjs from 'dayjs';

import withNavigateParams from '../../utils/withNavigateParams';
import { NavigateFunction, Outlet } from 'react-router';
import chains from '../../stores/chains';

import { Sorting } from '../../web-types/Sorting';

import './style.scss';
import { Button, DatePicker, Input, Select } from 'antd';
import { TableController } from '../../controls/TableController';
import { autobind } from 'core-decorators';
import { manager_downloadChainReviewsUrl, manager_fetchChainReviews } from '../../lib/api-web/reviews';
import { DownloadOutlined } from '@ant-design/icons';
import formatMoney from '../../utils/formatMoney';

const { RangePicker } = DatePicker;

@observer
class ChainTipsAndReviewsPage extends PureComponent<{ navigate: NavigateFunction; params: any; state: any }> {
	@observable contentVisible = false;
	@observable total: number | null = null;
	@observable params: { query: string; startDate: string | null; endDate: string | null; rating: number | null } = {
		query: '',
		rating: null,
		startDate: null,
		endDate: null,
	};
	sorting: Sorting | null = null;

	@observable employeeInviting = false;

	@autobind
	handleTablePropsUpdate(contentVisible: boolean, total: number | null, rowsPerPage: number) {
		this.contentVisible = contentVisible;
		this.total = total;
	}

	@autobind
	async handleLoad(
		params: { query: string; startDate: string | null; endDate: string | null; rating: number | null },
		offset: number,
		limit: number,
		sorting: Sorting | null,
	) {
		this.sorting = sorting;
		return manager_fetchChainReviews(this.props.params.chainId, offset, limit, {
			query: params.query,
			startDate: params.startDate,
			endDate: params.endDate,
			rating: params.rating,
			sorting,
		});
	}

	@autobind
	download() {
		const url = manager_downloadChainReviewsUrl(this.props.params.chainId, 0, 100, {
			query: this.params.query,
			startDate: this.params.startDate,
			endDate: this.params.endDate,
			rating: this.params.rating,
			sorting: this.sorting,
		});
		window.open(url, '_blank');
	}

	render() {
		if (this.props.params.employeeId) {
			return <Outlet />;
		}
		return (
			<div key="two" className={cn('page', { hidden: !this.contentVisible })}>
				<div className="page-head">
					<div className="page-title">
						<h2>
							Tips and reviews of {chains.chain?.name} {this.total !== null ? `(${this.total})` : ``}
						</h2>
					</div>
					<div className="page-actions">
						<Select
							style={{ width: 150, marginRight: 15 }}
							value={this.params.rating === null ? 'null' : String(this.params.rating)}
							onSelect={(val: string) => {
								this.params.rating = val === 'null' ? null : Number(val);
							}}
						>
							<Select.Option value="null">All ratings</Select.Option>
							<Select.Option value="0">Without rating</Select.Option>
							<Select.Option value="1">1-star</Select.Option>
							<Select.Option value="2">2-stars</Select.Option>
							<Select.Option value="3">3-stars</Select.Option>
							<Select.Option value="4">4-stars</Select.Option>
							<Select.Option value="5">5-stars</Select.Option>
						</Select>
						<RangePicker
							ranges={{
								'Today': [dayjs(), dayjs()],
								'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
							}}
							onChange={value => {
								if (value) {
									this.params.startDate = value[0]?.toISOString() || null;
									this.params.endDate = value[1]?.toISOString() || null;
								} else {
									this.params.startDate = null;
									this.params.endDate = null;
								}
							}}
						/>
						<Input.Search
							type="search"
							style={{ width: 150 }}
							onSearch={value => (this.params.query = value)}
							placeholder="Search review..."
						/>
						<Button onClick={this.download} icon={<DownloadOutlined />}>
							Download
						</Button>
					</div>
				</div>
				<section className="section">
					<TableController
						noSection
						// onRowClick={({ id }) => {
						// 	this.props.navigate(`/${null, this.props.params.chainId}/employees/${id}/`, {
						// 		state: { backable: true },
						// 	});
						// }}
						params={this.params}
						load={this.handleLoad}
						onTablePropsUpdate={this.handleTablePropsUpdate}
						columns={[
							{
								key: 'rating',
								title: <span style={{ fontSize: 16 }}>⭐</span>,
								sortable: true,
								titleClass: 'cell-center',
								cellClass: 'cell-center',
								render: value => (
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											fontSize: 20,
											width: 40,
											height: 40,
											borderRadius: 20,
											background: value === 4 ? '#deeec9' : '#c9eec9',
											border: '1px solid #e0e0e0',
										}}
									>
										<span style={{ color: value === 4 ? '#818f00' : '#008f07' }}>{value}</span>
									</div>
								),
								width: 64,
							},
							{
								key: 'user.avatarUrl',
								titleClass: 'cell-center',
								cellClass: 'cell-center',
								title: '',
								render: value => {
									return (
										<img
											alt=""
											src={value}
											style={{ width: 50, height: 50, borderRadius: '50%' }}
										/>
									);
								},
								width: 70,
							},
							{
								key: 'user.firstName',
								title: 'Employee name',
								sortable: true,
								render: (_, row) => `${row.user.firstName} ${row.user.lastName}`,
							},
							{
								key: 'text',
								title: 'Review',
								maxWidth: 600,
								minWidth: 120,
								sortable: true,
								render: value => value || <i>No text</i>,
							},
							{
								key: 'incomingTx.amount',
								title: 'Tip left',
								minWidth: 100,
								sortable: true,
								render: (value, row) => <>{formatMoney(value, 'AED')}</>,
							},
							{
								key: 'incomingTx.status',
								title: 'Status',
								minWidth: 100,
								sortable: true,
								render: (value, row) => <span style={{ color: value === 'routed' ? 'green' : 'red' }}>{value}</span>,
							},
							{
								key: 'created_at',
								title: 'Date',
								sortable: true,
								render: (value, row) => <>{moment(value).format('DD.MM HH:mm')}</>,
							},
						]}
					/>
				</section>
			</div>
		);
	}
}

export default withNavigateParams(ChainTipsAndReviewsPage);
