import React, { PureComponent } from 'react';

import { observer } from 'mobx-react';
import { NavigateFunction } from 'react-router';
import withNavigateParams from '../../utils/withNavigateParams';
import { observable } from 'mobx';
import {
	admin_attachPlace,
	admin_deleteChain,
	admin_fetchChain,
	manager_attachEmployeeToChain,
	manager_detachEmployeeFromChain,
	manager_updateChainName,
	manager_uploadChainAvatar,
} from '../../lib/api-web';
import toaster from '../../stores/toaster';
import AdminChainData from '../../web-types/AdminChainData';
import { Button, Menu, Popover, Spin, Tabs, Typography } from 'antd';
import {
	HomeOutlined,
	UploadOutlined,
	DeleteOutlined,
	EditOutlined,
	AppstoreAddOutlined,
	UserOutlined,
	CrownOutlined,
	UsergroupAddOutlined,
} from '@ant-design/icons';
import { TPage, TPContent, TPSidebar, TPSidebarHeader } from '../../controls/TPage';
import Avatar from 'antd/lib/avatar/avatar';
import AvatarUploadModal from '../../global-modals/AvatarUploadModal';
import dataUriToBlob from '../../utils/dataUriToBlob';
import { StatDiv, StatHeader, StatPlate, StatPlateTitle, StatPlateValue } from '../../controls/StatHeader';
import PromptModal from '../../global-modals/PromptModal';
import handleError from '../../utils/handleError';
import { AdminChainMetaData } from '../../web-types/AdminPlaceMetaData';
import formatNumber from '../../utils/formatNumber';
import formatMoney from '../../utils/formatMoney';
import ConfirmModal from '../../global-modals/ConfirmModal';
import BackButton from '../../controls/BackButton';
import SelectPlaceModal from '../../global-modals/SelectPlaceModal';
import { TableController } from '../../controls/TableController';
import { admin_fetchEmployees, EmployeeRow } from '../../lib/api-web/employees';
import { autobind } from 'core-decorators';
import { Sorting } from '../../web-types/Sorting';
import EmployeeRoleModal from '../../global-modals/EmployeeRoleModal';
import UserPlaceRole from '../../web-types/UserPlaceRole';
import SelectEmployeeModal from '../../global-modals/SelectEmployeeModal';
import UserChainRole from '../../web-types/UserChainRole';

interface AdminChainCardProps {
	navigate: NavigateFunction;
	params: { chainId: string };
	state: { backable: boolean } | null;
}

@observer
class AdminChainCard extends PureComponent<AdminChainCardProps> {
	@observable loading = false;
	@observable avatarUploading = false;
	@observable nameEditing = false;
	@observable placeAdding = false;
	@observable employeeInviting = false;
	@observable paymentDataEditing = false;
	@observable chainDeleting = false;
	@observable data: AdminChainData | null = null;
	@observable metadata: AdminChainMetaData | null = null;
	@observable imageUrl: string | null = null;

	@observable params: { query: string; byPlace: number | null; byChain: number | null } = {
		query: '',
		byPlace: null,
		byChain: Number(this.props.params.chainId),
	};
	controller =
		React.createRef<
			TableController<{ query: string; byPlace: number | null; byChain: number | null }, EmployeeRow>
		>();

	async reload() {
		this.loading = true;
		try {
			const result = await admin_fetchChain(Number(this.props.params.chainId));
			if (result.result) {
				this.data = result.data.data;
				this.metadata = result.data.metadata;
				this.controller.current?.load();
			} else {
				toaster.show({
					type: 'error',
					message: result.error,
				});
			}
		} catch (err) {
			toaster.show({
				type: 'error',
				message: (err as Error).message,
			});
		}
		this.loading = false;
	}

	componentDidMount() {
		this.reload();
	}

	componentDidUpdate(prevProps: AdminChainCardProps) {
		if (prevProps.params.chainId !== this.props.params.chainId) {
			this.reload();
		}
	}

	handleClick(info: any) {
		console.log('info: ', info);
	}

	@autobind
	handleTablePropsUpdate(contentVisible: boolean, total: number | null, rowsPerPage: number) {
		//
	}

	@autobind
	async handleLoad(
		params: { query: string; byPlace: number | null; byChain: number | null },
		offset: number,
		limit: number,
		sorting: Sorting | null,
	) {
		return admin_fetchEmployees(
			{ offset, limit, sorting },
			{ query: params.query, notInGroupId: null, placeId: null, chainId: params.byChain },
		);
	}

	async handleAvatarUpload() {
		const newAvatarDataUri = await AvatarUploadModal.prompt();
		if (newAvatarDataUri) {
			this.avatarUploading = true;
			try {
				await manager_uploadChainAvatar(
					Number(this.props.params.chainId),
					await dataUriToBlob(newAvatarDataUri),
				);
				await this.reload();
			} catch (err) {
				handleError(err);
			}
			this.avatarUploading = false;
		}
	}

	async handleAttachToChain() {
		const employeeId = await SelectEmployeeModal.show(null, null, null, null);
		if (!employeeId) {
			return;
		}
		const data = await EmployeeRoleModal.prompt('Select employee role', UserPlaceRole.OWNER);
		if (!data) {
			return;
		}
		this.nameEditing = true;
		try {
			const response = await manager_attachEmployeeToChain(
				Number(employeeId),
				Number(this.props.params.chainId),
				data.role as any as UserChainRole,
				data.text_role,
			);
			if (response.result) {
				this.nameEditing = false;
				toaster.show({ message: 'You successfully attached the employee', type: 'success' });
				await this.reload();
			} else {
				handleError(response.error);
			}
		} catch (err) {
			handleError(err);
		}
	}

	async handleAddPlace() {
		const newPlaceId = await SelectPlaceModal.show(null, this.data!.id, null);
		if (newPlaceId) {
			this.placeAdding = true;
			try {
				await admin_attachPlace(newPlaceId, this.data!.id);
				await this.reload();
			} catch (err) {
				handleError(err);
			}
			this.placeAdding = false;
		}
	}

	async handleEditName() {
		const newName = await PromptModal.prompt('Change chain name', this.data!.name);
		if (newName) {
			this.nameEditing = true;
			try {
				await manager_updateChainName(Number(this.props.params.chainId), newName);
				await this.reload();
			} catch (err) {
				handleError(err);
			}
			this.nameEditing = false;
		}
	}

	async handleDelete() {
		const descision = await ConfirmModal.prompt(
			'Are you sure?',
			'This action is irreversible. You will lose all the data: employees, transactions, QR codes. Are you absolutely sure?',
			'Yes',
		);
		if (!descision) {
			return;
		}
		this.chainDeleting = true;
		try {
			const response = await admin_deleteChain(Number(this.props.params.chainId));
			if (response.result) {
				this.chainDeleting = false;
				toaster.show({ message: 'You successfully deleted the chain', type: 'success' });
				this.props.navigate('/admin-chains/');
			} else {
				handleError(response.error);
			}
		} catch (err) {
			handleError(err);
		}
	}

	render() {
		if (!this.data || !this.metadata) {
			return <Spin />;
		}

		return (
			<div className="page">
				<div className="page-head">
					<div className="page-title">
						<Typography.Title
							level={4}
							style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
						>
							<BackButton props={this.props} /> Chain "{this.data.name}"
						</Typography.Title>
					</div>
					<div className="page-actions"></div>
				</div>
				<TPage className="card-section">
					<TPSidebar>
						<TPSidebarHeader>
							{this.avatarUploading ? (
								<Avatar size={120} src={null} icon={<Spin />} />
							) : (
								<Avatar size={120} src={this.data.logoUrl} icon={<HomeOutlined />} />
							)}
						</TPSidebarHeader>
						<Menu inlineIndent={12} selectable={false} onClick={this.handleClick} mode="inline">
							<Menu.Item
								key="1"
								icon={<UploadOutlined />}
								onClick={e => {
									e.domEvent.preventDefault();
									this.handleAvatarUpload();
								}}
							>
								Upload new image
							</Menu.Item>
							<Menu.Item
								key="2"
								icon={this.nameEditing ? <Spin /> : <EditOutlined />}
								onClick={e => {
									e.domEvent.preventDefault();
									this.handleEditName();
								}}
							>
								Edit name
							</Menu.Item>
							<Menu.Item
								key="3"
								icon={this.placeAdding ? <Spin /> : <AppstoreAddOutlined />}
								onClick={e => {
									e.domEvent.preventDefault();
									this.handleAddPlace();
								}}
							>
								Attach place to chain
							</Menu.Item>
							<Menu.Item
								key="5"
								icon={this.placeAdding ? <Spin /> : <UsergroupAddOutlined />}
								onClick={e => {
									e.domEvent.preventDefault();
									this.handleAttachToChain();
								}}
							>
								Attach user to chain
							</Menu.Item>
							<Menu.Divider />
							<Menu.Item
								key="4"
								danger
								icon={<DeleteOutlined />}
								onClick={e => {
									e.domEvent.preventDefault();
									this.handleDelete();
								}}
							>
								Delete chain
							</Menu.Item>
						</Menu>
					</TPSidebar>
					<TPContent>
						<StatHeader>
							<StatPlate>
								<StatPlateTitle>Places</StatPlateTitle>
								<StatPlateValue>{formatNumber(this.metadata.places_count)}</StatPlateValue>
							</StatPlate>
							<StatDiv />
							<StatPlate>
								<StatPlateTitle>Employees in places</StatPlateTitle>
								<StatPlateValue>{formatNumber(this.metadata.employees_count)}</StatPlateValue>
							</StatPlate>
							<StatDiv />
							<StatPlate>
								<StatPlateTitle>7d tips volume</StatPlateTitle>
								<StatPlateValue>{formatMoney(this.metadata.tips_volume_7d)}</StatPlateValue>
							</StatPlate>
							<StatDiv />
							<StatPlate>
								<StatPlateTitle>Avg. tip volume</StatPlateTitle>
								<StatPlateValue>{formatMoney(this.metadata.average_tip_30d)}</StatPlateValue>
							</StatPlate>
						</StatHeader>
						<Tabs defaultActiveKey="1" type="card">
							<Tabs.TabPane tab="Users" key="users">
								<div style={{ paddingLeft: 10, paddingRight: 10 }}>
									<TableController
										noSection
										ref={this.controller}
										onRowClick={({ id }) => {
											this.props.navigate(`/admin-employees/${id}/`, {
												state: { backable: true },
											});
										}}
										params={this.params}
										load={this.handleLoad}
										onTablePropsUpdate={this.handleTablePropsUpdate}
										columns={[
											{
												key: 'avatarUrl',
												title: '',
												render: value => (
													<Avatar size={50} src={value} icon={<UserOutlined />} />
												),
												width: 50,
											},
											{
												key: 'firstName',
												title: 'Name',
												sortable: true,
												render: (value, row) => `${row.firstName} ${row.lastName}`,
											},
											{
												key: 'uc_role',
												title: 'Role',
												sortable: true,
											},
											{
												key: 'uc_text_role',
												title: 'Position',
												sortable: true,
											},
											{
												key: 'id',
												title: 'Actions',
												width: 70,
												render: (id, row) => (
													<div
														style={{
															display: 'flex',
															flexDirection: 'row',
															alignItems: 'center',
														}}
													>
														<Popover content="Change role">
															<Button
																type="text"
																icon={<CrownOutlined />}
																onClick={async e => {
																	e.stopPropagation();
																	e.preventDefault();
																	const data = await EmployeeRoleModal.prompt(
																		'Select employee role',
																		UserPlaceRole.OWNER,
																		(row as any).uc_role,
																		(row as any).uc_text_role,
																	);
																	if (!data) {
																		return;
																	}
																	try {
																		const response =
																			await manager_attachEmployeeToChain(
																				Number(id),
																				Number(this.props.params.chainId),
																				data.role as any,
																				data.text_role,
																			);
																		if (response.result) {
																			toaster.show({
																				message:
																					'You successfully changed the employee role',
																				type: 'success',
																			});
																			await this.reload();
																		} else {
																			handleError(response.error);
																		}
																	} catch (err) {
																		handleError(err);
																	}
																}}
															/>
														</Popover>
														<Popover content="Remove from chain">
															<Button
																type="text"
																danger
																icon={<DeleteOutlined />}
																onClick={async e => {
																	e.preventDefault();
																	e.stopPropagation();
																	const descision = await ConfirmModal.prompt(
																		'Are you sure?',
																		'You will be able to attach employee to chain again, if needed.',
																		'Yes',
																	);
																	if (!descision) {
																		return;
																	}
																	try {
																		const response =
																			await manager_detachEmployeeFromChain(
																				Number(id),
																				Number(this.props.params.chainId),
																			);
																		if (response.result) {
																			toaster.show({
																				message:
																					'You successfully detached the employee',
																				type: 'success',
																			});
																			this.reload();
																		} else {
																			handleError(response.error);
																		}
																	} catch (err) {
																		handleError(err);
																	}
																}}
															/>
														</Popover>
													</div>
												),
											},
										]}
									/>
								</div>
							</Tabs.TabPane>
						</Tabs>
					</TPContent>
				</TPage>
			</div>
		);
	}
}

export default withNavigateParams(AdminChainCard);
