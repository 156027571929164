import { frontmanGet, writerPost } from '.';
import objectToFormData from '../../utils/objectToFormData';
import AdminEmployeeData from '../../web-types/AdminEmployeeData';
import AdminPlaceData from '../../web-types/AdminPlaceData';
import { AdminPlaceMetaData } from '../../web-types/AdminPlaceMetaData';
import OutgoingTxStatus from '../../web-types/OutgoingTxStatus';
import OwnerPlaceMetaData from '../../web-types/OwnerPlaceMetaData';
import { PlaceLinks } from '../../web-types/PlaceLinks';
import { Sorting } from '../../web-types/Sorting';
import UserChainRole from '../../web-types/UserChainRole';
import UserPlaceRole from '../../web-types/UserPlaceRole';
import NetworkResponse from '../responses/NetworkResponse';
import NetworkTableResponse from '../responses/NetworkTableResponse';

export function admin_fetchPlaces(
	offset: number,
	limit: number,
	params: {
		query: string;
		sorting: Sorting | null;
		placeId?: number | null;
		chainId?: number | null;
		notInChainId?: number | null;
	},
): NetworkTableResponse<AdminPlaceData> {
	return frontmanGet('/places/admin/list', { offset, limit, params: JSON.stringify(params) });
}

export function admin_fetchPlace(
	placeId: number,
): Promise<NetworkResponse<{ data: AdminPlaceData; metadata: AdminPlaceMetaData }>> {
	return frontmanGet(`/places/admin/${placeId}`);
}

export function admin_createPlace(name: string, chainId?: number | null): Promise<NetworkResponse<number>> {
	return writerPost('POST', '/places/admin/create', null, { name, chainId });
}

export function admin_deletePlace(placeId: number): Promise<NetworkResponse<void>> {
	return writerPost('DELETE', `/places/admin/${placeId}/`);
}

export function manager_fetchPlaceStatistics(placeId: number): Promise<NetworkResponse<OwnerPlaceMetaData>> {
	return frontmanGet(`/dashboard/${placeId}/statistics`);
}

export function manager_uploadPlaceAvatar(placeId: number, avatar: Blob): Promise<NetworkResponse<string>> {
	return writerPost('POST', `/places/${placeId}/avatar`, null, objectToFormData({ avatar }));
}

export function manager_updatePlaceName(placeId: number, newName: string) {
	return writerPost('POST', `/places/${placeId}/name/`, null, { newName });
}

export function manager_updatePlacePro(placeId: number, isPro: boolean) {
	return writerPost('POST', `/places/${placeId}/pro/`, null, { pro: isPro ? 'true' : 'false' });
}

export function admin_attachPlace(placeId: number, chainId: number): Promise<NetworkResponse<void>> {
	return writerPost('POST', `/places/admin/attach-place/${placeId}/${chainId}`);
}

export function admin_detachPlace(placeId: number): Promise<NetworkResponse<void>> {
	return writerPost('POST', `/places/admin/detach-place/${placeId}`);
}

export function manager_updatePlacePaymentData(placeId: number, paymentData: string) {
	return writerPost('POST', `/places/${placeId}/payment/`, null, { paymentData });
}

export function manager_requestWithdrawal(placeId: number, amount: number, comment: string) {
	return writerPost('POST', `/places/${placeId}/withdraw/`, null, { amount, comment });
}

export function manager_setWithdrawalStatus(placeId: number, withdrawId: number, status: OutgoingTxStatus) {
	return writerPost('POST', `/places/${placeId}/withdraw-set-status/`, null, { withdrawId, status });
}

export function manager_invitePlaceEmployee(
	placeId: number,
	phone: string,
	role: UserPlaceRole,
): Promise<NetworkResponse<string>> {
	return writerPost('POST', `/employees/${placeId}/invite/`, null, { phone, role });
}

export function manager_introducePlaceEmployee(
	placeId: number,
	phone: string,
	firstName: string,
	lastName: string,
	role: UserPlaceRole,
): Promise<NetworkResponse<boolean>> {
	return writerPost('POST', `/employees/${placeId}/introduce/`, null, { phone, firstName, lastName, role });
}

export function manager_attachEmployee(
	userId: number,
	placeId: number,
	role: UserPlaceRole,
	text_role: string,
): Promise<NetworkResponse<AdminEmployeeData>> {
	return writerPost('POST', `/places/${placeId}/attach/${userId}/`, null, { role, text_role });
}

export function manager_attachEmployeeToChain(
	userId: number,
	chainId: number,
	role: UserChainRole,
	text_role: string,
): Promise<NetworkResponse<AdminEmployeeData>> {
	return writerPost('POST', `/chains/${chainId}/attach/${userId}/`, null, { role, text_role });
}

export function manager_detachEmployee(userId: number, placeId: number): Promise<NetworkResponse<AdminEmployeeData>> {
	return writerPost('POST', `/places/${placeId}/detach/${userId}/`);
}

export function manager_fetchPlace(placeId: number): Promise<NetworkResponse<AdminPlaceData>> {
	return frontmanGet(`/places/${placeId}`);
}

export function manager_savePlaceLinks(placeId: number, links: PlaceLinks) {
	return writerPost('POST', `/places/${placeId}/links`, null, { links });
}
