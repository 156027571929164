import { UserOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import Modal from 'antd/lib/modal/Modal';
import { autobind } from 'core-decorators';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { PureComponent } from 'react';
import { TableController } from '../../controls/TableController';
import { admin_fetchChainEmployees, manager_fetchEmployees } from '../../lib/api-web/employees';
import modals from '../../stores/modals';
import { Sorting } from '../../web-types/Sorting';

export interface SelectEmployeeModalProps {
	placeId: number | null;
	notInPlaceId: number | null;
	notInGroupId: number | null;
	subchainId: number | null;
	defaultEmployeeId?: number | null;
	onDismiss: () => void;
	onConfirm: (userId: number) => void;
}

@observer
export default class SelectEmployeeModal extends PureComponent<SelectEmployeeModalProps> {
	static async show(
		placeId: number | null,
		notInPlaceId: number | null,
		notInGroupId: number | null,
		subchainId: number | null,
		defaultEmployeeId?: number | null,
	): Promise<number | null> {
		return new Promise<number | null>(resolve => {
			modals.show((close: () => void) => (
				<SelectEmployeeModal
					placeId={placeId}
					notInPlaceId={notInPlaceId}
					defaultEmployeeId={defaultEmployeeId}
					notInGroupId={notInGroupId}
					subchainId={subchainId}
					onDismiss={() => {
						close();
						resolve(null);
					}}
					onConfirm={userId => {
						close();
						resolve(userId);
					}}
				/>
			));
		});
	}

	@observable contentVisible = false;
	@observable total: number | null = null;
	@observable params: { query: string } = { query: '' };
	@observable selected: number | null = this.props.defaultEmployeeId || null;

	@autobind
	handleTablePropsUpdate(contentVisible: boolean, total: number | null, rowsPerPage: number) {
		this.contentVisible = contentVisible;
		this.total = total;
	}

	@autobind
	async handleLoad(params: { query: string }, offset: number, limit: number, sorting: Sorting | null) {
		return this.props.subchainId
			? admin_fetchChainEmployees(
					{ offset, limit, sorting },
					{
						placeId: this.props.placeId,
						query: params.query,
						notInGroupId: this.props.notInGroupId || null,
						chainId: this.props.subchainId || null,
					},
			  )
			: manager_fetchEmployees(
					{ offset, limit, sorting },
					{
						placeId: this.props.placeId,
						query: params.query,
						notInGroupId: this.props.notInGroupId || null,
						notInPlaceId: this.props.notInPlaceId || null,
					},
			  );
	}

	render() {
		return (
			<Modal
				title="Select employee"
				onCancel={() => this.props.onDismiss()}
				visible={true}
				closable={false}
				okButtonProps={{
					disabled: !this.selected,
				}}
				onOk={() => {
					this.props.onConfirm(this.selected!);
				}}
			>
				<Input.Search
					style={{ marginBottom: 15 }}
					type="search"
					onSearch={value => (this.params.query = value)}
					placeholder="Search employee..."
				/>
				<TableController
					onRowClick={({ id }) => {
						this.selected = id;
					}}
					noSection
					params={this.params}
					load={this.handleLoad}
					onTablePropsUpdate={this.handleTablePropsUpdate}
					rowClass={({ id }) => (this.selected === id ? 'row-selected' : 'dummy-class')}
					columns={[
						{
							key: 'avatarUrl',
							title: '',
							render: value => <Avatar size={30} src={value} icon={<UserOutlined />} />,
							width: 50,
						},
						{
							key: 'firstName',
							title: 'Name',
							sortable: true,
							render: (value, row) => `${row.firstName} ${row.lastName}`,
						},
						{
							key: 'p_name',
							title: 'Place',
							sortable: true,
						},
						{
							key: 'up_text_role',
							title: 'Role',
							sortable: true,
						},
					]}
				/>
			</Modal>
		);
	}
}
