import { Pagination, Spin } from 'antd';
import { comparer, computed, IReactionDisposer, observable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import { PureComponent } from 'react';
import NetworkTableResponse from '../../lib/responses/NetworkTableResponse';
import handleError, { handleResponseError } from '../../utils/handleError';
import { Sorting } from '../../web-types/Sorting';
import TipzTable, { TipzTableProps } from '../TipzTable';

import './style.scss';

export interface TableControllerProps<T, R extends Record<string, any>> {
	params: T;
	columns: TipzTableProps<R>['head'];
	noSection?: boolean;
	noDataText?: string;
	load: (params: T, offset: number, limit: number, sorting: Sorting | null) => NetworkTableResponse<R>;
	onRowClick?: (rowData: R) => void;
	onTablePropsUpdate?: (contentVisible: boolean, total: number | null, rowsPerPage: number) => void;
	rowClass?: string | ((row: R, rowIdx: number) => string);
}

@observer
export class TableController<T, R extends Record<string, any>> extends PureComponent<TableControllerProps<T, R>> {
	@observable loading = false;
	@observable showSpinner = false;
	@observable contentVisible = false;
	@observable sorting: Sorting | null = null;
	@observable data: null | R[] = null;
	@observable total: null | number = null;
	@observable currentPage: number = 1;
	@observable rowPerPage = 10;
	dispose!: IReactionDisposer;

	@computed get isDataNull() {
		return this.data === null;
	}

	async load() {
		this.loading = true;
		this.contentVisible = this.isDataNull ? false : true;
		if (this.props.onTablePropsUpdate) {
			this.props.onTablePropsUpdate(this.contentVisible, this.total, this.rowPerPage);
		}
		let spinnerTimeout = null;
		spinnerTimeout = setTimeout(() => {
			spinnerTimeout = null;
			this.showSpinner = true;
		}, 1000);
		try {
			const response = await this.props.load(
				this.props.params,
				(this.currentPage - 1) * this.rowPerPage,
				this.rowPerPage,
				this.sorting,
			);
			if (!response.result) {
				handleResponseError(response);
			} else {
				this.total = response.data.total;
				this.data = response.data.rows;
				if (spinnerTimeout) {
					clearTimeout(spinnerTimeout);
				}
				setTimeout(() => {
					this.contentVisible = true;
					if (this.props.onTablePropsUpdate) {
						this.props.onTablePropsUpdate(this.contentVisible, this.total, this.rowPerPage);
					}
				}, 100);
			}
		} catch (err) {
			handleError(err);
			return;
		}
		this.loading = false;
		this.showSpinner = false;
	}

	componentDidMount() {
		this.load();
		this.dispose = reaction(
			() => ({ ...this.props.params }),
			() => {
				this.load();
			},
			{
				equals: comparer.structural,
			},
		);
	}

	render() {
		if (!this.data) {
			return (
				<div key="one" className="page">
					{this.showSpinner ? <Spin /> : null}
				</div>
			);
		}
		const content = (
			<div className="table-controller">
				<TipzTable
					sorting={this.sorting}
					onSortingChange={s => {
						this.sorting = s;
						this.currentPage = 1;
						this.load();
					}}
					loading={this.loading}
					head={this.props.columns}
					onRowClick={this.props.onRowClick}
					rows={this.data}
					noDataText={this.props.noDataText}
					rowClass={this.props.rowClass}
				/>
				<Pagination
					current={this.currentPage}
					pageSize={this.rowPerPage}
					total={this.total || 0}
					onChange={(page, pageSize) => {
						this.rowPerPage = pageSize;
						this.currentPage = page;
						this.load();
					}}
				/>
			</div>
		);
		if (this.props.noSection) {
			return content;
		} else {
			return <section className="section">{content}</section>;
		}
	}
}
