import React from 'react';

import { observer } from 'mobx-react';

import { PureComponent } from 'react';
import cn from 'classnames';

import './style.scss';
import withNavigateParams from '../../utils/withNavigateParams';
import { NavigateFunction } from 'react-router';
import places from '../../stores/places';
import { Avatar, Button, Spin, Tooltip } from 'antd';
import MagicPlate from '../../controls/MagicPlate';
import TotalTipsCountIcon from '../../assets/icons/TotalTipsCountIcon';
import ReviewsIcon from '../../assets/icons/ReviewsIcon';
import TotalTipsVolumeIcon from '../../assets/icons/TotalTipsVolumeIcon';
import { observable } from 'mobx';
import OwnerPlaceMetaData from '../../web-types/OwnerPlaceMetaData';
import handleError, { handleResponseError } from '../../utils/handleError';
import { admin_fetchPlace, manager_fetchPlaceStatistics, manager_requestWithdrawal } from '../../lib/api-web';
import MetricChange from '../../controls/MetricChange';
import LineChart from '../../controls/LineChart';
import WithdrawModal from '../../global-modals/WithdrawModal';
import AdminPlaceData from '../../web-types/AdminPlaceData';
import i18n from '../../stores/i18n';
import user from '../../stores/user';
import UserPlaceRole from '../../web-types/UserPlaceRole';
import { employee_getMyBalance } from '../../lib/api-web/qr-codes';
import { employee_requestWithdrawal } from '../../lib/api-web/employees';

@observer
class DashboardPage extends PureComponent<{ navigate: NavigateFunction; params: any; state: any }> {
	@observable loading = true;
	@observable showSpinner = false;
	@observable contentVisible = false;
	@observable withdrawalLoading = false;
	@observable placeStatistics!: OwnerPlaceMetaData;
	@observable data: AdminPlaceData | null = null;

	@observable balance: number = 0;

	firstTime: boolean = true;

	async loadPlaceData(placeId: number) {
		this.loading = true;
		this.contentVisible = false;
		let spinnerTimeout = null;
		spinnerTimeout = setTimeout(() => {
			spinnerTimeout = null;
			this.showSpinner = true;
		}, 1000);
		try {
			if (places.place?.role === UserPlaceRole.EMPLOYEE) {
				const result = await employee_getMyBalance();
				this.balance = result.data.balance;
				if (spinnerTimeout) {
					clearTimeout(spinnerTimeout);
				}
				setTimeout(() => {
					this.contentVisible = true;
				}, 100);
			} else {
				const result = await admin_fetchPlace(Number(this.props.params.placeId));
				if (!result.result) {
					handleResponseError(result);
				} else {
					this.data = result.data.data;
				}
				const response = await manager_fetchPlaceStatistics(placeId);
				if (!response.result) {
					handleResponseError(response);
				} else {
					this.placeStatistics = response.data;
					if (spinnerTimeout) {
						clearTimeout(spinnerTimeout);
					}
					setTimeout(() => {
						this.contentVisible = true;
					}, 100);
				}
			}
		} catch (err) {
			handleError(err);
			return;
		}
		this.loading = false;
		this.showSpinner = false;
	}

	componentDidMount() {
		this.loadPlaceData(this.props.params.placeId);
	}

	async requestWithdrawal() {
		const result = await WithdrawModal.prompt(this.data?.payment_data);
		if (!result) {
			return;
		}
		this.withdrawalLoading = true;
		try {
			if (places.place?.role === UserPlaceRole.EMPLOYEE) {
				await employee_requestWithdrawal(user.user!.id, result.amount, result.comment);
			} else {
				await manager_requestWithdrawal(this.props.params.placeId, result.amount, result.comment);
			}

			await this.loadPlaceData(this.props.params.placeId);
		} catch (err) {
			handleError(err);
			return;
		} finally {
			this.withdrawalLoading = false;
		}
	}

	render() {
		if (this.loading) {
			return (
				<div key="one" className="page">
					{this.showSpinner ? <Spin /> : null}
				</div>
			);
		}

		console.log('places.place?.role: ', places.place?.role);

		if (places.place?.role === UserPlaceRole.EMPLOYEE) {
			return (
				<div key="two" className={cn('page', { hidden: !this.contentVisible })}>
					<div
						style={{
							padding: 30,
						}}
					>
						<h3
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								marginTop: 10,
							}}
						>
							<div style={{ fontSize: 20 }}>
								{i18n.m.BALANCE}: {Number(this.balance.toFixed(2))} AED
							</div>
							<Button
								loading={this.withdrawalLoading}
								size="small"
								style={{ marginLeft: 8 }}
								onClick={() => {
									this.requestWithdrawal();
								}}
								disabled={this.balance < 100}
							>
								{i18n.m.WITHDRAWAL_BUTTON}
							</Button>
						</h3>
					</div>
				</div>
			);
		}

		return (
			<div key="two" className={cn('page', { hidden: !this.contentVisible })}>
				<div className="page-head">
					<div className="page-title">
						<h2>{i18n.m.DASHBOARD_TITLE}</h2>
					</div>
					{places.place?.isTipzPro && (
						<div className="page-actions">
							<Tooltip
								title={this.placeStatistics.balance < 100 ? i18n.m.DASHBOARD_WITHDRAWAL_MINIMUM : ''}
							>
								<Button
									loading={this.withdrawalLoading}
									onClick={() => {
										this.requestWithdrawal();
									}}
									disabled={this.placeStatistics.balance < 100}
								>
									{i18n.m.WITHDRAWAL_BUTTON}
								</Button>
							</Tooltip>
						</div>
					)}
				</div>
				{places.place?.isTipzPro && (
					<div>
						<h3
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								marginTop: 10,
							}}
						>
							<div style={{ fontSize: 20 }}>
								{i18n.m.BALANCE}: {Number(this.placeStatistics.balance.toFixed(2))} AED
							</div>
							<Button
								loading={this.withdrawalLoading}
								size="small"
								style={{ marginLeft: 8 }}
								onClick={() => {
									this.requestWithdrawal();
								}}
								disabled={this.placeStatistics.balance < 100}
							>
								{i18n.m.WITHDRAWAL_BUTTON}
							</Button>
						</h3>
					</div>
				)}
				<div className="plates">
					<MagicPlate
						className="red"
						onClick={() => {
							this.props.navigate(`/${places.currentPlaceId}/tips`);
						}}
					>
						<div className="plate-head">
							<div className="plate-title">{i18n.m.AVG_RATING_30D}</div>
							<div className="plate-icon">
								<TotalTipsCountIcon />
							</div>
						</div>
						<div className="plate-body">
							<MetricChange
								newValue={this.placeStatistics.average_rating_30d}
								oldValue={this.placeStatistics.average_rating_previous_30d}
							/>
						</div>
						<div className="plate-footer">
							<div className="plate-link">{i18n.m.MENU_TIPS}</div>
							<div className="plate-arrow">
								<ReviewsIcon />
							</div>
						</div>
					</MagicPlate>
					<MagicPlate
						className="blue"
						onClick={() => {
							this.props.navigate(`/${places.currentPlaceId}/tips`);
						}}
					>
						<div className="plate-head">
							<div className="plate-title">{i18n.m.TIPS_VOL_7D}</div>
							<div className="plate-icon">
								<TotalTipsVolumeIcon />
							</div>
						</div>
						<div className="plate-body">
							<MetricChange
								addon="AED"
								newValue={this.placeStatistics.tips_volume_7d}
								oldValue={this.placeStatistics.tips_volume_7d}
							/>
						</div>
						<div className="plate-footer">
							<div className="plate-link">{i18n.m.MENU_TIPS}</div>
							<div className="plate-arrow">
								<svg
									width="23"
									height="24"
									viewBox="0 0 23 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M6.70832 11H13.9821L10.8196 7.71C10.6391 7.52169 10.5377 7.2663 10.5377 7C10.5377 6.7337 10.6391 6.4783 10.8196 6.29C11 6.10169 11.2448 5.99591 11.5 5.99591C11.7552 5.99591 11.9999 6.10169 12.1804 6.29L16.9721 11.29C17.0593 11.3851 17.1277 11.4972 17.1733 11.62C17.2692 11.8635 17.2692 12.1365 17.1733 12.38C17.1277 12.5027 17.0593 12.6149 16.9721 12.71L12.1804 17.71C12.0913 17.8037 11.9853 17.8781 11.8685 17.9289C11.7518 17.9797 11.6265 18.0058 11.5 18.0058C11.3735 18.0058 11.2482 17.9797 11.1314 17.9289C11.0147 17.8781 10.9087 17.8037 10.8196 17.71C10.7297 17.617 10.6585 17.5064 10.6098 17.3846C10.5611 17.2627 10.5361 17.132 10.5361 17C10.5361 16.868 10.5611 16.7373 10.6098 16.6154C10.6585 16.4936 10.7297 16.383 10.8196 16.29L13.9821 13H6.70832C6.45415 13 6.2104 12.8946 6.03068 12.7071C5.85095 12.5196 5.74999 12.2652 5.74999 12C5.74999 11.7348 5.85095 11.4804 6.03068 11.2929C6.2104 11.1054 6.45415 11 6.70832 11Z"
										fill="white"
									/>
								</svg>
							</div>
						</div>
					</MagicPlate>
					{/* <MagicPlate
						className="green"
						onClick={() => {
							this.props.navigate(`/${places.currentPlaceId}/employees`);
						}}
					>
						<div className="plate-head">
							<div className="plate-title">Redirects to TripAdvisor for 7 days</div>
							<div className="plate-icon">
								<svg
									width="25"
									height="24"
									viewBox="0 0 25 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M12.5 22C6.74692 22 2.08337 17.523 2.08337 12C2.08337 6.477 6.74692 2 12.5 2C18.2532 2 22.9167 6.477 22.9167 12C22.9167 17.523 18.2532 22 12.5 22ZM12.5 20C14.7102 20 16.8298 19.1571 18.3926 17.6569C19.9554 16.1566 20.8334 14.1217 20.8334 12C20.8334 9.87827 19.9554 7.84344 18.3926 6.34315C16.8298 4.84285 14.7102 4 12.5 4C10.2899 4 8.17029 4.84285 6.60748 6.34315C5.04468 7.84344 4.16671 9.87827 4.16671 12C4.16671 14.1217 5.04468 16.1566 6.60748 17.6569C8.17029 19.1571 10.2899 20 12.5 20V20ZM11.4615 16L7.04171 11.757L8.51462 10.343L11.4615 13.172L17.3532 7.515L18.8271 8.929L11.4615 16Z"
										fill="white"
									/>
								</svg>
							</div>
						</div>
						<div className="plate-body">
							<MetricChange
								newValue={21}
								// Object.values(this.placeStatistics.review_redirects_7d).reduce(
								// 	(p, c) => p + c,
								// 	0,
								// )
								oldValue={4}
							/>
						</div>
						<div className="plate-footer">
							<div className="plate-link">Redirected reviews</div>
							<div className="plate-arrow">
								<svg
									width="23"
									height="24"
									viewBox="0 0 23 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M6.70832 11H13.9821L10.8196 7.71C10.6391 7.52169 10.5377 7.2663 10.5377 7C10.5377 6.7337 10.6391 6.4783 10.8196 6.29C11 6.10169 11.2448 5.99591 11.5 5.99591C11.7552 5.99591 11.9999 6.10169 12.1804 6.29L16.9721 11.29C17.0593 11.3851 17.1277 11.4972 17.1733 11.62C17.2692 11.8635 17.2692 12.1365 17.1733 12.38C17.1277 12.5027 17.0593 12.6149 16.9721 12.71L12.1804 17.71C12.0913 17.8037 11.9853 17.8781 11.8685 17.9289C11.7518 17.9797 11.6265 18.0058 11.5 18.0058C11.3735 18.0058 11.2482 17.9797 11.1314 17.9289C11.0147 17.8781 10.9087 17.8037 10.8196 17.71C10.7297 17.617 10.6585 17.5064 10.6098 17.3846C10.5611 17.2627 10.5361 17.132 10.5361 17C10.5361 16.868 10.5611 16.7373 10.6098 16.6154C10.6585 16.4936 10.7297 16.383 10.8196 16.29L13.9821 13H6.70832C6.45415 13 6.2104 12.8946 6.03068 12.7071C5.85095 12.5196 5.74999 12.2652 5.74999 12C5.74999 11.7348 5.85095 11.4804 6.03068 11.2929C6.2104 11.1054 6.45415 11 6.70832 11Z"
										fill="white"
									/>
								</svg>
							</div>
						</div>
					</MagicPlate> */}
					<MagicPlate
						className="green"
						onClick={() => {
							this.props.navigate(`/${places.currentPlaceId}/tips`);
						}}
					>
						<div className="plate-head">
							<div className="plate-title">{i18n.m.REVIEWS_COUNT_7D}</div>
							<div className="plate-icon">
								<svg
									width="25"
									height="24"
									viewBox="0 0 25 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M12.5 22C6.74692 22 2.08337 17.523 2.08337 12C2.08337 6.477 6.74692 2 12.5 2C18.2532 2 22.9167 6.477 22.9167 12C22.9167 17.523 18.2532 22 12.5 22ZM12.5 20C14.7102 20 16.8298 19.1571 18.3926 17.6569C19.9554 16.1566 20.8334 14.1217 20.8334 12C20.8334 9.87827 19.9554 7.84344 18.3926 6.34315C16.8298 4.84285 14.7102 4 12.5 4C10.2899 4 8.17029 4.84285 6.60748 6.34315C5.04468 7.84344 4.16671 9.87827 4.16671 12C4.16671 14.1217 5.04468 16.1566 6.60748 17.6569C8.17029 19.1571 10.2899 20 12.5 20V20ZM11.4615 16L7.04171 11.757L8.51462 10.343L11.4615 13.172L17.3532 7.515L18.8271 8.929L11.4615 16Z"
										fill="white"
									/>
								</svg>
							</div>
						</div>
						<div className="plate-body">
							<MetricChange newValue={this.placeStatistics.tips_count_7d} oldValue={0} />
						</div>
						<div className="plate-footer">
							<div className="plate-link">{i18n.m.DASHBOARD_STATISTICS}</div>
							<div className="plate-arrow">
								<svg
									width="23"
									height="24"
									viewBox="0 0 23 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M6.70832 11H13.9821L10.8196 7.71C10.6391 7.52169 10.5377 7.2663 10.5377 7C10.5377 6.7337 10.6391 6.4783 10.8196 6.29C11 6.10169 11.2448 5.99591 11.5 5.99591C11.7552 5.99591 11.9999 6.10169 12.1804 6.29L16.9721 11.29C17.0593 11.3851 17.1277 11.4972 17.1733 11.62C17.2692 11.8635 17.2692 12.1365 17.1733 12.38C17.1277 12.5027 17.0593 12.6149 16.9721 12.71L12.1804 17.71C12.0913 17.8037 11.9853 17.8781 11.8685 17.9289C11.7518 17.9797 11.6265 18.0058 11.5 18.0058C11.3735 18.0058 11.2482 17.9797 11.1314 17.9289C11.0147 17.8781 10.9087 17.8037 10.8196 17.71C10.7297 17.617 10.6585 17.5064 10.6098 17.3846C10.5611 17.2627 10.5361 17.132 10.5361 17C10.5361 16.868 10.5611 16.7373 10.6098 16.6154C10.6585 16.4936 10.7297 16.383 10.8196 16.29L13.9821 13H6.70832C6.45415 13 6.2104 12.8946 6.03068 12.7071C5.85095 12.5196 5.74999 12.2652 5.74999 12C5.74999 11.7348 5.85095 11.4804 6.03068 11.2929C6.2104 11.1054 6.45415 11 6.70832 11Z"
										fill="white"
									/>
								</svg>
							</div>
						</div>
					</MagicPlate>
				</div>
				<div className="plates" style={{ alignItems: 'stretch' }}>
					{this.placeStatistics.best_employee_month ? (
						<div className="employee-plate">
							<h3 style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 30 }}>
								{i18n.m.DASHBOARD_BEST_EMPLOYEE_MONTH}
							</h3>
							<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
								<Avatar size={100} src={this.placeStatistics.best_employee_month.avatarUrl} />
								<h4 style={{ marginTop: 10 }}>
									<a
										href={`/${this.props.params.placeId}/employees/${this.placeStatistics.best_employee_month.id}`}
										onClick={e => {
											e.preventDefault();
											this.props.navigate(
												`/${this.props.params.placeId}/employees/${
													this.placeStatistics.best_employee_month!.id
												}`,
											);
										}}
									>
										{this.placeStatistics.best_employee_month.firstName}{' '}
										{this.placeStatistics.best_employee_month.lastName}
									</a>
								</h4>
							</div>
							<h3 style={{ fontSize: 24, color: '#00a500', marginTop: 10 }}>
								⭐ {Number(this.placeStatistics.best_employee_rating.toFixed(1))}
							</h3>
						</div>
					) : null}
					<div className="rating-chart">
						<h3 style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 30 }}>{i18n.m.AVG_RATING_30D}</h3>
						<div style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'stretch' }}>
							<LineChart data={this.placeStatistics.daily_rating_last_month} />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withNavigateParams(DashboardPage);
