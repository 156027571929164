import Modal from 'antd/lib/modal/Modal';
import { autobind } from 'core-decorators';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { PureComponent } from 'react';
import AvatarMagic from 'react-avatar-edit';
import modals from '../../stores/modals';
import AutoSizer from 'react-virtualized-auto-sizer';

export interface AvatarUploadModalProps {
	onDismiss: () => void;
	onConfirm: (data: string) => void;
}

@observer
export default class AvatarUploadModal extends PureComponent<AvatarUploadModalProps> {
	static async prompt(): Promise<string | null> {
		return new Promise<string | null>((resolve, reject) => {
			modals.show((close: () => void) => (
				<AvatarUploadModal
					onConfirm={url => {
						close();
						resolve(url);
					}}
					onDismiss={() => {
						close();
						resolve(null);
					}}
				/>
			));
		});
	}

	@observable preview: any = null;

	@autobind
	handleCrop(preview: any) {
		this.preview = preview;
	}

	@autobind
	handleClose() {
		this.preview = null;
	}

	render() {
		return (
			<Modal
				okButtonProps={{ disabled: !this.preview }}
				onCancel={() => this.props.onDismiss()}
				visible={true}
				closable={false}
				onOk={() => {
					this.props.onConfirm(this.preview);
				}}
			>
				<div style={{ width: '100%', minHeight: 300 }}>
					<AutoSizer>
						{({ width, height }) => (
							<AvatarMagic
								width={width}
								height={300}
								exportAsSquare={true}
								exportSize={200}
								exportMimeType="image/jpeg"
								onCrop={this.handleCrop}
								onClose={this.handleClose}
								src={''}
							/>
						)}
					</AutoSizer>
				</div>
			</Modal>
		);
	}
}
