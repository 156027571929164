import { Input } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { autobind } from 'core-decorators';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { PureComponent } from 'react';
import { TableController } from '../../controls/TableController';
import { manager_fetchQRCodes } from '../../lib/api-web/qr-codes';
import modals from '../../stores/modals';
import { Sorting } from '../../web-types/Sorting';

export interface SelectQRCodeModalProps {
	placeId: number;
	userId: 'any' | 'null' | number;
	groupId: 'any' | 'null' | number;
	defaultGroupId?: number;
	onDismiss: () => void;
	onConfirm: (groupId: number) => void;
}

@observer
export default class SelectQRCodeModal extends PureComponent<SelectQRCodeModalProps> {
	static async show(
		placeId: number,
		userId: 'any' | 'null' | number,
		groupId: 'any' | 'null' | number,
	): Promise<number | null> {
		return new Promise<number | null>((resolve, reject) => {
			modals.show((close: () => void) => (
				<SelectQRCodeModal
					placeId={placeId}
					userId={userId}
					groupId={groupId}
					onDismiss={() => {
						close();
						resolve(null);
					}}
					onConfirm={groupId => {
						close();
						resolve(groupId);
					}}
				/>
			));
		});
	}

	@observable contentVisible = false;
	@observable total: number | null = null;
	@observable params: { query: string } = { query: '' };
	@observable selected: number | null = this.props.defaultGroupId || null;

	@autobind
	handleTablePropsUpdate(contentVisible: boolean, total: number | null, rowsPerPage: number) {
		this.contentVisible = contentVisible;
		this.total = total;
	}

	@autobind
	async handleLoad(params: { query: string }, offset: number, limit: number, sorting: Sorting | null) {
		return manager_fetchQRCodes(this.props.placeId, this.props.userId, this.props.groupId, offset, limit, {
			query: params.query,
			sorting,
		});
	}

	render() {
		return (
			<Modal
				title="Select QR code"
				onCancel={() => this.props.onDismiss()}
				visible={true}
				closable={false}
				okButtonProps={{
					disabled: !this.selected,
				}}
				onOk={() => {
					this.props.onConfirm(this.selected!);
				}}
			>
				<Input.Search
					style={{ marginBottom: 15 }}
					type="search"
					onSearch={value => (this.params.query = value)}
					placeholder="Search QR code..."
				/>
				<TableController
					onRowClick={({ id }) => {
						this.selected = id;
					}}
					noSection
					params={this.params}
					load={this.handleLoad}
					onTablePropsUpdate={this.handleTablePropsUpdate}
					rowClass={({ id }) => (this.selected === id ? 'row-selected' : 'dummy-class')}
					columns={[
						{
							key: 'unique_id',
							title: 'Code',
							sortable: true,
						},
					]}
				/>
			</Modal>
		);
	}
}
