import Modal from 'antd/lib/modal/Modal';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { PureComponent } from 'react';
import modals from '../../stores/modals';
import { Form, Input, Select } from 'antd';
import UserPlaceRole from '../../web-types/UserPlaceRole';
import user from '../../stores/user';
import InternalRole from '../../web-types/InternalRole';
import { autobind } from 'core-decorators';

export interface SimpleEmployeeModalProps {
	title: string;
	myRoleInThisPlace: UserPlaceRole | null;
	helpText?: string;
	onDismiss: () => void;
	onConfirm: (data: {
		role: UserPlaceRole;
		phone: string;
		firstName: string;
		lastName: string;
		text_role: string;
	}) => void;
}

@observer
export default class SimpleEmployeeModal extends PureComponent<SimpleEmployeeModalProps> {
	static async prompt(title: string, myRoleInThisPlace: UserPlaceRole | null, helpText?: string) {
		return new Promise<{
			role: UserPlaceRole;
			phone: string;
			firstName: string;
			lastName: string;
			text_role: string;
		} | null>((resolve, reject) => {
			modals.show((close: () => void) => (
				<SimpleEmployeeModal
					title={title}
					myRoleInThisPlace={myRoleInThisPlace}
					helpText={helpText}
					onConfirm={data => {
						close();
						resolve(data);
					}}
					onDismiss={() => {
						close();
						resolve(null);
					}}
				/>
			));
		});
	}

	@observable phone: string = '';
	@observable role: UserPlaceRole = UserPlaceRole.EMPLOYEE;
	@observable firstName: string = '';
	@observable lastName: string = '';
	@observable text_role: string = 'Waiter';

	@autobind
	handleEnter(e: React.KeyboardEvent) {
		if (e.key === 'Enter') {
			this.props.onConfirm({
				role: this.role,
				phone: this.phone,
				firstName: this.firstName,
				lastName: this.lastName,
				text_role: this.text_role,
			});
		}
	}

	render() {
		return (
			<Modal
				title={this.props.title}
				okButtonProps={{ disabled: false }}
				onCancel={() => this.props.onDismiss()}
				visible={true}
				closable={false}
				onOk={() => {
					this.props.onConfirm({
						role: this.role,
						phone: this.phone,
						firstName: this.firstName,
						lastName: this.lastName,
						text_role: this.text_role,
					});
				}}
			>
				<Form>
					<Form.Item>
						<Select value={this.role} onChange={e => (this.role = e)}>
							<Select.Option
								value={UserPlaceRole.EMPLOYEE}
								disabled={
									user.user!.role !== InternalRole.ADMIN &&
									user.user!.role !== InternalRole.SALES &&
									(this.props.myRoleInThisPlace === null ||
										this.props.myRoleInThisPlace === UserPlaceRole.EMPLOYEE)
								}
							>
								Employee
							</Select.Option>
							<Select.Option
								value={UserPlaceRole.ADMIN}
								disabled={
									user.user!.role !== InternalRole.ADMIN &&
									user.user!.role !== InternalRole.SALES &&
									(this.props.myRoleInThisPlace === null ||
										this.props.myRoleInThisPlace === UserPlaceRole.OWNER)
								}
							>
								Admin
							</Select.Option>
							<Select.Option
								value={UserPlaceRole.OWNER}
								disabled={
									user.user!.role !== InternalRole.ADMIN && user.user!.role !== InternalRole.SALES
								}
							>
								Owner
							</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item help={this.props.helpText}>
						<Input
							autoFocus={true}
							style={{ width: '100%' }}
							placeholder="Enter employee phone"
							value={this.phone}
							onKeyPress={this.handleEnter}
							onChange={e => (this.phone = e.target.value)}
						/>
					</Form.Item>
					<Form.Item help={this.props.helpText}>
						<Input
							autoFocus={true}
							style={{ width: '100%' }}
							placeholder="Enter employee first name"
							value={this.firstName}
							onKeyPress={this.handleEnter}
							onChange={e => (this.firstName = e.target.value)}
						/>
					</Form.Item>
					<Form.Item help={this.props.helpText}>
						<Input
							autoFocus={true}
							style={{ width: '100%' }}
							placeholder="Enter employee last name"
							value={this.lastName}
							onKeyPress={this.handleEnter}
							onChange={e => (this.lastName = e.target.value)}
						/>
					</Form.Item>
					<Form.Item help={this.props.helpText}>
						<Input
							autoFocus={true}
							style={{ width: '100%' }}
							placeholder="Enter employee position"
							value={this.text_role}
							onKeyPress={this.handleEnter}
							onChange={e => (this.text_role = e.target.value)}
						/>
					</Form.Item>
				</Form>
			</Modal>
		);
	}
}
