import { Typography } from 'antd';
import { PureComponent } from 'react';
import { NavigateFunction } from 'react-router';
import withNavigateParams from '../../utils/withNavigateParams';
import BackButton from '../BackButton';

export interface PageTitleProps {
	navigate: NavigateFunction;
	params: { placeId?: string; chainId?: string };
	state: { backable: boolean } | null;

	total?: number | null;
	type: string;
	title?: string;
	sub?: null | {
		type: string;
		entityName: string;
		link: string;
	};
}

export class PageTitle extends PureComponent<PageTitleProps> {
	render() {
		return (
			<div className="page-title">
				<Typography.Title level={4} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
					<BackButton props={this.props} /> {this.props.type}
					{this.props.title && ` "${this.props.title}"`}
					{typeof this.props.total === 'number' && ` (${this.props.total})`}
					{this.props.sub && (
						<>
							{', '}
							<a
								href={this.props.sub.link}
								onClick={e => {
									e.preventDefault();
									e.stopPropagation();

									this.props.navigate(this.props.sub!.link);
								}}
							>
								{this.props.sub.type} {this.props.sub.entityName}
							</a>
						</>
					)}
				</Typography.Title>
			</div>
		);
	}
}

export default withNavigateParams(PageTitle);
