import { frontmanGet, tableGet, writerPost } from '.';
import { GroupRow } from '../../pages/Groups/GroupCard';
import objectToFormData from '../../utils/objectToFormData';
import AdminPlaceData from '../../web-types/AdminPlaceData';
import CompactPlace from '../../web-types/CompactPlace';
import CompactUserData from '../../web-types/CompactUserData';
import IncomingTxStatus from '../../web-types/IncomingTxStatus';
import InternalRole from '../../web-types/InternalRole';
import { ITableQuery } from '../../web-types/ITableQuery';
import { Sorting } from '../../web-types/Sorting';
import NetworkResponse from '../responses/NetworkResponse';
import NetworkTableResponse from '../responses/NetworkTableResponse';

export interface EmployeeRow {
	id: number;
	phone: string;
	firstName: string;
	lastName: string;
	avatarUrl: string | null;
	balance: number;
	frozen_balance: number;
	role: InternalRole;
	rating_7d: number;
	rating_30d: number;
	rating_mnth: number;
	rating_week: number;
	up_role: string;
	up_text_role: string;
	up_is_paused: boolean;
}

export interface EmployeeRowExt extends EmployeeRow {
	up_unique_user_id: string;
	up_dream: string;
}

export interface IncomingTxRow {
	id: number;
	created_at: string;
	updated_at: string;
	unique_session_id: string;
	external_paygate_id: string | null;
	status: IncomingTxStatus;
	qrCodeId: number | null;
	userId: number;
	placeId: number;
	groupId: number | null;
	clientId: number;
	clientDeviceId: number;
	reviewId: number;
}

export interface ReviewRow {
	id: number;
	created_at: string;
	updated_at: string;
	deleted_at: string;
	unique_session_id: string;
	rating: number;
	text: string;
	notes: string[] | null;
	userId: number;
	placeId: number;
	clientId: number;
	clientDeviceId: number;
	incomingTxId: number;
	incomingTx: IncomingTxRow | null;
	suggestedRedirectTo: string | null;
	suggestedRedirectToUrl: string | null;
	wasRedirected: boolean;
	user: CompactUserData;
}

export interface InternalTxRow {
	id: number;
	created_at: string;
	updated_at: string;
	deleted_at: string;
	incomingTxId: number;
	incomingTx: IncomingTxRow;
	percent: number;
	amount: number;
	placeId: number;
	place: CompactPlace;
	userId: number;
	user: CompactUserData;
	groupId: number | null;
	group: GroupRow | null;
}

export function manager_fetchEmployees(
	tableQuery: ITableQuery,
	params: {
		placeId: number | null;
		notInGroupId: number | null;
		notInPlaceId: number | null;
		chainId?: number | null;
		query: string;
	},
): NetworkTableResponse<EmployeeRow> {
	return tableGet(`/employees/list`, tableQuery, params);
}

export function admin_fetchEmployees(
	tableQuery: ITableQuery,
	params: { notInGroupId: number | null; placeId: number | null; chainId: number | null; query: string },
): NetworkTableResponse<EmployeeRow> {
	return tableGet(`/employees/admin/list`, tableQuery, params);
}

export function admin_fetchChainEmployees(
	tableQuery: ITableQuery,
	params: { notInGroupId: number | null; placeId: number | null; chainId: number | null; query: string },
): NetworkTableResponse<EmployeeRow> {
	return tableGet(`/employees/admin/sub-list`, tableQuery, params);
}

export function admin_fetchEmployee(employeeId: number): Promise<NetworkResponse<EmployeeRowExt>> {
	return frontmanGet(`/employees/${employeeId}`);
}

export function manager_fetchEmployee(placeId: number, employeeId: number): Promise<NetworkResponse<EmployeeRowExt>> {
	return frontmanGet(`/employees/${placeId}/get/${employeeId}`);
}

export function manager_fetchProfile(employeeId: number): Promise<NetworkResponse<EmployeeRowExt>> {
	return frontmanGet(`/employees/profile-get/${employeeId}`);
}

export function manager_fetchEmployeePlaces(
	employeeId: number,
	offset: number,
	limit: number,
	params: { chainId: number | null; query: string; sorting: Sorting | null },
): NetworkTableResponse<AdminPlaceData> {
	return frontmanGet(`/employees/${employeeId}/places`, { offset, limit, params: JSON.stringify(params) });
}

export function manager_fetchEmployeeChains(
	employeeId: number,
	offset: number,
	limit: number,
	params: { chainId: number | null; query: string; sorting: Sorting | null },
): NetworkTableResponse<AdminPlaceData> {
	return frontmanGet(`/employees/${employeeId}/chains`, { offset, limit, params: JSON.stringify(params) });
}

export function manager_fetchEmployeeReviews(
	placeId: number,
	employeeId: number,
	offset: number,
	limit: number,
	params: { query: string; sorting: Sorting | null },
): NetworkTableResponse<ReviewRow> {
	return frontmanGet(`/reviews/${placeId}/list`, { employeeId, offset, limit, params: JSON.stringify(params) });
}

export function manager_fetchEmployeeTransactions(
	placeId: number,
	employeeId: number,
	offset: number,
	limit: number,
	params: { sorting: Sorting | null },
): NetworkTableResponse<InternalTxRow> {
	return frontmanGet(`/transactions/${placeId}/${employeeId}/`, { offset, limit, params: JSON.stringify(params) });
}

export function manager_updateEmployeeDream(placeId: number, employeeId: number, dream: string) {
	return writerPost('POST', `/employees/${placeId}/dream/${employeeId}`, null, { dream });
}

export function manager_updateEmployeeLink(placeId: number, employeeId: number, link: string) {
	return writerPost('POST', `/employees/${placeId}/link/${employeeId}`, null, { link });
}

export function manager_updateEmployeeTextRole(placeId: number, employeeId: number, text_role: string) {
	return writerPost('POST', `/employees/${placeId}/text-role/${employeeId}`, null, { text_role });
}

export function manager_updateEmployeeName(placeId: number, employeeId: number, firstName: string, lastName: string) {
	return writerPost('POST', `/employees/${placeId}/name/${employeeId}`, null, { firstName, lastName });
}

export function manager_deleteEmployee(placeId: number, employeeId: number) {
	return writerPost('DELETE', `/employees/${placeId}/remove/${employeeId}`);
}

export function admin_updateEmployeeName(employeeId: number, firstName: string, lastName: string) {
	return writerPost('POST', `/employees/admin/${employeeId}/name`, null, { firstName, lastName });
}

export function admin_updateEmployeePassword(employeeId: number, password: string) {
	return writerPost('POST', `/employees/admin/${employeeId}/password`, null, { password });
}

export function manager_updateProfilePhone(employeeId: number, phone: string) {
	return writerPost('POST', `/employees/profile-phone/${employeeId}`, null, { phone });
}

export function manager_updateProfilePassword(employeeId: number, password: string) {
	return writerPost('POST', `/employees/profile-password/${employeeId}`, null, { password });
}

export function manager_updateProfileName(employeeId: number, firstName: string, lastName: string) {
	return writerPost('POST', `/employees/profile-name/${employeeId}`, null, { firstName, lastName });
}

export function admin_deleteEmployee(employeeId: number) {
	return writerPost('DELETE', `/employees/admin/${employeeId}/remove`);
}

export function manager_uploadEmployeeAvatar(
	placeId: number,
	employeeId: number,
	avatar: Blob,
): Promise<NetworkResponse<string>> {
	return writerPost('POST', `/employees/${placeId}/avatar/${employeeId}`, null, objectToFormData({ avatar }));
}

export function employee_requestWithdrawal(employeeId: number, amount: number, comment: string) {
	return writerPost('POST', `/employees/${employeeId}/withdraw/`, null, { amount, comment });
}

export function manager_uploadProfileAvatar(employeeId: number, avatar: Blob): Promise<NetworkResponse<string>> {
	return writerPost('POST', `/employees/profile-avatar/${employeeId}`, null, objectToFormData({ avatar }));
}

export function admin_uploadEmployeeAvatar(employeeId: number, avatar: Blob): Promise<NetworkResponse<string>> {
	return writerPost('POST', `/employees/admin/${employeeId}/avatar`, null, objectToFormData({ avatar }));
}
