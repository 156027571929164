import { autobind } from 'core-decorators';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { PureComponent } from 'react';

import logo from '../../assets/logo.svg';

import TipzButton from '../../controls/TipzButton';
import handleError, { handleResponseError } from '../../utils/handleError';
import AdminPlaceData from '../../web-types/AdminPlaceData';
import { fetchInvite, signUp } from '../../lib/api';
import withNavigateParams from '../../utils/withNavigateParams';
import { NavigateFunction } from 'react-router';
import UserPlaceRole from '../../web-types/UserPlaceRole';
import { Form, Input, Spin } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import Avatar from 'antd/lib/avatar/avatar';
import user from '../../stores/user';

export interface InvitePageProps {
	navigate: NavigateFunction;
	params: { inviteCode: string };
	state: null;
}

@observer
class InvitePage extends PureComponent<InvitePageProps> {
	@observable predefinedPhone = '';
	@observable predefinedRole: UserPlaceRole = UserPlaceRole.EMPLOYEE;
	@observable place: AdminPlaceData | null = null;

	@observable status: 'loading' | 'accept-invite' | 'login-and-accept-invite' | 'new-user' = 'loading';

	@observable loading = false;

	@observable firstName: string = '';
	@observable lastName: string = '';

	@observable result: string = '';
	legalData: any = null;

	leftInterval: any = null;

	async componentDidMount() {
		const response = await fetchInvite(this.props.params.inviteCode);
		if (!response.result) {
			return handleResponseError(response);
		}
		const data = response.data;
		this.status = data.result;
		this.place = data.place;
		this.predefinedPhone = data.phone;
		this.predefinedRole = data.role;
	}

	@autobind
	async onAccept() {
		this.loading = true;
		try {
			if (this.status === 'accept-invite') {
				const response = await signUp(this.props.params.inviteCode, {
					firstName: this.firstName,
					lastName: this.lastName,
					legalData: null,
				});
				if (response.result) {
					this.result = response.data;
				} else {
					handleResponseError(response);
				}
			} else if (this.status === 'login-and-accept-invite') {
				this.props.navigate(
					'/login?phone=' +
						encodeURIComponent(this.predefinedPhone) +
						'&back=' +
						encodeURIComponent(`/invite/${this.props.params.inviteCode}`),
				);
			} else if (this.status === 'new-user') {
				const response = await signUp(this.props.params.inviteCode, {
					firstName: this.firstName,
					lastName: this.lastName,
					legalData: null,
				});
				if (response.result) {
					this.result = response.data;
				} else {
					handleResponseError(response);
				}
			} else {
				// no-op
			}
		} catch (err) {
			this.loading = false;
			handleError(err);
		}
		this.loading = false;
	}

	render() {
		if (this.status === 'loading' || !this.place) {
			return (
				<div className="fullpage login-page">
					<Spin />
				</div>
			);
		}

		// user-created
		// relation-created

		return (
			<div className="fullpage login-page">
				<div className="login-form-better">
					{this.result === 'user-created' ? (
						<div className="login-form-inner">
							Success! Now you are employee of the "{this.place.name}" and can receive your tips.
							<br />
							<br />
							You should login to access your profile.
							<br />
							<br />
							<br />
							<TipzButton
								onClick={() => {
									this.props.navigate('/login?phone=' + encodeURIComponent(this.predefinedPhone));
								}}
							>
								Proceed to login
							</TipzButton>
						</div>
					) : this.result === 'relation-created' ? (
						<div className="login-form-inner">
							Success! Now you are employee of the "{this.place.name}" and can receive your tips.
							<TipzButton
								onClick={() => {
									this.props.navigate('/');
								}}
							>
								Proceed to my profile
							</TipzButton>
						</div>
					) : (
						<div className="login-form-inner">
							{this.place.logoUrl ? (
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										width: '100%',
									}}
								>
									<Avatar size={120} src={this.place.logoUrl} icon={<HomeOutlined />} />
								</div>
							) : (
								<img src={logo} className="login-logo" alt="" />
							)}

							<div className="l-maintitle" style={{ fontWeight: 'bold' }}>
								You've been invited to join "{this.place.name}" as an {this.predefinedRole}
							</div>

							<div className="login-form-content">
								{this.status === 'login-and-accept-invite' ? (
									<span>
										You{' '}
										<a
											href="#login"
											onClick={e => {
												e.preventDefault();
												this.props.navigate(
													'/login?phone=' +
														encodeURIComponent(this.predefinedPhone) +
														'&back=' +
														encodeURIComponent(`/invite/${this.props.params.inviteCode}`),
												);
											}}
										>
											should login with phone {this.predefinedPhone}
										</a>{' '}
										to accept this invitation
									</span>
								) : this.status === 'accept-invite' ? (
									'You can accept invite here'
								) : (
									<div>
										<Form.Item label={<span style={{ width: 80, textAlign: 'right' }}>Phone</span>}>
											<Input
												id="phone-input"
												placeholder="Enter your first name"
												value={this.predefinedPhone}
												disabled
											/>
										</Form.Item>
										<Form.Item
											label={<span style={{ width: 80, textAlign: 'right' }}>First name</span>}
										>
											<Input
												id="first-name-input"
												placeholder="Enter your first name"
												value={this.firstName}
												onChange={e => (this.firstName = e ? String(e.target.value) : '')}
											/>
										</Form.Item>
										<Form.Item
											label={<span style={{ width: 80, textAlign: 'right' }}>Last name</span>}
										>
											<Input
												id="last-name-input"
												placeholder="Enter your last name"
												value={this.lastName}
												onChange={e => (this.lastName = e ? String(e.target.value) : '')}
											/>
										</Form.Item>
									</div>
								)}
							</div>

							<TipzButton loading={this.loading} onClick={() => this.onAccept()}>
								{this.status === 'accept-invite'
									? `Accept invitation as ${user.user?.firstName} ${user.user?.lastName}`
									: null}
								{this.status === 'login-and-accept-invite' ? `Login as ${this.predefinedPhone}` : null}
								{this.status === 'new-user' ? `Sign up` : null}
							</TipzButton>
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default withNavigateParams(InvitePage);
