import { autorun, IReactionDisposer, observable } from 'mobx';
import { observer } from 'mobx-react';

import { PureComponent } from 'react';

import './style.scss';
import withNavigateParams from '../../utils/withNavigateParams';
import { NavigateFunction } from 'react-router';
import TipzTable from '../../controls/TipzTable';

import { Sorting } from '../../web-types/Sorting';
import { Button, Popover } from 'antd';
import { EditOutlined, DeleteOutlined, PauseCircleOutlined } from '@ant-design/icons';

@observer
class AdminOutgoingTxsPage extends PureComponent<{ navigate: NavigateFunction; params: any; state: any }> {
	@observable loading = false;
	@observable sorting: Sorting | null = null;
	@observable data: Record<string, any>[] = [];
	dispose!: IReactionDisposer;

	componentDidMount() {
		this.dispose = autorun(() => {
			this.loading = true;
			// fetch data
			this.loading = false;
		});
	}

	componentWillUnmount() {
		this.dispose();
	}

	render() {
		return (
			<div className="page">
				<div className="page-head">
					<div className="page-title">
						<h2>QR Codes</h2>
					</div>
					<div className="page-actions"></div>
				</div>
				<section className="section">
					<TipzTable
						sorting={this.sorting}
						onSortingChange={s => (this.sorting = s)}
						loading={this.loading}
						head={[
							{
								key: 'avatarUrl',
								title: '',
								render: value => (
									<img
										src={value}
										alt="avatar"
										style={{ width: 50, height: 50, borderRadius: '50%' }}
									/>
								),
								width: 50,
							},
							{
								key: 'name',
								title: 'Name',
								sortable: true,
							},
							{
								key: 'text_role',
								title: 'Role',
								sortable: true,
							},
							{
								key: 'id',
								title: 'Actions',
								render: id => (
									<>
										<Popover content="Edit employee">
											<Button type="text" icon={<EditOutlined />} />
										</Popover>
										<Popover content="Pause tips distribution">
											<Button ghost icon={<PauseCircleOutlined />} />
										</Popover>
										<Popover content="Delete employee from organization">
											<Button type="text" icon={<DeleteOutlined />} danger />
										</Popover>
									</>
								),
							},
						]}
						rows={[
							{
								avatarUrl: 'https://i.pravatar.cc/60?r=' + Math.random(),
								name: 'Danila Simonov',
								text_role: 'Chef',
								qr: Math.random() + '' + Math.random() + '' + Math.random() + '' + Math.random(),
								phone: '+97112345678',
							},
							{
								avatarUrl: 'https://i.pravatar.cc/60?r=' + Math.random(),
								name: 'John Mayer',
								text_role: 'Su-Chef',
								qr: Math.random() + '' + Math.random() + '' + Math.random() + '' + Math.random(),
								phone: '+971847294728',
							},
							{
								avatarUrl: 'https://i.pravatar.cc/60?r=' + Math.random(),
								name: 'Muhammad Levish',
								text_role: 'Su-Chef',
								qr: Math.random() + '' + Math.random() + '' + Math.random() + '' + Math.random(),
								phone: '+97100198295',
							},
							{
								avatarUrl: 'https://i.pravatar.cc/60?r=' + Math.random(),
								name: 'Iron Maiden',
								text_role: 'Hostes',
								qr: Math.random() + '' + Math.random() + '' + Math.random() + '' + Math.random(),
								phone: '+97162947294',
							},
							{
								avatarUrl: 'https://i.pravatar.cc/60?r=' + Math.random(),
								name: 'John Grishin',
								text_role: 'Servant',
								qr: Math.random() + '' + Math.random() + '' + Math.random() + '' + Math.random(),
								phone: '+97166830144',
							},
							{
								avatarUrl: 'https://i.pravatar.cc/60?r=' + Math.random(),
								name: 'Islec Malotun',
								text_role: 'Servant',
								qr: Math.random() + '' + Math.random() + '' + Math.random() + '' + Math.random(),
								phone: '+97174916644',
							},
							{
								avatarUrl: 'https://i.pravatar.cc/60?r=' + Math.random(),
								name: 'Gompher Celvish',
								text_role: 'Servant',
								qr: Math.random() + '' + Math.random() + '' + Math.random() + '' + Math.random(),
								phone: '+97100217887',
							},
						]}
					/>
				</section>
			</div>
		);
	}
}

export default withNavigateParams(AdminOutgoingTxsPage);
