import { frontmanGet, frontmanGetUrl } from '.';
import { Sorting } from '../../web-types/Sorting';
import NetworkTableResponse from '../responses/NetworkTableResponse';
import { ReviewRow } from './employees';

export interface ReviewRowExt extends ReviewRow {
	user: any;
	place: any;
}

export function manager_fetchReviews(
	placeId: number,
	offset: number,
	limit: number,
	params: {
		query: string;
		startDate: string | null;
		endDate: string | null;
		sorting: Sorting | null;
		rating: number | null;
	},
): NetworkTableResponse<ReviewRowExt> {
	return frontmanGet(`/reviews/${placeId}/list`, { offset, limit, params: JSON.stringify(params) });
}

export function manager_downloadReviewsUrl(
	placeId: number,
	offset: number,
	limit: number,
	params: {
		query: string;
		startDate: string | null;
		endDate: string | null;
		sorting: Sorting | null;
		rating: number | null;
	},
): string {
	return frontmanGetUrl(`/reviews/${placeId}/download`, { offset, limit, params: JSON.stringify(params) });
}

export function manager_fetchChainReviews(
	chainId: number,
	offset: number,
	limit: number,
	params: {
		query: string;
		startDate: string | null;
		endDate: string | null;
		sorting: Sorting | null;
		rating: number | null;
	},
): NetworkTableResponse<ReviewRowExt> {
	return frontmanGet(`/reviews/chain/${chainId}/list`, { offset, limit, params: JSON.stringify(params) });
}

export function manager_downloadChainReviewsUrl(
	chainId: number,
	offset: number,
	limit: number,
	params: {
		query: string;
		startDate: string | null;
		endDate: string | null;
		sorting: Sorting | null;
		rating: number | null;
	},
): string {
	return frontmanGetUrl(`/reviews/chain/${chainId}/download`, { offset, limit, params: JSON.stringify(params) });
}

export function admin_fetchReviews(
	offset: number,
	limit: number,
	params: {
		query: string;
		placeId: number | null;
		startDate: string | null;
		endDate: string | null;
		sorting: Sorting | null;
	},
): NetworkTableResponse<ReviewRowExt> {
	return frontmanGet(`/reviews/admin/list`, { offset, limit, params: JSON.stringify(params) });
}
