import React, { PureComponent } from 'react';

import { observer } from 'mobx-react';
import { NavigateFunction } from 'react-router';
import withNavigateParams from '../../utils/withNavigateParams';
import { observable } from 'mobx';
import {
	admin_deleteChain,
	admin_fetchChain,
	manager_updateChainName,
	manager_uploadChainAvatar,
} from '../../lib/api-web';
import toaster from '../../stores/toaster';
import AdminChainData from '../../web-types/AdminChainData';
import { Menu, Spin } from 'antd';
import { HomeOutlined, UploadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { TPage, TPContent, TPSidebar, TPSidebarHeader } from '../../controls/TPage';
import Avatar from 'antd/lib/avatar/avatar';
import AvatarUploadModal from '../../global-modals/AvatarUploadModal';
import dataUriToBlob from '../../utils/dataUriToBlob';
import { StatDiv, StatHeader, StatPlate, StatPlateTitle, StatPlateValue } from '../../controls/StatHeader';
import PromptModal from '../../global-modals/PromptModal';
import handleError from '../../utils/handleError';
import { AdminChainMetaData } from '../../web-types/AdminPlaceMetaData';
import formatNumber from '../../utils/formatNumber';
import formatMoney from '../../utils/formatMoney';
import ConfirmModal from '../../global-modals/ConfirmModal';
import BackButton from '../../controls/BackButton';

interface ChainProfileProps {
	navigate: NavigateFunction;
	params: { chainId: string };
	state: { backable: boolean } | null;
}

@observer
class ChainProfile extends PureComponent<ChainProfileProps> {
	@observable loading = false;
	@observable avatarUploading = false;
	@observable nameEditing = false;
	@observable paymentDataEditing = false;
	@observable employeeInviting = false;
	@observable placeDeleting = false;
	@observable data: AdminChainData | null = null;
	@observable metadata: AdminChainMetaData | null = null;
	@observable imageUrl: string | null = null;

	async reload() {
		this.loading = true;
		try {
			const result = await admin_fetchChain(Number(this.props.params.chainId));
			if (result.result) {
				this.data = result.data.data;
				this.metadata = result.data.metadata;
			} else {
				toaster.show({
					type: 'error',
					message: result.error,
				});
			}
		} catch (err) {
			toaster.show({
				type: 'error',
				message: (err as Error).message,
			});
		}
		this.loading = false;
	}

	componentDidMount() {
		this.reload();
	}

	componentDidUpdate(prevProps: ChainProfileProps) {
		if (prevProps.params.chainId !== this.props.params.chainId) {
			this.reload();
		}
	}

	handleClick(info: any) {
		console.log('info: ', info);
	}

	async handleAvatarUpload() {
		const newAvatarDataUri = await AvatarUploadModal.prompt();
		if (newAvatarDataUri) {
			this.avatarUploading = true;
			try {
				await manager_uploadChainAvatar(
					Number(this.props.params.chainId),
					await dataUriToBlob(newAvatarDataUri),
				);
				await this.reload();
			} catch (err) {
				handleError(err);
			}
			this.avatarUploading = false;
		}
	}

	async handleEditName() {
		const newName = await PromptModal.prompt('Change place name', this.data!.name);
		if (newName) {
			this.nameEditing = true;
			try {
				await manager_updateChainName(Number(this.props.params.chainId), newName);
				await this.reload();
			} catch (err) {
				handleError(err);
			}
			this.nameEditing = false;
		}
	}

	async handleDelete() {
		const descision = await ConfirmModal.prompt(
			'Are you sure?',
			'This action is irreversible. You will lose all the connections between places and this chain. Are you absolutely sure?',
			'Yes',
		);
		if (!descision) {
			return;
		}
		this.placeDeleting = true;
		try {
			const response = await admin_deleteChain(Number(this.props.params.chainId));
			if (response.result) {
				this.placeDeleting = false;
				toaster.show({ message: 'You successfully deleted the place', type: 'success' });
				this.props.navigate('/admin-places/');
			} else {
				handleError(response.error);
			}
		} catch (err) {
			handleError(err);
		}
	}

	render() {
		if (!this.data || !this.metadata) {
			return <Spin />;
		}

		return (
			<div className="page">
				<BackButton props={this.props} />
				<div className="page-head">
					<div className="page-title">
						<h2>Chain "{this.data.name}"</h2>
					</div>
					<div className="page-actions"></div>
				</div>
				<TPage className="section">
					<TPSidebar>
						<TPSidebarHeader>
							{this.avatarUploading ? (
								<Avatar size={120} src={null} icon={<Spin />} />
							) : (
								<Avatar size={120} src={this.data.logoUrl} icon={<HomeOutlined />} />
							)}
						</TPSidebarHeader>
						<Menu inlineIndent={12} selectable={false} onClick={this.handleClick} mode="inline">
							<Menu.Item
								key="1"
								icon={<UploadOutlined />}
								onClick={e => {
									e.domEvent.preventDefault();
									this.handleAvatarUpload();
								}}
							>
								Upload new image
							</Menu.Item>
							<Menu.Item
								key="2"
								icon={this.nameEditing ? <Spin /> : <EditOutlined />}
								onClick={e => {
									e.domEvent.preventDefault();
									this.handleEditName();
								}}
							>
								Edit name
							</Menu.Item>
							<Menu.Divider />
							<Menu.Item
								key="4"
								danger
								icon={<DeleteOutlined />}
								onClick={e => {
									e.domEvent.preventDefault();
									this.handleDelete();
								}}
							>
								Delete chain
							</Menu.Item>
						</Menu>
					</TPSidebar>
					<TPContent>
						<StatHeader>
							<StatPlate>
								<StatPlateTitle>Employees</StatPlateTitle>
								<StatPlateValue>{formatNumber(this.metadata.employees_count)}</StatPlateValue>
							</StatPlate>
							<StatDiv />
							<StatPlate>
								<StatPlateTitle>7d tips volume</StatPlateTitle>
								<StatPlateValue>{formatMoney(this.metadata.tips_volume_7d)}</StatPlateValue>
							</StatPlate>
							<StatDiv />
							<StatPlate>
								<StatPlateTitle>Avg. tip volume</StatPlateTitle>
								<StatPlateValue>{formatMoney(this.metadata.average_tip_30d)}</StatPlateValue>
							</StatPlate>
						</StatHeader>
					</TPContent>
				</TPage>
			</div>
		);
	}
}

export default withNavigateParams(ChainProfile);
