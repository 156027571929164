import classNames from 'classnames';
import './style.scss';

export interface MetricChangeProps {
	newValue: number;
	oldValue: number;
	toFixed?: number;
	isString?: boolean;
	addon?: string;
	className?: string;
	trimZeroz?: boolean;
}

export default function MetricChange({
	newValue,
	oldValue,
	className,
	isString = false,
	toFixed = 2,
	trimZeroz = true,
	addon = '',
}: MetricChangeProps): JSX.Element {
	if (isString) {
		if (!oldValue) {
			return <>{Number(newValue.toFixed(toFixed))}</>;
		} else {
			const change = Number((((newValue - oldValue) / oldValue) * 100).toFixed(1));
			if (!change) {
				return (
					<>
						{(trimZeroz ? Number : String)(newValue.toFixed(toFixed))}
						{addon ? ` ${addon}` : ''}
					</>
				);
			}
			if (oldValue < newValue) {
				return (
					<>
						{(trimZeroz ? Number : String)(newValue.toFixed(toFixed))}
						{addon ? ` ${addon}` : ''} (+{change}%)
					</>
				);
			} else {
				return (
					<>
						{(trimZeroz ? Number : String)(newValue.toFixed(toFixed))}
						{addon ? ` ${addon}` : ''} (-{-change}%)
					</>
				);
			}
		}
	} else {
		if (!oldValue) {
			return (
				<span className={classNames('metric-change no-change', className)}>
					<span className="original-value">
						{(trimZeroz ? Number : String)(newValue.toFixed(toFixed))}
						{addon ? ` ${addon}` : ''}
					</span>
				</span>
			);
		} else {
			const change = Number((((newValue - oldValue) / oldValue) * 100).toFixed(1));
			if (!change) {
				return (
					<span className={classNames('metric-change no-change', className)}>
						<span className="original-value">
							{(trimZeroz ? Number : String)(newValue.toFixed(toFixed))}
							{addon ? ` ${addon}` : ''}
						</span>
					</span>
				);
			}
			if (oldValue < newValue) {
				return (
					<span className={classNames('metric-change', className)}>
						<span className="original-value">
							{(trimZeroz ? Number : String)(newValue.toFixed(toFixed))}
							{addon ? ` ${addon}` : ''}
						</span>
						<span className="m-change asc">+{change}%</span>
					</span>
				);
			} else {
				return (
					<span className={classNames('metric-change', className)}>
						<span className="original-value">
							{(trimZeroz ? Number : String)(newValue.toFixed(toFixed))}
							{addon ? ` ${addon}` : ''}
						</span>
						<span className="m-change desc">-{-change}%</span>
					</span>
				);
			}
		}
	}
}
