import Modal from 'antd/lib/modal/Modal';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { PureComponent } from 'react';
import modals from '../../stores/modals';
import { Form, Input } from 'antd';

export interface PromptModalProps {
	title: string;
	defaultValue?: string;
	helpText?: string;
	type?: 'input' | 'textarea';
	validator?: (t: string) => boolean;
	onDismiss: () => void;
	onConfirm: (data: string) => void;
}

@observer
export default class PromptModal extends PureComponent<PromptModalProps> {
	static async prompt(
		title: string,
		defaultValue?: string,
		validator?: (t: string) => boolean,
		helpText?: string,
		type?: 'input' | 'textarea',
	): Promise<string | null> {
		return new Promise<string | null>((resolve, reject) => {
			modals.show((close: () => void) => (
				<PromptModal
					title={title}
					type={type}
					validator={validator}
					defaultValue={defaultValue}
					helpText={helpText}
					onConfirm={url => {
						close();
						resolve(url);
					}}
					onDismiss={() => {
						close();
						resolve(null);
					}}
				/>
			));
		});
	}

	@observable value: string = this.props.defaultValue || '';

	render() {
		const isValid = this.props.validator ? this.props.validator(this.value) : !!this.value.length;
		const type = this.props.type ? this.props.type : 'input';
		return (
			<Modal
				title={this.props.title}
				okButtonProps={{ disabled: !isValid }}
				onCancel={() => this.props.onDismiss()}
				visible={true}
				closable={false}
				onOk={() => {
					this.props.onConfirm(this.value);
				}}
			>
				<Form>
					<Form.Item validateStatus={isValid ? 'success' : 'error'} help={this.props.helpText}>
						{type === 'input' ? (
							<Input
								autoFocus={true}
								style={{ width: '100%' }}
								placeholder="Enter value..."
								value={this.value}
								onKeyPress={e => (e.key === 'Enter' ? this.props.onConfirm(this.value) : null)}
								onChange={e => (this.value = e.target.value)}
							/>
						) : (
							<Input.TextArea
								autoFocus={true}
								style={{ width: '100%', minHeight: 200 }}
								placeholder="Enter value..."
								value={this.value}
								onChange={e => (this.value = e.target.value)}
							/>
						)}
					</Form.Item>
				</Form>
			</Modal>
		);
	}
}
