import { HTMLAttributes, ReactNode } from 'react';

import { observer } from 'mobx-react';
import cn from 'classnames';

import './style.scss';
import { Sorting } from '../../web-types/Sorting';
import { Empty, Spin } from 'antd';
import { SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { get } from 'lodash';

export interface TipzTableProps<T extends Record<string, any>> extends HTMLAttributes<HTMLTableElement> {
	sorting: Sorting | null;
	head: {
		key: string;
		title: ReactNode;
		titleClass?: string;
		cellClass?: string | ((value: any, row: T, rowIdx: number) => string);
		width?: number;
		minWidth?: number;
		maxWidth?: number;
		render?: (value: any, row: T, rowIdx: number) => ReactNode;
		sortable?: boolean;
	}[];
	rows: T[];
	onSortingChange?: (sorting: Sorting | null) => void;
	loading?: boolean;
	onRowClick?: (rowData: T) => void;
	noDataText?: string;
	rowClass?: string | ((row: T, rowIdx: number) => string);
}

function TipzTable<T extends Record<string, any>>({
	onSortingChange,
	onRowClick,
	loading,
	sorting,
	head,
	rows,
	className,
	noDataText,
	rowClass,
	...props
}: TipzTableProps<T>) {
	return (
		<div className="tipz-table-wrap">
			<table
				className={cn(
					'tipz-table bp3-html-table-striped',
					{ loading, 'bp3-interactive': !!onRowClick },
					className,
				)}
				{...props}
			>
				<thead>
					<tr>
						{head.map(h => (
							<th
								style={{
									width: h.width ? h.width : undefined,
									minWidth: h.minWidth || h.width ? h.minWidth || h.width : undefined,
									maxWidth: h.maxWidth ? h.maxWidth : undefined,
								}}
								key={h.key}
								className={cn({ sortable: h.sortable }, h.titleClass)}
								onClick={
									h.sortable
										? () => {
												if (!sorting || sorting.key !== h.key) {
													onSortingChange &&
														onSortingChange({ key: h.key, direction: 'ASC' });
												} else if (sorting.direction === 'ASC') {
													onSortingChange &&
														onSortingChange({ key: h.key, direction: 'DESC' });
												} else {
													onSortingChange && onSortingChange(null);
												}
										  }
										: void 0
								}
							>
								{h.title}
								{sorting?.key === h.key ? (
									sorting.direction === 'ASC' ? (
										<SortAscendingOutlined
											className="sorting-icon"
											size={12}
											style={{
												marginLeft: 10,
												marginBottom: 1,
											}}
										/>
									) : (
										<SortDescendingOutlined
											className="sorting-icon"
											size={12}
											style={{
												marginLeft: 10,
												marginBottom: 1,
											}}
										/>
									)
								) : null}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{rows.length === 0 && (
						<>
							<tr>
								<td colSpan={head.length}>
									<div
										style={{
											paddingTop: 50,
											paddingBottom: 50,
										}}
									>
										<Empty description={noDataText || 'No Data'} />
									</div>
								</td>
							</tr>
						</>
					)}
					{rows.map((r, idx) => (
						<tr
							className={typeof rowClass === 'function' ? rowClass(r, idx) : rowClass}
							key={idx}
							onClick={() => onRowClick && onRowClick(r)}
						>
							{head.map(h => (
								<td
									className={cn(
										typeof h.cellClass === 'function'
											? h.cellClass(get(r, h.key), r, idx)
											: h.cellClass,
									)}
									style={h.width ? { width: h.width } : {}}
									key={h.key}
								>
									{h.render ? h.render(get(r, h.key), r, idx) : get(r, h.key)}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
			{loading ? (
				<div className="tipz-table-overlay">
					<Spin />
				</div>
			) : null}
		</div>
	);
}

export default observer(TipzTable);
