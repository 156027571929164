import { IReactComponent } from 'mobx-react/dist/types/IReactComponent';
import React from 'react';
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router';

function withNavigateParams<P extends { navigate: NavigateFunction; params: any; state: any }>(
	Cmp: IReactComponent<P>,
) {
	return React.forwardRef((props: Omit<P, 'navigate' | 'params' | 'state'>, ref) => {
		const navigate = useNavigate();
		const location = useLocation();
		const params = useParams();
		return (
			//@ts-ignore
			<Cmp ref={ref} state={location.state} navigate={navigate} params={params} {...props} />
		);
	});
}

export default withNavigateParams;
