import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { PureComponent } from 'react';

import './style.scss';
import withNavigateParams from '../../utils/withNavigateParams';
import { NavigateFunction, Outlet } from 'react-router';
import { Sorting } from '../../web-types/Sorting';
import { admin_createChain, admin_fetchChains } from '../../lib/api-web';
import { Avatar, Button } from 'antd';
import { ApartmentOutlined, PlusOutlined } from '@ant-design/icons';
import PromptModal from '../../global-modals/PromptModal';
import handleError, { handleResponseError } from '../../utils/handleError';
import { TableController } from '../../controls/TableController';
import { autobind } from 'core-decorators';
import PageTitle from '../../controls/PageTitle';

@observer
class AdminChainsPage extends PureComponent<{ navigate: NavigateFunction; params: any; state: any }> {
	@observable loading = false;
	@observable chainAdding = false;
	@observable contentVisible = false;
	@observable total: number | null = null;

	@autobind
	handleTablePropsUpdate(contentVisible: boolean, total: number | null, rowsPerPage: number) {
		this.contentVisible = contentVisible;
		this.total = total;
	}

	@autobind
	async handleLoad(params: { query: string }, offset: number, limit: number, sorting: Sorting | null) {
		return admin_fetchChains(offset, limit, {
			query: params.query,
			sorting,
		});
	}

	async handleAddChain() {
		const chainName = await PromptModal.prompt('Enter chain name', '');
		if (chainName) {
			this.chainAdding = true;
			try {
				const response = await admin_createChain(chainName);
				if (response.result) {
					this.props.navigate(`/admin-chains/${response.data}`, { state: { backable: true } });
				} else {
					handleResponseError(response);
				}
			} catch (err) {
				handleError(err);
			}
			this.chainAdding = false;
		}
	}

	render() {
		if (this.props.params.chainId) {
			return <Outlet />;
		}
		return (
			<div className="page">
				<div className="page-head">
					<PageTitle type="Chains" />
					<div className="page-actions">
						<Button
							type="primary"
							icon={<PlusOutlined />}
							onClick={e => {
								e.preventDefault();
								this.handleAddChain();
							}}
						>
							Add chain
						</Button>
					</div>
				</div>
				<TableController
					params={{ query: '' }}
					load={this.handleLoad}
					onRowClick={({ id }) =>
						this.props.navigate('/admin-chains/' + id + '/', { state: { backable: true } })
					}
					columns={[
						{
							key: 'logoUrl',
							title: '',
							render: value => <Avatar src={value} alt="" icon={<ApartmentOutlined />} />,
							width: 50,
						},
						{
							key: 'name',
							title: 'Title',
							sortable: true,
						},
						{
							key: 'placesCount',
							title: 'Places',
							sortable: true,
						},
						{
							key: 'employeesCount',
							title: 'Employees',
							sortable: true,
						},
						{
							key: 'tipsVolume7d',
							title: '7d tips volume',
							sortable: true,
						},
					]}
				/>
			</div>
		);
	}
}

export default withNavigateParams(AdminChainsPage);
