import { FC } from 'react';
import cn from 'classnames';

import './style.scss';
import { Button, ButtonProps } from 'antd';

const TipzButton: FC<ButtonProps> = ({ children, className, ...rest }) => {
	return (
		<Button className={cn('tipz-button', className)} {...rest}>
			{children}
		</Button>
	);
};

export default TipzButton;
