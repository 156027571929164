import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { PureComponent } from 'react';
import { Outlet } from 'react-router';
import modals from '../../stores/modals';

import Header from './Header';
import Sidebar from './Sidebar';
import './style.scss';

@observer
export default class MainLayout extends PureComponent {
	@observable sidebarVisible = window.outerWidth >= 600;

	render() {
		return (
			<div className="fullpage main-page">
				<Sidebar
					visible={this.sidebarVisible}
					onSidebarClose={() => (this.sidebarVisible = window.outerWidth >= 600)}
				/>
				<div className="content">
					<Header onSidebarVisibleChange={() => (this.sidebarVisible = !this.sidebarVisible)} />
					<div className="layout">
						<Outlet />
					</div>
				</div>
				{modals.render()}
			</div>
		);
	}
}
