export default function TotalTipsCountIcon() {
	return (
		<svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M22.1667 23.8333H5.83334C4.90509 23.8333 4.01485 23.4909 3.35847 22.8814C2.70209 22.2719 2.33334 21.4453 2.33334 20.5833V3.25C2.33334 2.96268 2.45626 2.68713 2.67505 2.48397C2.89384 2.2808 3.19059 2.16667 3.50001 2.16667H19.8333C20.1428 2.16667 20.4395 2.2808 20.6583 2.48397C20.8771 2.68713 21 2.96268 21 3.25V16.25H25.6667V20.5833C25.6667 21.4453 25.2979 22.2719 24.6416 22.8814C23.9852 23.4909 23.0949 23.8333 22.1667 23.8333ZM21 18.4167V20.5833C21 20.8707 21.1229 21.1462 21.3417 21.3494C21.5605 21.5525 21.8573 21.6667 22.1667 21.6667C22.4761 21.6667 22.7728 21.5525 22.9916 21.3494C23.2104 21.1462 23.3333 20.8707 23.3333 20.5833V18.4167H21ZM18.6667 21.6667V4.33333H4.66668V20.5833C4.66668 20.8707 4.78959 21.1462 5.00839 21.3494C5.22718 21.5525 5.52392 21.6667 5.83334 21.6667H18.6667ZM7.00001 7.58333H16.3333V9.75H7.00001V7.58333ZM7.00001 11.9167H16.3333V14.0833H7.00001V11.9167ZM7.00001 16.25H12.8333V18.4167H7.00001V16.25Z"
				fill="white"
			/>
		</svg>
	);
}
