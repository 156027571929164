import { FC, HTMLAttributes } from 'react';
import cn from 'classnames';
import './style.scss';

export const TPage: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className, ...props }) => (
	<section className={cn('TPage', className)} {...props}>
		{children}
	</section>
);
export const TPSidebar: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className, ...props }) => (
	<div className={cn('TPSidebar', className)} {...props}>
		{children}
	</div>
);
export const TPSidebarHeader: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className, ...props }) => (
	<div className={cn('TPSidebarHeader', className)} {...props}>
		{children}
	</div>
);
export const TPSidebarItem: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className, ...props }) => (
	<div className={cn('TPSidebarItem', className)} {...props}>
		{children}
	</div>
);
export const TPContent: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className, ...props }) => (
	<div className={cn('TPContent', className)} {...props}>
		{children}
	</div>
);
