import { Avatar, Button, Dropdown, Menu } from 'antd';
import MenuItem from 'antd/lib/menu/MenuItem';
import { observer } from 'mobx-react';
import { FC } from 'react';
import { useNavigate } from 'react-router';
import * as api from '../../lib/api';
import places from '../../stores/places';
import user from '../../stores/user';
import InternalRole from '../../web-types/InternalRole';
import {
	DownOutlined,
	UserOutlined,
	LogoutOutlined,
	BuildOutlined,
	MenuOutlined,
	ProfileOutlined,
	EditOutlined,
	AppstoreOutlined,
	ApartmentOutlined,
} from '@ant-design/icons';
import CompactPlace from '../../web-types/CompactPlace';
import chains from '../../stores/chains';
import i18n from '../../stores/i18n';

const Header: FC<{ onSidebarVisibleChange: () => void }> = observer(({ children, onSidebarVisibleChange }) => {
	const navigate = useNavigate();
	if (!user.user) {
		return null;
	}

	const isMobile = window.outerWidth < 600;

	const chainToPlaceMap: Record<string, CompactPlace[]> = {};

	for (const p of places.places) {
		const internalId = chains.chains.find(c => c.id === p.chainId) ? String(p.chainId) : 'null';
		if (!chainToPlaceMap[internalId]) {
			chainToPlaceMap[internalId] = [];
		}
		chainToPlaceMap[internalId].push(p);
	}

	const menu = (
		<Menu>
			{Object.keys(chainToPlaceMap).map(internalId => {
				const chain = internalId === 'null' ? null : chains.chains.find(c => c.id === Number(internalId));
				return (
					<>
						{internalId !== 'null' && (
							<MenuItem
								key={`c${chain!.id}`}
								icon={
									<Avatar
										size={'small'}
										className="avatar-places-dropdown"
										style={{ marginRight: 6 }}
										src={chain?.logoUrl}
										icon={<ApartmentOutlined />}
									/>
								}
								onClick={() => {
									chains.selectChainId(chain!.id, href => (document.location.href = href));
								}}
							>
								<div
									style={{
										width: '100%',
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'space-between',
									}}
								>
									<span>{chain!.name}</span>
									<Button
										onClick={e => {
											e.stopPropagation();
											document.location.href = `/chain/${chain!.id}/profile`;
										}}
										style={{ marginLeft: 20 }}
										size="small"
										icon={<EditOutlined />}
									>
										{i18n.m.EDIT_PROFILE}
									</Button>
								</div>
							</MenuItem>
						)}
						{chainToPlaceMap[internalId].map(p => (
							<MenuItem
								key={`p${p.id}`}
								style={{ paddingLeft: 30 }}
								icon={
									<Avatar
										size={'small'}
										className="avatar-places-dropdown"
										style={{ marginRight: 6 }}
										src={p?.logoUrl}
										icon={<BuildOutlined />}
									/>
								}
								onClick={() => {
									places.selectPlaceId(p.id, href => (document.location.href = href));
								}}
							>
								<div
									style={{
										width: '100%',
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'space-between',
									}}
								>
									<span>{p.name}</span>
									<Button
										onClick={e => {
											e.stopPropagation();
											document.location.href = `/${p.id}/profile`;
										}}
										style={{ marginLeft: 20 }}
										size="small"
										icon={<EditOutlined />}
									>
										{i18n.m.EDIT_PROFILE}
									</Button>
								</div>
							</MenuItem>
						))}
					</>
				);
			})}
		</Menu>
	);

	return (
		<div className="header">
			<div className="header-left">
				{isMobile ? (
					<div style={{ marginRight: 15 }}>
						<Button onClick={onSidebarVisibleChange} icon={<MenuOutlined />} />
					</div>
				) : null}
				{user.user.role === InternalRole.NONE ? (
					places.place ? (
						<Dropdown.Button size="large" icon={<DownOutlined />} overlay={menu}>
							{/* {places.place.logoUrl ? ( */}
							<Avatar
								size={'small'}
								style={{ marginRight: 6 }}
								src={places.place.logoUrl}
								icon={<ApartmentOutlined />}
							/>
							{/* // ) : (
							// 	// <img
							// 	// 	alt=""
							// 	// 	style={{
							// 	// 		width: 20,
							// 	// 		height: 20,
							// 	// 		borderRadius: '50%',
							// 	// 		marginRight: 10,
							// 	// 		marginTop: -3,
							// 	// 		marginLeft: -6,
							// 	// 	}}
							// 	// 	src={places.place.logoUrl}
							// 	// />
							// 	<BuildOutlined />
							// )} */}
							{places.place.name}
						</Dropdown.Button>
					) : chains.chain ? (
						<Dropdown.Button size="large" icon={<DownOutlined />} overlay={menu}>
							<Avatar
								size={'small'}
								style={{ marginRight: 6 }}
								src={chains.chain.logoUrl}
								icon={<AppstoreOutlined />}
							/>
							{/* {chains.chain.logoUrl ? (
								<img
									alt=""
									style={{
										width: 20,
										height: 20,
										borderRadius: '50%',
										marginRight: 10,
										marginTop: -3,
										marginLeft: -6,
									}}
									src={chains.chain.logoUrl}
								/>
							) : null} */}
							{chains.chain.name}
						</Dropdown.Button>
					) : (
						<Dropdown.Button icon={<DownOutlined />} overlay={menu}>
							{i18n.m.SELECT_PLACE}...
						</Dropdown.Button>
					)
				) : null}
			</div>
			<div className="header-right">
				{/* <div className="nots">
					<Popover
						trigger="hover"
						placement="bottomLeft"
						content={<div style={{ padding: 10, minWidth: 300 }}>No unread notifications</div>}
					>
						<div className="nots-inner">
							<NotificationOutlined />
							{notifications.nots.some(n => n.unread) ? <span className="emphaizer" /> : null}
						</div>
					</Popover>
				</div> */}
				<Dropdown
					overlay={
						<Menu>
							<MenuItem
								icon={<ProfileOutlined />}
								onClick={async () => {
									navigate('/profile');
								}}
							>
								<span style={{ marginLeft: 10 }}>{i18n.m.PROFILE}</span>
							</MenuItem>
							<MenuItem
								icon={<LogoutOutlined />}
								onClick={async () => {
									await api.signOut();
									user.user = null;
									user.save();
								}}
							>
								<span style={{ marginLeft: 10 }}>{i18n.m.LOGOUT}</span>
							</MenuItem>
						</Menu>
					}
				>
					<div className="profile">
						{!isMobile && (
							<span className="user-name">{user.user!.firstName + ' ' + user.user!.lastName}</span>
						)}
						<Avatar src={user.user?.avatarUrl} icon={<UserOutlined style={{ fontSize: 30 }} />} />
					</div>
				</Dropdown>
			</div>
		</div>
	);
});

export default Header;
