import { PureComponent } from 'react';

import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { Button, Input, List, Popover, Select, Spin } from 'antd';
import { DeleteOutlined, PlusOutlined, FacebookOutlined, InstagramOutlined, LinkOutlined } from '@ant-design/icons';

import withNavigateParams from '../../utils/withNavigateParams';
import { NavigateFunction } from 'react-router';
import places from '../../stores/places';

import './style.scss';
import { manager_fetchPlace, manager_savePlaceLinks } from '../../lib/api-web/places';
import AdminPlaceData from '../../web-types/AdminPlaceData';
import { PlaceLink } from '../../web-types/PlaceLinks';

@observer
class SettingsPage extends PureComponent<{ navigate: NavigateFunction; params: any; state: any }> {
	@observable loading = false;
	@observable data: AdminPlaceData | null = null;

	@observable type: PlaceLink['type'] = 'tripadvisor';
	@observable link: string = '';

	async saveLinks() {
		await manager_savePlaceLinks(this.props.params.placeId, this.data!.links);
	}

	async load() {
		const response = await manager_fetchPlace(this.props.params.placeId);
		if (response.result) {
			this.data = response.data;
		}
	}

	componentDidMount() {
		this.load();
	}

	render() {
		if (!this.data || this.loading) {
			return (
				<div key="one" className="page">
					<Spin />
				</div>
			);
		}
		return (
			<div key="two" className={cn('page')}>
				<div className="page-head">
					<div className="page-title">
						<h2>Settings of {places.place?.name}</h2>
					</div>
					<div className="page-actions"></div>
				</div>
				<section className="section">
					<div>
						<h3>Redirect links</h3>
						<List
							bordered
							header={
								<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch' }}>
									<Select
										onSelect={(v: PlaceLink['type']) => {
											this.type = v;
										}}
										value={this.type}
										className="select-before"
									>
										<Select.Option value="instagram">Instagram</Select.Option>
										<Select.Option value="facebook">Facebook</Select.Option>
										<Select.Option value="tripadvisor">Tripadvisor</Select.Option>
										<Select.Option value="google">Google</Select.Option>
										<Select.Option value="custom">Other</Select.Option>
									</Select>
									<Input
										style={{ flexGrow: 1 }}
										value={this.link}
										onChange={e => (this.link = e.target.value)}
									/>
									<Button
										type="primary"
										icon={<PlusOutlined />}
										onClick={() => {
											this.data!.links.push({
												type: this.type,
												link: this.link,
											});
											this.link = '';
											this.saveLinks();
										}}
									>
										Add link
									</Button>
								</div>
							}
							dataSource={toJS(this.data.links) as PlaceLink[]}
							renderItem={(item, index) => (
								<List.Item>
									<div
										style={{
											width: '100%',
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
										}}
									>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
												flexBasis: 40,
											}}
										>
											{item.type === 'facebook' && (
												<FacebookOutlined style={{ color: '#4267B2', fontSize: 24 }} />
											)}
											{item.type === 'instagram' && (
												<InstagramOutlined style={{ color: '#E1306C', fontSize: 24 }} />
											)}
											{item.type === 'custom' && <LinkOutlined style={{ fontSize: 24 }} />}
											{item.type === 'google' && <LinkOutlined style={{ fontSize: 24 }} />}
											{item.type === 'tripadvisor' && (
												<svg
													width="24"
													height="24"
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 64 64"
												>
													<path
														d="M39.1 13.516a50.1 50.1 0 0 1 5.3 1.209 32.624 32.624 0 0 1 8.492 3.929 1.435 1.435 0 0 0 .7.2h10.189v.1a4.39 4.39 0 0 0-.4.705 16.853 16.853 0 0 0-2.5 5.239.477.477 0 0 0 .1.6 15.734 15.734 0 0 1 2.5 13.3 15.315 15.315 0 0 1-7.094 9.772 15.97 15.97 0 0 1-8.487 2.422 15.691 15.691 0 0 1-3.8-.5 16.211 16.211 0 0 1-7.893-4.634 9.057 9.057 0 0 1-.9-1.007c-1.1 1.713-2.3 3.425-3.4 5.138-1.2-1.713-2.3-3.425-3.4-5.037-.1 0-.1 0-.1.1l-.1.1a15.544 15.544 0 0 1-9.891 5.641 14.656 14.656 0 0 1-6.594-.4 15.314 15.314 0 0 1-7.793-4.936 15.784 15.784 0 0 1-3.8-8.16 13.951 13.951 0 0 1 .3-6.347 13.547 13.547 0 0 1 2.4-5.339.76.76 0 0 0 .1-.5 21.114 21.114 0 0 0-2.2-4.836 7.687 7.687 0 0 0-.8-1.108v-.1h9.9c.1 0 .3-.1.4-.1a34.036 34.036 0 0 1 7.194-3.526 50.8 50.8 0 0 1 5.6-1.511 33.995 33.995 0 0 1 5.6-.705 41.028 41.028 0 0 1 10.377.291zm-4 21.458a12.789 12.789 0 1 0 12.6-12.895 12.8 12.8 0 0 0-12.593 12.895zM15.924 22.079a12.846 12.846 0 1 0 12.788 12.895 12.706 12.706 0 0 0-12.788-12.895zm.7-3.324a15.746 15.746 0 0 1 10.091 4.231 16.211 16.211 0 0 1 5.2 9.772A16.351 16.351 0 0 1 37 23.087a15.491 15.491 0 0 1 10-4.231 36.237 36.237 0 0 0-14.187-3.022 38.507 38.507 0 0 0-16.19 2.921z"
														fill="#00af87"
													></path>
													<path
														d="M47.8 26.814a7.963 7.963 0 0 1 .3 15.917 7.962 7.962 0 1 1-.3-15.917zm5.2 7.959a5.146 5.146 0 1 0-10.291-.1 5.245 5.245 0 0 0 5.2 5.239 5.012 5.012 0 0 0 5.082-5.139zm-37.176-7.959a7.966 7.966 0 0 1 .4 15.917 7.971 7.971 0 0 1-8.293-7.959 7.756 7.756 0 0 1 7.893-7.958zm5.1 7.959a5.036 5.036 0 0 0-5.2-5.138 5.24 5.24 0 0 0 .1 10.477 5.316 5.316 0 0 0 5.095-5.339z"
														fill="#00af87"
													></path>
													<path
														d="M47.8 32.153a2.591 2.591 0 0 1 2.6 2.619 2.656 2.656 0 0 1-2.6 2.619 2.591 2.591 0 0 1-2.6-2.619 2.656 2.656 0 0 1 2.6-2.619z"
														fill="#00af87"
													></path>
													<path
														d="M15.724 32.153a2.677 2.677 0 0 1 2.7 2.619 2.763 2.763 0 0 1-2.7 2.72 2.67 2.67 0 1 1 0-5.339z"
														fill="#00af87"
													></path>
												</svg>
											)}
										</div>
										<div style={{ flexGrow: 1 }}>
											<a href={item.link}>
												{item.link.substring(0, 70)}
												{item.link.length > 70 ? '...' : ''}
											</a>
										</div>
										<div style={{ flexBasis: 40 }}>
											<Popover content="Remove link from redirect list">
												<Button
													danger
													icon={<DeleteOutlined />}
													onClick={() => {
														this.data!.links.splice(index, 1);
														this.saveLinks();
													}}
												/>
											</Popover>
										</div>
									</div>
								</List.Item>
							)}
						/>
					</div>
				</section>
			</div>
		);
	}
}

export default withNavigateParams(SettingsPage);
