import { PureComponent } from 'react';

import { observable } from 'mobx';
import { observer } from 'mobx-react';
import cn from 'classnames';

import withNavigateParams from '../../utils/withNavigateParams';
import { NavigateFunction } from 'react-router';
import chains from '../../stores/chains';

import { Sorting } from '../../web-types/Sorting';

import './style.scss';
import moment from 'moment';
import { TableController } from '../../controls/TableController';
import { autobind } from 'core-decorators';
import { manager_fetchChainOutgoingTxs } from '../../lib/api-web/qr-codes';

const statuses = {
	'created': 'Created',
	'auto-approved': 'Approved',
	'need-manual-approve': 'Processing',
	'manual-approved': 'Processing',
	'processing': 'Processing',
	'success': 'Sucessfully sent',
	'failed': 'Rejected',
	'rejected': 'Rejected',
};

@observer
class ChainOutgoingTxsPage extends PureComponent<{ navigate: NavigateFunction; params: any; state: any }> {
	@observable contentVisible = false;
	@observable total: number | null = null;
	@observable params: { query: string } = { query: '' };

	@observable employeeInviting = false;

	@autobind
	handleTablePropsUpdate(contentVisible: boolean, total: number | null, rowsPerPage: number) {
		this.contentVisible = contentVisible;
		this.total = total;
	}

	@autobind
	async handleLoad(params: { query: string }, offset: number, limit: number, sorting: Sorting | null) {
		return manager_fetchChainOutgoingTxs(this.props.params.chainId, offset, limit, {
			query: params.query,
			sorting,
		});
	}

	render() {
		return (
			<div key="two" className={cn('page', { hidden: !this.contentVisible })}>
				<div className="page-head">
					<div className="page-title">
						<h2>
							Payout requests of {chains.chain?.name} {this.total !== null ? `(${this.total})` : ``}
						</h2>
					</div>
				</div>
				<section className="section">
					<TableController
						noSection
						onRowClick={({ unique_id }) => {
							// QRInfo.show(unique_id);
						}}
						params={this.params}
						load={this.handleLoad}
						onTablePropsUpdate={this.handleTablePropsUpdate}
						columns={[
							{
								key: 'place.name',
								title: 'Place',
								sortable: true,
							},
							{
								key: 'created_at',
								title: 'Date',
								sortable: true,
								render: (value, row) => (value ? moment(value).format('DD.MM HH:mm') : 'Never'),
							},
							{
								key: 'amount',
								title: 'Amount',
								sortable: true,
								render: value => value + ' AED',
							},
							{
								key: 'status',
								title: 'Status',
								sortable: true,
								render: val => statuses[val as keyof typeof statuses],
							},
							// {
							// 	key: 'id',
							// 	title: 'Actions',
							// 	width: 90,
							// 	render: (id, { unique_id }) => (
							// 		<>
							// 			<Popover content="Reattach QR code">
							// 				<Button
							// 					type="text"
							// 					onClick={e => {
							// 						e.stopPropagation();
							// 						QRInfo.show(unique_id);
							// 					}}
							// 					icon={<EditOutlined />}
							// 				/>
							// 			</Popover>
							// 		</>
							// 	),
							// },
						]}
					/>
				</section>
			</div>
		);
	}
}

export default withNavigateParams(ChainOutgoingTxsPage);
