import { writerPost, frontmanGet } from '.';
import AdminPlaceData from '../../web-types/AdminPlaceData';
import CompactUserData from '../../web-types/CompactUserData';
import UserPlaceRole from '../../web-types/UserPlaceRole';
import NetworkResponse from '../responses/NetworkResponse';
import SignInResponse from '../responses/SignInResponse';

export function signIn(phone: string, code?: string): Promise<NetworkResponse<SignInResponse>> {
	return writerPost('POST', '/auth/sign-in', null, { phone, code });
}

export function demoSignIn(userId: number): Promise<NetworkResponse<SignInResponse>> {
	return writerPost('POST', '/demo/sign-in', null, { userId });
}

export function signOut(): Promise<NetworkResponse<undefined>> {
	return frontmanGet('/auth/sign-out');
}

export function fetchDemoAccounts(): Promise<NetworkResponse<CompactUserData[]>> {
	return frontmanGet('/demo/accounts');
}

export function fetchNotifications() {
	return frontmanGet('/notifications');
}

export function getMe(): Promise<NetworkResponse<CompactUserData | null>> {
	return frontmanGet('/auth/me');
}

export function signUp(
	inviteCode: string,
	data: { firstName: string; lastName: string; legalData: any },
): Promise<NetworkResponse<string>> {
	return writerPost('POST', `/invite/${inviteCode}/sign-up`, null, data);
}

export function fetchInvite(inviteCode: string): Promise<
	NetworkResponse<{
		result: 'accept-invite' | 'login-and-accept-invite' | 'new-user';
		phone: string;
		role: UserPlaceRole;
		place: AdminPlaceData;
	}>
> {
	return frontmanGet(`/invite/${inviteCode}/`);
}
