import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { PureComponent } from 'react';

import './style.scss';
import withNavigateParams from '../../utils/withNavigateParams';
import { NavigateFunction, Outlet } from 'react-router';
import { Sorting } from '../../web-types/Sorting';
import { admin_createPlace, admin_fetchPlaces } from '../../lib/api-web';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import PromptModal from '../../global-modals/PromptModal';
import handleError, { handleResponseError } from '../../utils/handleError';
import { TableController } from '../../controls/TableController';
import { autobind } from 'core-decorators';
import formatMoney from '../../utils/formatMoney';

@observer
class ChainPlacesPage extends PureComponent<{ navigate: NavigateFunction; params: any; state: any }> {
	@observable loading = false;
	@observable placeAdding = false;
	@observable contentVisible = false;
	@observable total: number | null = null;

	@autobind
	handleTablePropsUpdate(contentVisible: boolean, total: number | null, rowsPerPage: number) {
		this.contentVisible = contentVisible;
		this.total = total;
	}

	@autobind
	async handleLoad(params: { query: string }, offset: number, limit: number, sorting: Sorting | null) {
		return admin_fetchPlaces(offset, limit, {
			query: params.query,
			sorting,
			chainId: this.props.params.chainId,
		});
	}

	async handleAddPlace() {
		const placeName = await PromptModal.prompt('Enter place name', '');
		if (placeName) {
			this.placeAdding = true;
			try {
				const response = await admin_createPlace(placeName, this.props.params.chainId);
				if (response.result) {
					this.props.navigate(`/chain/${this.props.params.chainId}/places/${response.data}`, {
						state: { backable: true },
					});
				} else {
					handleResponseError(response);
				}
			} catch (err) {
				handleError(err);
			}
			this.placeAdding = false;
		}
	}

	render() {
		if (this.props.params.placeId) {
			return <Outlet />;
		}
		return (
			<div className="page">
				<div className="page-head">
					<div className="page-title">
						<h2>Places</h2>
					</div>
					<div className="page-actions">
						<Button
							type="primary"
							icon={<PlusOutlined />}
							onClick={e => {
								e.preventDefault();
								this.handleAddPlace();
							}}
						>
							Add place
						</Button>
					</div>
				</div>
				<TableController
					params={{ query: '' }}
					load={this.handleLoad}
					onRowClick={({ id }) =>
						this.props.navigate(`/chain/${this.props.params.chainId}/places/${id}/`, {
							state: { backable: true },
						})
					}
					columns={[
						{
							key: 'logoUrl',
							title: '',
							render: value => (
								<img src={value} alt="" style={{ width: 50, height: 50, borderRadius: '50%' }} />
							),
							width: 50,
						},
						{
							key: 'name',
							title: 'Title',
							sortable: true,
						},
						{
							key: 'employeesCount',
							title: 'Employees',
							sortable: true,
						},
						{
							key: 'tipsVolume7d',
							title: '7d tips volume',
							sortable: true,
						},
						{
							key: 'balance',
							title: 'Balance',
							sortable: true,
							render: value => formatMoney(value, 'AED'),
						},
					]}
				/>
			</div>
		);
	}
}

export default withNavigateParams(ChainPlacesPage);
