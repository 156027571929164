import NetworkResponse from '../lib/responses/NetworkResponse';
import i18n from '../stores/i18n';
import toaster from '../stores/toaster';

function getSection(section: string) {
	if (section === 'AUTH') {
		return i18n.errors.auth;
	} else {
		return {} as Record<string, string>;
	}
}

export function handleResponseError(response: NetworkResponse) {
	if (!response.result) {
		handleError(response.error, response.data);
	}
}

export default function handleError(code: any, data?: any) {
	if (typeof code === 'string') {
		const [section] = code.split(':');
		const sectionData = getSection(section);
		const text = sectionData[code];
		if (text) {
			toaster.show({
				type: 'error',
				message: text,
			});
		} else {
			toaster.show({
				type: 'error',
				message: `Unexpected error. ${code}`,
			});
		}
	} else {
		if (code && code instanceof Error) {
			toaster.show({
				type: 'error',
				message: `Unexpected error. ${code.message}`,
			});
		} else {
			toaster.show({
				type: 'error',
				message: `Unexpected error`,
			});
		}
	}
}
