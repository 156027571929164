import { observable } from 'mobx';

export type ModalRenderingFunction = (close: () => void) => JSX.Element;

class ModalsStore {
	@observable elements: Set<ModalRenderingFunction> = new Set();

	show(fn: ModalRenderingFunction) {
		this.elements.add(fn);
	}

	render() {
		return [...this.elements.values()].map(fn => fn(() => this.elements.delete(fn)));
	}
}

const modals = new ModalsStore();

export default modals;
