import { DisconnectOutlined, EditOutlined, HomeOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import Modal from 'antd/lib/modal/Modal';
import { autobind } from 'core-decorators';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { PureComponent } from 'react';
import {
	manager_fetchQRCode,
	manager_qrCodeAttachEmployee,
	manager_qrCodeAttachGroup,
	manager_qrCodeDetachEmployee,
	manager_qrCodeDetachGroup,
	manager_qrCodeDetachPlace,
	QRCodeRow,
} from '../../lib/api-web/qr-codes';
import modals from '../../stores/modals';
import MessageModal from '../MessageModal';
import SelectEmployeeModal from '../SelectEmployeeModal';
import SelectGroupModal from '../SelectGroupModal';

export interface QRInfoModalProps {
	unique_id: string;
	onDismiss: () => void;
}

@observer
export default class QRInfoModal extends PureComponent<QRInfoModalProps> {
	static async show(unique_id: string): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			modals.show((close: () => void) => (
				<QRInfoModal
					unique_id={unique_id}
					onDismiss={() => {
						close();
						resolve();
					}}
				/>
			));
		});
	}

	@observable data: QRCodeRow | null = null;

	@autobind
	async update() {
		const response = await manager_fetchQRCode(this.props.unique_id);
		if (response.result) {
			this.data = response.data;
		} else {
			await MessageModal.show('Error', `QR code was not found. You can view only QR codes of your company`);
			this.props.onDismiss();
		}
	}

	componentDidMount() {
		this.update();
	}

	@autobind
	async handleAttachGroup() {
		if (!this.data) {
			return;
		}
		const newGroupId = await SelectGroupModal.show(this.data.placeId!, this.data.groupId || undefined);
		if (newGroupId) {
			await manager_qrCodeAttachGroup(this.data.id, newGroupId);
			await this.update();
		}
	}

	@autobind
	async handleAttachEmployee() {
		if (!this.data) {
			return;
		}
		const newEmployeeId = await SelectEmployeeModal.show(this.data.placeId!, null, null, this.data.userId);
		if (newEmployeeId) {
			await manager_qrCodeAttachEmployee(this.data.id, newEmployeeId);
			await this.update();
		}
	}

	@autobind
	handleDetachEmployee() {
		if (!this.data) {
			return;
		}
		manager_qrCodeDetachEmployee(this.data.id).then(this.update);
	}

	@autobind
	handleDetachGroup() {
		if (!this.data) {
			return;
		}
		manager_qrCodeDetachGroup(this.data.id).then(this.update);
	}

	@autobind
	handleDetachPlace() {
		if (!this.data) {
			return;
		}
		manager_qrCodeDetachPlace(this.data.id).then(this.update);
	}

	render() {
		return (
			<Modal
				title={`QR Code "${this.props.unique_id}"`}
				onCancel={() => this.props.onDismiss()}
				visible={true}
				closable={false}
				onOk={() => {
					this.props.onDismiss();
				}}
			>
				{this.data ? (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'stretch',
							justifyContent: 'flex-start',
							width: '100%',
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'stretch',
								justifyContent: 'flex-start',
								marginBottom: 15,
							}}
						>
							<div style={{ marginBottom: 10, fontWeight: 'bold', fontSize: 18 }}>Place:</div>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								{this.data.place ? (
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'flex-end',
											marginBottom: 10,
										}}
									>
										<Avatar
											style={{ marginRight: 15 }}
											src={this.data.place.logoUrl}
											icon={<HomeOutlined />}
										/>
										<div>{this.data.place.name}</div>
									</div>
								) : (
									<>
										<i>No place attached</i>{' '}
										<div style={{ marginTop: 10 }}>
											<Button>Attach place</Button>
										</div>
									</>
								)}
							</div>
						</div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'stretch',
								justifyContent: 'flex-start',
								marginBottom: 15,
							}}
						>
							<div style={{ marginBottom: 10, fontWeight: 'bold', fontSize: 18 }}>User:</div>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								{this.data.user ? (
									<>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'flex-end',
												marginBottom: 10,
											}}
										>
											<Avatar
												style={{ marginRight: 15 }}
												src={this.data.user.avatarUrl}
												icon={<UserOutlined />}
											/>
											<div>
												{this.data.user.firstName} {this.data.user.lastName}
											</div>
										</div>
										<div style={{ display: 'flex', flexDirection: 'row' }}>
											<Button
												onClick={this.handleAttachEmployee}
												style={{ marginRight: 10 }}
												icon={<EditOutlined />}
											>
												Change user
											</Button>
											<Button
												onClick={this.handleDetachEmployee}
												danger
												icon={<DisconnectOutlined />}
											>
												Detach user
											</Button>
										</div>
									</>
								) : (
									<>
										<i>No user attached</i>{' '}
										<div style={{ marginTop: 10 }}>
											<Button
												onClick={this.handleAttachEmployee}
												icon={<PlusOutlined />}
												type="primary"
											>
												Attach user
											</Button>
										</div>
									</>
								)}
							</div>
						</div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'stretch',
								justifyContent: 'flex-start',
								marginBottom: 15,
								opacity: this.data.user ? 1 : 0.7,
								pointerEvents: this.data.user ? 'all' : 'none',
							}}
						>
							<div style={{ marginBottom: 10, fontWeight: 'bold', fontSize: 18 }}>Group:</div>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								{this.data.group ? (
									<>
										<div style={{ marginBottom: 15 }}>{this.data.group.group_name}</div>
										<div style={{ display: 'flex', flexDirection: 'row' }}>
											<Button onClick={this.handleAttachGroup} icon={<EditOutlined />}>
												Change group
											</Button>
											<Button
												onClick={this.handleDetachGroup}
												style={{ marginLeft: 10 }}
												danger
												icon={<DisconnectOutlined />}
											>
												Detach group
											</Button>
										</div>
									</>
								) : (
									<>
										<i>No group attached</i>{' '}
										<div style={{ marginTop: 10 }}>
											<Button
												onClick={this.handleAttachGroup}
												icon={<PlusOutlined />}
												type="primary"
											>
												Attach group
											</Button>
										</div>
									</>
								)}
							</div>
						</div>
					</div>
				) : (
					<Spin />
				)}
			</Modal>
		);
	}
}
