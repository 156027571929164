import { autobind } from 'core-decorators';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { PureComponent } from 'react';
import cn from 'classnames';

import logo from '../../assets/logo-new.svg';

import TipzButton from '../../controls/TipzButton';
import * as api from '../../lib/api';
import toaster from '../../stores/toaster';
import './style.scss';
import user from '../../stores/user';
import InternalRole from '../../web-types/InternalRole';
import CompactUserData from '../../web-types/CompactUserData';
import { Form, Input, Select } from 'antd';
import handleError, { handleResponseError } from '../../utils/handleError';
import getSearchParams from '../../utils/getSearchParams';
import Avatar from 'antd/lib/avatar/avatar';
import i18n from '../../stores/i18n';

@observer
export default class LoginPage extends PureComponent {
	@observable phone = '';
	@observable code = '';
	@observable isCodeSent = false;

	@observable userData!: CompactUserData;

	@observable loading = false;
	@observable accepted = false;
	@observable acceptedEnd = false;
	@observable expanding = false;

	@observable demoAccounts: CompactUserData[] = [];
	@observable timeLeft: number = 0;

	leftInterval: any = null;

	@autobind
	onResize() {
		if (this.avatarRef && this.acceptedEnd && !this.expanding) {
			this.avatarPosX = this.avatarRef.getBoundingClientRect().left;
			this.avatarPosY = this.avatarRef.getBoundingClientRect().top;
		}
	}

	async componentDidMount() {
		if (process.env.REACT_APP_TIPZ_ENV !== 'prod') {
			this.demoAccounts = (await api.fetchDemoAccounts()).data;
		}
		const sp = getSearchParams();
		if (sp.phone && typeof sp.phone === 'string') {
			if (sp.phone.startsWith('+7')) {
				this.countryCode = '+7';
				this.phone = sp.phone.substr(2);
			} else if (sp.phone.startsWith('+995')) {
				this.countryCode = '+995';
				this.phone = sp.phone.substr(2);
			} else if (sp.phone.startsWith('+971')) {
				this.countryCode = '+971';
				this.phone = sp.phone.substr(4);
			}
		}

		window.addEventListener('resize', this.onResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.onResize);
	}

	startAnimation() {
		this.accepted = true;
		setTimeout(() => {
			this.acceptedEnd = true;

			setTimeout(() => {
				this.expanding = true;
				this.avatarPosX = window.innerWidth - 77;
				this.avatarPosY = 12;

				setTimeout(() => {
					this.finishAnimation();
				}, 500);
			}, 600);
		}, 800);
	}

	finishAnimation() {
		user.user = this.userData;
		user.save();
	}

	@autobind
	async onLogin(resend: boolean = false) {
		this.loading = true;
		try {
			if (!this.isCodeSent || resend) {
				// const result = await api.signIn(this.countryCode + this.phone, '');
				// if (result.result) {
				// 	if (result.data.result === 'sent') {
				// 		this.isCodeSent = true;
				// 		this.timeLeft = 30;
				// 		if (this.leftInterval) {
				// 			clearInterval(this.leftInterval);
				// 		}
				// 		this.leftInterval = setInterval(() => {
				// 			this.timeLeft -= 1;
				// 			if (this.timeLeft === 0) {
				// 				clearInterval(this.leftInterval);
				// 				this.leftInterval = null;
				// 			}
				// 		}, 1000);
				// 	} else {
				// 		handleError('AUTH:UNKNOWN_CODE_STATE');
				// 	}
				// } else {
				// 	handleResponseError(result);
				// }
				this.isCodeSent = true;
				this.loading = false;
			} else {
				const result = await api.signIn(this.countryCode + this.phone, this.code);
				if (result.result) {
					console.log('result.data: ', result.data);
					if (result.data.result === 'accepted') {
						this.userData = result.data.user;
						this.startAnimation();
					} else {
						handleError('AUTH:UNKNOWN_CODE_STATE');
					}
				} else {
					handleResponseError(result);
				}
				this.loading = false;
			}
		} catch (err) {
			this.loading = false;
			handleError(err);
		}
	}

	@observable countryCode: string = document.location.host.endsWith('.ae') ? '+971' : '+995';

	isValidNumber(phone: string) {
		const clean = phone
			.replaceAll('+', '')
			.replaceAll('-', '')
			.replaceAll(' ', '')
			.replaceAll('(', '')
			.replaceAll(')', '');
		if (this.countryCode === '+7' && clean.length !== 10) {
			return false;
		}
		if (this.countryCode.length + clean.length < 9) {
			return false;
		}
		return true;
	}

	@observable avatarPosX: number = 0;
	@observable avatarPosY: number = 0;

	avatarRef: HTMLDivElement | null = null;

	@autobind
	gotAvatarRef(el: HTMLDivElement | null) {
		this.avatarRef = el;
		if (el && !this.expanding) {
			this.avatarPosX = el.getBoundingClientRect().left;
			this.avatarPosY = el.getBoundingClientRect().top;
		}
	}

	render() {
		const selectBefore = (
			<Select value={this.countryCode} className="select-before" onChange={e => (this.countryCode = e)}>
				<Select.Option value="+971">+971</Select.Option>
				<Select.Option value="+995">+995</Select.Option>
				<Select.Option value="+381">+381</Select.Option>
				<Select.Option value="+7">+7</Select.Option>
			</Select>
		);

		return (
			<div className="fullpage login-page">
				{process.env.REACT_APP_TIPZ_ENV !== 'prod' ? (
					<div
						className="demo-helper"
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'stretch',
							padding: 10,
							position: 'absolute',
							left: 0,
							height: 400,
							top: '50%',
							marginTop: -200,
							width: 250,
							backgroundColor: 'white',
							borderTopRightRadius: 20,
							borderBottomRightRadius: 20,
						}}
					>
						<div>
							<h3 style={{ fontSize: 26, margin: 0, marginBottom: 3, textAlign: 'center' }}>
								Demo accounts
							</h3>
							<h4 style={{ fontSize: 18, margin: 0, marginBottom: 10, textAlign: 'center' }}>
								Click to log in
							</h4>
						</div>
						<div
							style={{
								overflowY: 'scroll',
								flexGrow: 1,
							}}
						>
							{this.demoAccounts.map(a => (
								<div
									key={a.id}
									className="demo-acc-plate"
									onClick={async () => {
										const result = await api.demoSignIn(a.id);
										if (result.result) {
											if (result.data.result === 'accepted') {
												user.user = result.data.user;
												user.save();
											} else {
												toaster.show({
													message: 'GENERIC_FAIL',
													type: 'error',
												});
											}
										} else {
											toaster.show({
												message: result.error,
												type: 'error',
											});
										}
									}}
								>
									<img
										src={a.avatarUrl || ''}
										alt=""
										style={{
											width: 40,
											height: 40,
											borderRadius: '50%',
											marginRight: 10,
											flexGrow: 0,
										}}
									/>
									<div
										style={{
											display: 'flex',
											flexGrow: 1,
											flexDirection: 'column',
											alignItems: 'stretch',
										}}
									>
										<div style={{ fontSize: 16 }}>
											{a.firstName} {a.lastName}
										</div>
										<div style={{ fontSize: 13, whiteSpace: 'nowrap' }}>
											{a.role === InternalRole.ADMIN
												? 'System admin'
												: a.places.map(p => p.name + ' / ' + p.text_role).join(', ')}
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				) : null}
				<div
					className={cn('login-form', {
						expanding: this.expanding,
						done: this.accepted,
						open: this.isCodeSent,
					})}
				>
					<div className="login-form-inner">
						<div className="login-front">
							<img src={logo} className="login-logo" alt="" />
							<div className="l-subtitle">
								{this.isCodeSent ? i18n.m.ENTER_YOUR_PASSWORD : i18n.m.ENTER_PHONE_TO_LOGIN}
							</div>
							<div className="login-form-content">
								<Form.Item
									validateStatus={
										this.phone ? (this.isValidNumber(this.phone) ? 'success' : 'error') : 'success'
									}
									help={
										this.phone
											? this.isValidNumber(this.phone)
												? undefined
												: i18n.m.INVALID_PHONE_NUMBER
											: i18n.m.PHONE_NUMBER_REQUIRED
									}
								>
									<div>
										<Input
											addonBefore={selectBefore}
											id="phone-input"
											disabled={this.isCodeSent}
											placeholder={i18n.m.ENTER_YOUR_PHONE_NUMBER}
											value={this.phone}
											onChange={e => (this.phone = e ? String(e.target.value) : '')}
											onKeyPress={e => {
												if (e.key === 'Enter') {
													e.preventDefault();
													this.onLogin();
												}
											}}
										/>
									</div>
								</Form.Item>
								{this.isCodeSent ? (
									<Form.Item
										label="Password"
										// help={
										// 	<div>
										// 		Didn't get the code?{' '}
										// 		{this.timeLeft ? (
										// 			<span>
										// 				Resend
										// 				{this.timeLeft ? ` in ${formatTime(this.timeLeft)}` : ''}
										// 			</span>
										// 		) : (
										// 			<a
										// 				href="#resend"
										// 				onClick={e => {
										// 					e.preventDefault();
										// 					this.onLogin(true);
										// 				}}
										// 			>
										// 				Resend
										// 			</a>
										// 		)}
										// 	</div>
										// }
									>
										<Input
											type="password"
											id="code-input"
											placeholder="Password"
											onChange={e => (this.code = e.target.value)}
											value={this.code}
										/>
									</Form.Item>
								) : null}
							</div>
							<TipzButton loading={this.loading} onClick={() => this.onLogin()}>
								{this.isCodeSent ? i18n.m.LOGIN : i18n.m.ENTER_PASSWORD}
							</TipzButton>
						</div>
						<div className="login-back">
							{this.accepted && (
								<>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											marginBottom: 30,
											marginTop: 10,
										}}
									>
										<div ref={this.gotAvatarRef} style={{ width: 146, height: 146 }}>
											{!this.acceptedEnd && (
												<div
													style={{
														width: 146,
														height: 146,
														border: '4px solid #C1E5FF',
														borderRadius: '50%',
													}}
												>
													<div
														style={{
															width: 138,
															height: 138,
															border: '4px solid #5EA8FF',
															borderRadius: '50%',
														}}
													>
														<Avatar size={130} src={this.userData.avatarUrl} />
													</div>
												</div>
											)}
										</div>
									</div>
									<h3>{i18n.m.WELCOME_BACK}</h3>
									<br />
									<h2>{this.userData.firstName + ' ' + this.userData.lastName}</h2>
									<h3>-</h3>
								</>
							)}
						</div>
					</div>
				</div>
				<div
					className={cn('flying-avatar', { expanding: this.expanding })}
					style={{
						border: '4px solid #C1E5FF',
						borderRadius: '50%',
						display: this.acceptedEnd ? 'block' : 'none',
						position: 'fixed',
						zIndex: 100,
						left: this.avatarPosX,
						top: this.avatarPosY,
					}}
				>
					<div style={{ border: '4px solid #5EA8FF', borderRadius: '50%' }}>
						{this.userData ? <Avatar size={130} src={this.userData.avatarUrl} /> : null}
					</div>
				</div>
			</div>
		);
	}
}
