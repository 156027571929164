import { HTMLAttributes, useEffect, useState } from 'react';
import QRCode from 'qrcode';

export default function QRCodeImg({ data, ...rest }: Omit<HTMLAttributes<HTMLImageElement>, 'src'> & { data: string }) {
	const [url, setUrl] = useState('');

	useEffect(() => {
		let unmounted = false;
		QRCode.toDataURL(data, (err, url) => {
			if (!unmounted) {
				setUrl(url);
			}
		});
		return () => {
			unmounted = true;
		};
	}, [data]);

	return <img src={url} alt="" {...rest} />;
}
