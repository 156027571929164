const mainLabelsEn = {
	//
	OK: 'OK',
	CANCEL: 'Cancel',
	//
	EDIT_PROFILE: 'Edit profile',
	SELECT_PLACE: 'Select place',
	PROFILE: 'Profile',
	LOGOUT: 'Logout',
	//
	MENU_DASHBOARD: 'Dashboard',
	MENU_EMPLOYEES: 'Employees',
	MENU_GROUPS: 'Groups',
	MENU_TIPS: 'Tips & reviews',
	MENU_QR_CODES: 'QR codes',
	MENU_PAYOUTS: 'Payouts',
	MENU_SETTINGS: 'Settings',
	MENU_PLACES: 'Places',
	MENU_MANAGERS: 'Managers',
	MENU_PAYOUT_REQUESTS: 'Payout requests',
	//
	DASHBOARD_TITLE: 'Activity of your business',
	DASHBOARD_WITHDRAWAL_MINIMUM: 'You can withdraw only more than 100 AED at once',
	WITHDRAWAL_BUTTON: 'Withdraw',
	BALANCE: 'Balance',
	//
	AVG_RATING_30D: 'Average rating for 30 days',
	TIPS_VOL_7D: 'Total tips volume for 7 days',
	REVIEWS_COUNT_7D: 'Reviews count for 7 days',
	DASHBOARD_STATISTICS: 'Detailed statistics',
	DASHBOARD_BEST_EMPLOYEE_MONTH: 'Employee of the month',
	//
	WITHDRAWAL_MODAL_TITLE: 'Withdrawal request',
	WITHDRAWAL_MODAL_HELP: 'Minimal amount: 100 AED',
	ENTER_AMOUNT: 'Enter amount...',
	ENTER_BANK_DETAILS: 'Enter bank account details',
	//
	MY_PROFILE: 'My profile',
	UPLOAD_NEW_AVATAR: 'Upload avatar',
	EDIT_NAME: 'Edit name',
	EDIT_PHONE: 'Edit phone',
	EDIT_PASSWORD: 'Edit password',
	PROFILE_HELPER_TEXT: 'Please, use buttons on the left to edit your profile details. Thank you!',
	ENTER_NEW_PASSWORD: 'Enter new password',
	ENTER_NEW_PHONE: 'Enter new phone',
	ENTER_NEW_FIRST_NAME: 'Enter new first name',
	ENTER_NEW_LAST_NAME: 'Enter new last name',
	//
	EMPLOYEES_OF: 'Employees of',
	SEARCH_EMPLOYEE: 'Search employee...',
	ADD: 'Add',
	NAME: 'Name',
	ROLE: 'Role',
	RATING_7D: `⭐ 7 days`,
	RATING_30D: `⭐ 30 days`,
	RATING_MONTH: `⭐ Month`,
	RATING_WEEK: `⭐ Week`,
	ACTIONS: 'Actions',
	ROLE_Employee: 'Employee',
	ROLE_Manager: 'Manager',
	ROLE_Admin: 'Admin',
	ROLE_Owner: 'Owner',
	//
	ENTER_PHONE_TO_LOGIN: 'Please, enter your phone number to log in',
	ENTER_YOUR_PHONE_NUMBER: 'Enter your phone number',
	INVALID_PHONE_NUMBER: 'Invalid phone number',
	PHONE_NUMBER_REQUIRED: 'Phone number is required',
	ENTER_YOUR_PASSWORD: 'Please, enter your password',
	ENTER_PASSWORD: 'Enter password',
	LOGIN: 'Login',
	WELCOME_BACK: 'Welcome back!',
};

export default mainLabelsEn;
