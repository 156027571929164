import Modal from 'antd/lib/modal/Modal';
import { observer } from 'mobx-react';
import { PureComponent } from 'react';
import modals from '../../stores/modals';

export interface MessageModalProps {
	title: string;
	text: string;
	okButton: string;
	onConfirm: () => void;
}

@observer
export default class MessageModal extends PureComponent<MessageModalProps> {
	static async show(title: string, text: string, okButton: string = 'Confirm'): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			modals.show((close: () => void) => (
				<MessageModal
					title={title}
					text={text}
					okButton={okButton}
					onConfirm={() => {
						close();
						resolve();
					}}
				/>
			));
		});
	}

	render() {
		return (
			<Modal
				okText={this.props.okButton}
				visible={true}
				closable={false}
				title={this.props.title}
				onOk={() => {
					this.props.onConfirm();
				}}
			>
				<div style={{ width: '100%' }}>{this.props.text}</div>
			</Modal>
		);
	}
}
