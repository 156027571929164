import React, { PureComponent } from 'react';

import { observable } from 'mobx';
import { observer } from 'mobx-react';
import cn from 'classnames';

import withNavigateParams from '../../utils/withNavigateParams';
import { NavigateFunction, Outlet } from 'react-router';
import places from '../../stores/places';

import { Sorting } from '../../web-types/Sorting';

import './style.scss';
import { TableController } from '../../controls/TableController';
import { autobind } from 'core-decorators';
import { Button, Input, Popover } from 'antd';
import { manager_fetchGroups, manager_groupAdd } from '../../lib/api-web/groups';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import formatMoney from '../../utils/formatMoney';
import PromptModal from '../../global-modals/PromptModal';
import handleError from '../../utils/handleError';

@observer
class GroupsPage extends PureComponent<{ navigate: NavigateFunction; params: any; state: any }> {
	@observable contentVisible = false;
	@observable total: number | null = null;
	@observable params: { query: string } = { query: '' };

	@observable groupCreating = false;

	controller = React.createRef<TableController<{ query: string }, { id: number }>>();

	@autobind
	handleTablePropsUpdate(contentVisible: boolean, total: number | null, rowsPerPage: number) {
		this.contentVisible = contentVisible;
		this.total = total;
	}

	@autobind
	async handleLoad(params: { query: string }, offset: number, limit: number, sorting: Sorting | null) {
		return manager_fetchGroups(this.props.params.placeId, offset, limit, {
			query: params.query,
			sorting,
		});
	}

	async handleAddGroup() {
		const newName = await PromptModal.prompt('Enter new group name', '');
		if (newName) {
			this.groupCreating = true;
			try {
				await manager_groupAdd(Number(this.props.params.placeId), newName);
				this.controller.current?.load();
			} catch (err) {
				handleError(err);
			}
			this.groupCreating = false;
		}
	}

	render() {
		if (this.props.params.groupId) {
			return <Outlet />;
		}
		return (
			<div key="two" className={cn('page', { hidden: !this.contentVisible })}>
				<div className="page-head">
					<div className="page-title">
						<h2>
							Groups of {places.place?.name} {this.total !== null ? `(${this.total})` : ``}
						</h2>
					</div>
					<div className="page-actions">
						<Input.Search
							style={{ marginRight: 15 }}
							type="search"
							onSearch={value => (this.params.query = value)}
							placeholder="Search groups..."
						/>
						<Button
							type="primary"
							icon={<PlusOutlined />}
							onClick={e => {
								e.preventDefault();
								this.handleAddGroup();
							}}
						>
							Add
						</Button>
					</div>
				</div>
				<section className="section">
					<TableController
						ref={this.controller}
						noSection
						onRowClick={({ id }) => {
							this.props.navigate(`/${this.props.params.placeId}/groups/${id}/`, {
								state: { backable: true },
							});
						}}
						params={this.params}
						load={this.handleLoad}
						onTablePropsUpdate={this.handleTablePropsUpdate}
						columns={[
							{
								key: 'group_name',
								title: 'Name',
								sortable: true,
							},
							{
								key: 'userRelations.length',
								title: 'Participans',
								sortable: true,
							},
							// {
							// 	key: 'is_uniform',
							// 	title: 'Distribution',
							// 	sortable: true,
							// 	render: (value, row) => <>{row.is_uniform ? 'Uniform' : 'Custom'}</>,
							// },
							{
								key: 'tips_30d',
								title: 'Tips in 30d',
								minWidth: 100,
								sortable: true,
								render: (value, row) => formatMoney(value, 'AED'),
							},
							{
								key: 'id',
								title: 'Actions',
								width: 60,
								render: id => (
									<>
										<Popover content="Edit group">
											<Button type="text" icon={<EditOutlined />} />
										</Popover>
									</>
								),
							},
						]}
					/>
				</section>
			</div>
		);
	}
}

export default withNavigateParams(GroupsPage);
