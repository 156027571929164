import { PureComponent } from 'react';
import { observer } from 'mobx-react';

import './style.scss';

import withNavigateParams from '../../utils/withNavigateParams';
import { NavigateFunction } from 'react-router';
import moment from 'moment';
import { observable } from 'mobx';
import { admin_fetchPlacesStatistics, PlaceStatistics } from '../../lib/api-web/stat';
import { Avatar, Button, Tooltip } from 'antd';
import { ClusterOutlined } from '@ant-design/icons';

@observer
class AdminDashboardPage extends PureComponent<{ navigate: NavigateFunction; params: any; state: any }> {
	@observable loading = false;
	@observable places: PlaceStatistics[] = [];

	async reload() {
		this.loading = true;
		try {
			const data = await admin_fetchPlacesStatistics({});
			if (data.result) {
				this.places = data.data;
			}
		} finally {
			this.loading = false;
		}
	}

	componentDidMount() {
		this.reload();
	}

	getDayColor(place: PlaceStatistics, day: PlaceStatistics['days'][0]) {
		if (day.pageViews < 10) {
			return 'rgba(255, 0, 0, 0.08)';
		} else if (day.pageViews < 20) {
			return 'rgba(255, 187, 0, 0.08)';
		} else {
			return 'rgba(94, 255, 0, 0.08)';
		}
	}

	getPageViewsColor(place: PlaceStatistics, day: PlaceStatistics['days'][0], val: number) {
		if (val < 10) {
			return 'rgb(141, 0, 0)';
		} else if (val < 20) {
			return 'rgb(194, 132, 0)';
		} else {
			return 'rgb(49, 134, 0)';
		}
	}

	getReviewsColor(place: PlaceStatistics, day: PlaceStatistics['days'][0], val: number) {
		if (val < 3) {
			return 'rgb(141, 0, 0)';
		} else if (val < 7) {
			return 'rgb(194, 132, 0)';
		} else {
			return 'rgb(49, 134, 0)';
		}
	}

	getTipsCountColor(place: PlaceStatistics, day: PlaceStatistics['days'][0], val: number) {
		if (val < 2) {
			return 'rgb(141, 0, 0)';
		} else if (val < 5) {
			return 'rgb(194, 132, 0)';
		} else {
			return 'rgb(49, 134, 0)';
		}
	}

	getTipsVolumeColor(place: PlaceStatistics, day: PlaceStatistics['days'][0], val: number) {
		if (val < 25) {
			return 'rgb(141, 0, 0)';
		} else if (val < 45) {
			return 'rgb(194, 132, 0)';
		} else {
			return 'rgb(49, 134, 0)';
		}
	}

	render() {
		const last7Days = [...new Array(7)].map((_, idx) => moment.utc().endOf('day').subtract(idx, 'day'));
		return (
			<div className="page">
				<div className="page-head">
					<div className="page-title">
						<h2>Places activity for this week</h2>
					</div>
					<div className="page-actions"></div>
				</div>
				<div style={{ width: '100%', background: 'white', borderRadius: 10, padding: 10 }}>
					<table style={{ width: '100%' }}>
						<thead>
							<tr>
								<td></td>
								{last7Days.map(day => (
									<td key={day.unix()} style={{ textAlign: 'center', padding: 10 }}>
										{day.format('DD.MM')}
									</td>
								))}
							</tr>
						</thead>
						<tbody>
							{this.places.map(place => (
								<tr key={place.placeId}>
									<td>
										<Button
											type="link"
											icon={
												<Avatar
													style={{ marginRight: 10 }}
													src={place.logoUrl}
													icon={<ClusterOutlined />}
												/>
											}
											href={`/admin-places/${place.placeId}/`}
											onClick={e => {
												e.preventDefault();
												this.props.navigate(`/admin-places/${place.placeId}/`);
											}}
										>
											{place.name}
											{/* <Button
												type="link"
												href={`/admin-employees/?byPlace=${place.placeId}`}
												onClick={e => {
													e.preventDefault();
													this.props.navigate(`/admin-employees/?byPlace=${place.placeId}`);
												}}
											>
												({place.employeeCount})
											</Button> */}
										</Button>
									</td>
									{last7Days.map((_, idx) => (
										<td key={idx} style={{ padding: 3 }}>
											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
													alignItems: 'stretch',
													justifyContent: 'flex-start',
													background: this.getDayColor(place, place.days[idx]),
												}}
											>
												<div
													style={{
														display: 'flex',
														flexDirection: 'row',
														alignItems: 'center',
														justifyContent: 'space-around',
													}}
												>
													<div
														style={{
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															fontSize: 11,
														}}
													>
														PV:{' '}
														<Tooltip overlay="Page Views: how many times people scan QR code. Low values means that staff don't give QR codes to the guests">
															<span
																style={{
																	fontSize: 20,
																	marginLeft: 5,
																	fontWeight: 'bold',
																	color: this.getPageViewsColor(
																		place,
																		place.days[idx],
																		place.days[idx].pageViews,
																	),
																}}
															>
																{Number(Number(place.days[idx].pageViews).toFixed(2))}
															</span>
														</Tooltip>
													</div>
													<div
														style={{
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															fontSize: 11,
														}}
													>
														R:{' '}
														<Tooltip overlay="Reviews: how many people left a review for the venue. This number includes reviews with tips">
															<span
																style={{
																	fontSize: 20,
																	marginLeft: 5,
																	fontWeight: 'bold',
																	color: this.getReviewsColor(
																		place,
																		place.days[idx],
																		place.days[idx].reviews,
																	),
																}}
															>
																{Number(Number(place.days[idx].reviews).toFixed(2))}
															</span>
														</Tooltip>
													</div>
												</div>
												<div
													style={{
														display: 'flex',
														flexDirection: 'row',
														alignItems: 'center',
														justifyContent: 'space-around',
														marginTop: 4,
													}}
												>
													<div
														style={{
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															fontSize: 11,
														}}
													>
														TC:{' '}
														<Tooltip overlay="Tips Count: How many times someone left a tip">
															<span
																style={{
																	fontSize: 20,
																	marginLeft: 5,
																	fontWeight: 'bold',
																	color: this.getTipsCountColor(
																		place,
																		place.days[idx],
																		place.days[idx].tipsCount,
																	),
																}}
															>
																{Number(Number(place.days[idx].tipsCount).toFixed(2))}
															</span>
														</Tooltip>
													</div>
													<div
														style={{
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															fontSize: 11,
														}}
													>
														TV:{' '}
														<Tooltip overlay="Tips Volume: how much people left in tips">
															<span
																style={{
																	fontSize: 20,
																	marginLeft: 5,
																	marginRight: 4,
																	fontWeight: 'bold',
																	color: this.getTipsVolumeColor(
																		place,
																		place.days[idx],
																		place.days[idx].tipsVolume,
																	),
																}}
															>
																{Number(Number(place.days[idx].tipsVolume).toFixed(2))}
															</span>
														</Tooltip>
													</div>
												</div>
											</div>
										</td>
									))}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}

export default withNavigateParams(AdminDashboardPage);
