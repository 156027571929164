import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { NavigateFunction, useNavigate } from 'react-router';

export interface BackButtonProps {
	props: {
		navigate: NavigateFunction;
		params: { placeId?: string; chainId?: string };
		state: { backable: boolean } | null;
	};
}

export default function BackButton({ props }: BackButtonProps) {
	const navigate = useNavigate();
	if (!props.state?.backable) {
		return null;
	}
	const isMobile = window.outerWidth < 600;
	if (isMobile) {
		return null;
	}
	return (
		<Button
			style={{ marginRight: 12 }}
			type="default"
			icon={
				<ArrowLeftOutlined
					size={10}
					onClick={e => {
						e.preventDefault();
						navigate(-1);
					}}
				/>
			}
		/>
	);
}
