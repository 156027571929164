import { observer } from 'mobx-react';

import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import LoginPage from './pages/Login';
import notifications from './stores/notifications';
import places from './stores/places';
import Dashboard from './pages/Dashboard';
import MainLayout from './layouts/Main';
import ChainCatcher from './controls/ChainCatcher';
import PlaceCatcher from './controls/PlaceCatcher';
import Employees from './pages/Employees';
import user from './stores/user';
import InternalRole from './web-types/InternalRole';
import AdminPlaces from './admin-pages/AdminPlaces';
import AdminChains from './admin-pages/AdminChains';
import AdminChainCard from './admin-pages/AdminChains/AdminChainCard';
import AdminEmployeeCard from './admin-pages/AdminEmployees/AdminEmployeeCard';
import AdminEmployees from './admin-pages/AdminEmployees';
import AdminQrCodes from './admin-pages/AdminQrCodes';
import AdminIncomingTxs from './admin-pages/AdminIncomingTxs';
import AdminInternalTxs from './admin-pages/AdminInternalTxs';
import AdminOutgoingTxs from './admin-pages/AdminOutgoingTxs';
import AdminActionLogs from './admin-pages/AdminActionLogs';
import AdminDashboard from './admin-pages/AdminDashboard';
import AdminPlaceCard from './admin-pages/AdminPlaces/AdminPlaceCard';
import { Spin } from 'antd';
import { Chart, registerables } from 'chart.js';
import InvitePage from './pages/InvitePage';
import Groups from './pages/Groups';
import TipsAndReviews from './pages/TipsAndReviews';
import EmployeeCard from './pages/Employees/EmployeeCard';
import GroupCard from './pages/Groups/GroupCard';
import Settings from './pages/Settings';
import QRCodes from './pages/QRCodes';
import OutgoingTxs from './pages/OutgoingTxs';
import EmpsOutgoingTxsPage from './pages/EmpsOutgoingTxs';
import ProfilePage from './pages/Profile';
import PlaceProfile from './pages/PlaceProfile';
import ChainDashboard from './chain-pages/ChainDashboard';
import ChainManagers from './chain-pages/ChainManagers';
import ChainManagerCard from './chain-pages/ChainManagers/ChainManagerCard';
import ChainEmployees from './chain-pages/ChainEmployees';
import ChainEmployeeCard from './chain-pages/ChainEmployees/ChainEmployeeCard';
import ChainProfile from './chain-pages/ChainProfile';
import ChainQRCodes from './chain-pages/ChainQRCodes';
import ChainOutgoingTxs from './chain-pages/ChainOutgoingTxs';
import ChainTipsAndReviews from './chain-pages/ChainTipsAndReviews';
import ChainPlaces from './chain-pages/ChainPlaces';
import ChainPlaceCard from './chain-pages/ChainPlaces/ChainPlaceCard';

Chart.register(...registerables);

//@ts-ignore
window.stores = {
	user,
	notifications,
	places,
};

export default observer(function App() {
	const location = useLocation();

	if (user.loading) {
		return (
			<div className="fullpage fullpage-center">
				<Spin />
			</div>
		);
	}

	if (!user.isAuthorized && location.pathname !== '/login' && !location.pathname.startsWith('/invite/')) {
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	if (user.isAuthorized && location.pathname === '/login') {
		return <Navigate to="/" />;
	}

	if (user.isAuthorized && user.user?.role === InternalRole.ADMIN && location.pathname === '/') {
		return <Navigate to="/admin-dashboard/" />;
	}

	return (
		<Routes>
			<Route path="/">
				<Route path="*" element={<MainLayout />}>
					<Route index element={<PlaceCatcher />} />
					<Route path="profile" element={<ProfilePage />} />
					<Route path="admin-dashboard/" element={<AdminDashboard />} />
					<Route path="admin-places/" element={<AdminPlaces />}>
						<Route path=":placeId/" element={<AdminPlaceCard />} />
					</Route>
					<Route path="admin-chains/" element={<AdminChains />}>
						<Route path=":chainId/" element={<AdminChainCard />} />
					</Route>
					<Route path="admin-employees/" element={<AdminEmployees />}>
						<Route path=":employeeId/" element={<AdminEmployeeCard />} />
					</Route>
					<Route path="admin-qr-codes/" element={<AdminQrCodes />} />
					<Route path="admin-incoming-txs/" element={<AdminIncomingTxs />} />
					<Route path="admin-internal-txs/" element={<AdminInternalTxs />} />
					<Route path="admin-outgoing-txs/" element={<AdminOutgoingTxs />} />
					<Route path="admin-action-logs/" element={<AdminActionLogs />} />
					<Route path="chain/:chainId/" element={<ChainCatcher />}>
						<Route index element={<ChainDashboard />} />
						<Route path="profile" element={<ChainProfile />} />
						<Route path="places" element={<ChainPlaces />}>
							<Route path=":placeId/" element={<ChainPlaceCard />} />
						</Route>
						<Route path="employees" element={<ChainEmployees />}>
							<Route path=":employeeId/" element={<ChainEmployeeCard />} />
						</Route>
						<Route path="managers" element={<ChainManagers />}>
							<Route path=":employeeId/" element={<ChainManagerCard />} />
						</Route>
						<Route path="qr-codes" element={<ChainQRCodes />} />
						<Route path="outgoing-txs" element={<ChainOutgoingTxs />} />

						<Route path="tips" element={<ChainTipsAndReviews />} />

						<Route path="*" element={<Navigate to="/" />} />
					</Route>
					<Route path=":placeId/" element={<PlaceCatcher />}>
						<Route index element={<Dashboard />} />
						<Route path="profile" element={<PlaceProfile />} />
						<Route path="employees" element={<Employees />}>
							<Route path=":employeeId/" element={<EmployeeCard />} />
						</Route>
						<Route path="groups" element={<Groups />}>
							<Route path=":groupId/" element={<GroupCard />} />
						</Route>
						<Route path="qr-codes" element={<QRCodes />} />
						<Route path="outgoing-txs" element={<OutgoingTxs />} />
						<Route path="users-outgoing-txs" element={<EmpsOutgoingTxsPage />} />

						<Route path="settings" element={<Settings />} />
						<Route path="tips" element={<TipsAndReviews />} />

						<Route path="*" element={<Navigate to="/" />} />
					</Route>
				</Route>
				<Route path="invite/:inviteCode/" element={<InvitePage />} />
				<Route path="login" element={<LoginPage />} />
			</Route>
		</Routes>
	);
});
