import { frontmanGet, writerPost } from '.';
import { GroupExt } from '../../pages/Groups/GroupCard';
import { Sorting } from '../../web-types/Sorting';
import NetworkResponse from '../responses/NetworkResponse';
import NetworkTableResponse from '../responses/NetworkTableResponse';
import { ReviewRow } from './employees';

export function manager_fetchGroups(
	placeId: number,
	offset: number,
	limit: number,
	params: { query: string; sorting: Sorting | null },
): NetworkTableResponse<{ id: number }> {
	return frontmanGet(`/groups/${placeId}/list`, { offset, limit, params: JSON.stringify(params) });
}

export function manager_fetchGroup(groupId: number): Promise<NetworkResponse<GroupExt>> {
	return frontmanGet(`/groups/get/${groupId}`);
}

export function manager_fetchGroupReviews(
	groupId: number,
	offset: number,
	limit: number,
	params: { query: string; sorting: Sorting | null },
): NetworkTableResponse<ReviewRow> {
	return frontmanGet(`/reviews/group/${groupId}/list`, { offset, limit, params: JSON.stringify(params) });
}

export function manager_updateGroupName(groupId: number, name: string) {
	return writerPost('POST', `/groups/${groupId}/name/`, null, { name });
}

export function manager_removeGroup(groupId: number) {
	return writerPost('POST', `/groups/${groupId}/remove/`, null);
}

export function manager_groupAddEmployee(groupId: number, userId: number) {
	return writerPost('POST', `/groups/${groupId}/add-employee/`, null, { userId });
}

export function manager_groupRemoveEmployee(groupId: number, userId: number) {
	return writerPost('POST', `/groups/${groupId}/remove-employee/`, null, { userId });
}

export function manager_groupAdd(placeId: number, name: string) {
	return writerPost('POST', `/groups/${placeId}/add`, null, { name });
}
