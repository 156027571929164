import React, { PureComponent } from 'react';

import { observable } from 'mobx';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { Avatar, Button, Input, Popover } from 'antd';
import { CrownOutlined, DeleteOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';

import withNavigateParams from '../../utils/withNavigateParams';
import { NavigateFunction, Outlet } from 'react-router';
import chains from '../../stores/chains';

import { Sorting } from '../../web-types/Sorting';

import './style.scss';
import { EmployeeRow, manager_fetchEmployees } from '../../lib/api-web/employees';
import { TableController } from '../../controls/TableController';
import { autobind } from 'core-decorators';
import { manager_attachEmployeeToChain, manager_detachEmployeeFromChain } from '../../lib/api-web';
import handleError from '../../utils/handleError';
import ConfirmModal from '../../global-modals/ConfirmModal';
import EmployeeRoleModal from '../../global-modals/EmployeeRoleModal';
import toaster from '../../stores/toaster';
import UserPlaceRole from '../../web-types/UserPlaceRole';
import SelectEmployeeModal from '../../global-modals/SelectEmployeeModal';

@observer
class ChainManagersPage extends PureComponent<{ navigate: NavigateFunction; params: any; state: any }> {
	@observable contentVisible = false;
	@observable total: number | null = null;
	@observable params: { query: string } = { query: '' };

	@observable employeeInviting = false;

	controller = React.createRef<TableController<{ query: string }, EmployeeRow>>();

	reload() {
		this.controller.current?.load();
	}

	@autobind
	handleTablePropsUpdate(contentVisible: boolean, total: number | null, rowsPerPage: number) {
		this.contentVisible = contentVisible;
		this.total = total;
	}

	@autobind
	async handleLoad(params: { query: string }, offset: number, limit: number, sorting: Sorting | null) {
		return manager_fetchEmployees(
			{ offset, limit, sorting },
			{
				query: params.query,
				notInGroupId: null,
				notInPlaceId: null,
				placeId: null,
				chainId: this.props.params.chainId,
			},
		);
	}

	async handleAddEmployee() {
		const employeeId = await SelectEmployeeModal.show(null, null, null, this.props.params.chainId);
		if (!employeeId) {
			return;
		}
		const data = await EmployeeRoleModal.prompt('Select role for this chain', UserPlaceRole.OWNER);
		if (!data) {
			return;
		}
		this.employeeInviting = true;
		try {
			const response = await manager_attachEmployeeToChain(
				employeeId,
				this.props.params.chainId,
				data.role as any,
				data.text_role,
			);
			if (response.result) {
				await this.controller.current?.load();
			} else {
				handleError(response.error);
			}
		} catch (err) {
			handleError(err);
		}
	}

	render() {
		if (this.props.params.employeeId) {
			return <Outlet />;
		}
		return (
			<div key="two" className={cn('page', { hidden: !this.contentVisible })}>
				<div className="page-head">
					<div className="page-title">
						<h2>
							Managers of {chains.chain?.name} {this.total !== null ? `(${this.total})` : ``}
						</h2>
					</div>
					<div className="page-actions">
						<Input.Search
							style={{ marginRight: 15 }}
							type="search"
							onSearch={value => (this.params.query = value)}
							placeholder="Search employee..."
						/>
						<Button
							type="primary"
							icon={<PlusOutlined />}
							onClick={e => {
								e.preventDefault();
								this.handleAddEmployee();
							}}
						>
							Add
						</Button>
					</div>
				</div>
				<TableController
					ref={this.controller}
					onRowClick={({ id }) => {
						this.props.navigate(`/chain/${this.props.params.chainId}/managers/${id}/`, {
							state: { backable: true },
						});
					}}
					params={this.params}
					load={this.handleLoad}
					onTablePropsUpdate={this.handleTablePropsUpdate}
					columns={[
						{
							key: 'avatarUrl',
							title: '',
							render: value => <Avatar size={50} src={value} icon={<UserOutlined />} />,
							width: 50,
						},
						{
							key: 'firstName',
							title: 'Name',
							sortable: true,
							render: (value, row) => `${row.firstName} ${row.lastName}`,
						},
						{
							key: 'uc_role',
							title: 'Role',
							sortable: true,
						},
						{
							key: 'uc_text_role',
							title: 'Position',
							sortable: true,
						},
						{
							key: 'id',
							title: 'Actions',
							width: 70,
							render: (id, row) => (
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
									}}
								>
									<Popover content="Change role">
										<Button
											type="text"
											icon={<CrownOutlined />}
											onClick={async e => {
												e.stopPropagation();
												e.preventDefault();
												const data = await EmployeeRoleModal.prompt(
													'Select employee role',
													UserPlaceRole.OWNER,
													(row as any).uc_role,
													(row as any).uc_text_role,
												);
												if (!data) {
													return;
												}
												try {
													const response = await manager_attachEmployeeToChain(
														Number(id),
														Number(this.props.params.chainId),
														data.role as any,
														data.text_role,
													);
													if (response.result) {
														toaster.show({
															message: 'You successfully changed the employee role',
															type: 'success',
														});
														await this.reload();
													} else {
														handleError(response.error);
													}
												} catch (err) {
													handleError(err);
												}
											}}
										/>
									</Popover>
									<Popover content="Remove from chain">
										<Button
											type="text"
											danger
											icon={<DeleteOutlined />}
											onClick={async e => {
												e.preventDefault();
												e.stopPropagation();
												const descision = await ConfirmModal.prompt(
													'Are you sure?',
													'You will be able to attach employee to chain again, if needed.',
													'Yes',
												);
												if (!descision) {
													return;
												}
												try {
													const response = await manager_detachEmployeeFromChain(
														Number(id),
														Number(this.props.params.chainId),
													);
													if (response.result) {
														toaster.show({
															message: 'You successfully detached the employee',
															type: 'success',
														});
														this.reload();
													} else {
														handleError(response.error);
													}
												} catch (err) {
													handleError(err);
												}
											}}
										/>
									</Popover>
								</div>
							),
						},
					]}
				/>
			</div>
		);
	}
}

export default withNavigateParams(ChainManagersPage);
