import { FC, useEffect, useMemo, useState } from 'react';
import { Select } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { BorderOutlined, UserDeleteOutlined } from '@ant-design/icons';

export interface SelectEntry {
	id: number;
	title: string;
	avatarUrl: string | null;
}

export type EntitySelectProps = {
	placeholder?: string;
	loader: (query: string, id: number | null) => Promise<SelectEntry[]>;
	selectedId: number | null;
	onSelect: (value: number | null) => void;
};

export const EntitySelect: FC<EntitySelectProps> = ({
	placeholder,
	loader,
	onSelect,
	selectedId,
	...rest
}: EntitySelectProps) => {
	const [query] = useState<string>('');
	const [wasLoaded, setWasLoaded] = useState(false);
	const [options, setOptions] = useState<SelectEntry[]>([]);
	const optionsMap: Record<number, SelectEntry> = useMemo(
		() =>
			options.reduce(
				(p, c) => ({
					...p,
					[c.id]: c,
				}),
				{} as Record<number, SelectEntry>,
			),
		[options],
	);

	const isThereAvatars = useMemo(() => options.some(o => !!o.avatarUrl), [options]);

	useEffect(() => {
		if (selectedId && !options.some(opt => opt.id === selectedId)) {
			setWasLoaded(true);
			loader(query, selectedId).then(result => {
				setOptions(result);
			});
		} else if (!wasLoaded) {
			setWasLoaded(true);
			loader(query, selectedId).then(result => {
				console.log('got result: ', result);
				setOptions(result);
			});
		}
	}, [loader, options, query, selectedId, wasLoaded]);

	return (
		<Select
			allowClear
			placeholder={placeholder}
			value={
				selectedId
					? {
							key: selectedId,
							value: selectedId,
							label: (
								<>
									{isThereAvatars ? (
										<Avatar
											style={{ marginTop: -2 }}
											src={optionsMap[selectedId].avatarUrl}
											icon={<UserDeleteOutlined />}
											size={18}
										/>
									) : null}{' '}
									{optionsMap[selectedId].title}
								</>
							),
					  }
					: null
			}
			onSelect={(value: any, option: any) => {
				onSelect(Number(value));
			}}
			onClear={() => {
				onSelect(null);
			}}
			style={{ minWidth: 200 }}
		>
			{options.map(opt => (
				<Select.Option key={opt.id}>
					{isThereAvatars ? (
						<Avatar src={opt.avatarUrl} icon={<BorderOutlined />} style={{ marginRight: 10 }} />
					) : null}
					{opt.title}
				</Select.Option>
			))}
		</Select>
	);
};
