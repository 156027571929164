import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import { PureComponent } from 'react';
import { NavigateFunction, Outlet, useNavigate, useParams } from 'react-router';
import chains from '../stores/chains';

@observer
class ChainCatcherInner extends PureComponent<{ chainId: number; navigate: NavigateFunction }> {
	dispose: any;

	componentDidMount() {
		const delayedNavigate = (url: string) => {
			const { navigate } = this.props;
			setTimeout(() => navigate(url), 0);
		};
		this.dispose = autorun(() => {
			const { chainId } = this.props;
			if (!chains.chains.length) {
				return;
			}
			if (chains.currentChainId === null) {
				if (!isNaN(chainId)) {
					if (chains.chains.find(p => p.id === chainId)) {
						chains.currentChainId = chainId;
					} else {
						return delayedNavigate('/');
					}
				} else {
					return delayedNavigate(`/${chains.chains[0].id}`);
				}
			} else {
				if (chainId && chains.currentChainId !== chainId) {
					chains.currentChainId = chainId;
					return;
				} else if (!chains.chains.find(p => p.id === chainId)) {
					chains.currentChainId = null;
					return delayedNavigate('/');
				}
			}
		});
	}

	componentWillUnmount() {
		this.dispose();
	}

	render() {
		if (!chains.chains.length) {
			return <>Loading</>;
		}
		if (chains.currentChainId === this.props.chainId && chains.chain) {
			return <Outlet />;
		} else {
			return null;
		}
	}
}

const ChainCatcher = () => {
	const params = useParams();
	const navigate = useNavigate();
	const npid = Number(params.chainId);

	return <ChainCatcherInner navigate={navigate} chainId={npid} />;
};

export default ChainCatcher;
