import { PureComponent } from 'react';

import { observable } from 'mobx';
import { observer } from 'mobx-react';
import cn from 'classnames';

import withNavigateParams from '../../utils/withNavigateParams';
import { NavigateFunction } from 'react-router';
import places from '../../stores/places';

import { Sorting } from '../../web-types/Sorting';

import './style.scss';
import moment from 'moment';
import { TableController } from '../../controls/TableController';
import { autobind } from 'core-decorators';
import {
	employee_fetchMyOutgoingTxs,
	employee_fetchOutgoingTxs,
	manager_fetchEmployeesOutgoingTxs,
	manager_fetchOutgoingTxs,
} from '../../lib/api-web/qr-codes';
import UserPlaceRole from '../../web-types/UserPlaceRole';
import user from '../../stores/user';
import { Button } from 'antd';
import OutgoingTxStatus from '../../web-types/OutgoingTxStatus';
import { manager_setWithdrawalStatus } from '../../lib/api-web/places';
import React from 'react';

const statuses = {
	'created': 'Created',
	'auto-approved': 'Approved',
	'need-manual-approve': 'Processing',
	'manual-approved': 'Processing',
	'processing': 'Processing',
	'success': 'Sucessfully sent',
	'failed': 'Rejected',
	'rejected': 'Rejected',
};

@observer
class OutgoingTxsPage extends PureComponent<{
	navigate: NavigateFunction;
	params: any;
	state: any;
}> {
	@observable contentVisible = false;
	@observable total: number | null = null;
	@observable params: { query: string } = { query: '' };

	tcRef = React.createRef<any>();

	@observable employeeInviting = false;

	@autobind
	handleTablePropsUpdate(contentVisible: boolean, total: number | null, rowsPerPage: number) {
		this.contentVisible = contentVisible;
		this.total = total;
	}

	@autobind
	async handleLoad(params: { query: string }, offset: number, limit: number, sorting: Sorting | null) {
		if (places.place?.role === UserPlaceRole.EMPLOYEE) {
			return employee_fetchMyOutgoingTxs(offset, limit, {
				query: params.query,
				sorting,
			});
		} else {
			return manager_fetchOutgoingTxs(this.props.params.placeId, offset, limit, {
				query: params.query,
				sorting,
			});
		}
	}

	render() {
		return (
			<div key="two" className={cn('page', { hidden: !this.contentVisible })}>
				<div className="page-head">
					<div className="page-title">
						<h2>
							Payout requests of{' '}
							{places.place?.role === UserPlaceRole.EMPLOYEE
								? `${user.user?.firstName} ${user.user?.lastName}`
								: places.place?.name}{' '}
							{this.total !== null ? `(${this.total})` : ``}
						</h2>
					</div>
				</div>
				<section className="section">
					<TableController
						noSection
						onRowClick={({ unique_id }) => {
							// QRInfo.show(unique_id);
						}}
						ref={this.tcRef}
						params={this.params}
						load={this.handleLoad}
						onTablePropsUpdate={this.handleTablePropsUpdate}
						columns={[
							{
								key: 'created_at',
								title: 'Date',
								sortable: true,
								render: (value, row) => (value ? moment(value).format('DD.MM HH:mm') : 'Never'),
							},
							{
								key: 'amount',
								title: 'Amount',
								sortable: true,
								render: value => value + ' AED',
							},
							{
								key: 'status',
								title: 'Status',
								sortable: true,
								render: val => statuses[val as keyof typeof statuses],
							},
						]}
					/>
				</section>
			</div>
		);
	}
}

export default withNavigateParams(OutgoingTxsPage);
