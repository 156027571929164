import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import Modal from 'antd/lib/modal/Modal';

//@ts-ignore
import Scanner from 'react-webcam-qr-scanner';

import modals from '../../stores/modals';

export interface QRScanModalProps {
	okButton: string;
	onDismiss: () => void;
	onConfirm: (text: string) => void;
}

function ScannerWrap({ onDecode, onScannerLoad }: any) {
	return (
		<Scanner
			className="some-classname"
			onDecode={onDecode}
			onScannerLoad={onScannerLoad}
			constraints={{
				audio: false,
				video: {
					facingMode: 'environment',
				},
			}}
			captureSize={{ width: 1280, height: 720 }}
		/>
	);
}

@observer
export default class QRScanModal extends PureComponent<QRScanModalProps> {
	static async prompt(okButton: string = 'Confirm'): Promise<string | null> {
		return new Promise<string | null>((resolve, reject) => {
			modals.show((close: () => void) => (
				<QRScanModal
					okButton={okButton}
					onConfirm={text => {
						close();
						resolve(text);
					}}
					onDismiss={() => {
						close();
						resolve(null);
					}}
				/>
			));
		});
	}

	divRef = React.createRef<HTMLDivElement>();

	@autobind
	handleDecode(result: any) {
		console.log('result: ', result);
		if (this.divRef.current && result && result.location) {
			const lt = result.location.topLeftCorner;
			const wh = {
				w: result.location.bottomRightCorner.x - lt.x,
				h: result.location.bottomRightCorner.y - lt.y,
			};
			this.divRef.current.style.left = (lt.x / 640) * 100 + '%';
			this.divRef.current.style.top = (lt.y / 480) * 100 + '%';
			this.divRef.current.style.width = (wh.w / 640) * 100 + '%';
			this.divRef.current.style.height = (wh.h / 480) * 100 + '%';
			if (typeof result.data === 'string' && result.data && result.data.includes('q.tipz')) {
				if (result.data.startsWith('https://q.tipz.ae') || result.data.startsWith('https://q.tipz.lc')) {
					this.props.onConfirm(result.data);
				}
			}
		}
	}

	@autobind
	handleScannerLoad(mode: any) {
		console.log(mode);
	}

	render() {
		return (
			<Modal
				width="min-content"
				okText={this.props.okButton}
				onCancel={() => this.props.onDismiss()}
				visible={true}
				closable={false}
				title="Scan QR code"
			>
				<div style={{ position: 'relative' }}>
					<ScannerWrap key="1" onDecode={this.handleDecode} onScannerLoad={this.handleScannerLoad} />
					<div
						ref={this.divRef}
						style={{
							borderColor: 'rgba(0, 255, 255, 1)',
							borderWidth: 3,
							borderStyle: 'solid',
							position: 'absolute',
						}}
					/>
				</div>
			</Modal>
		);
	}
}
