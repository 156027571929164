import { computed, observable } from 'mobx';
import authErrorsEn from '../i18n/en/errors/auth';
import authErrorsRu from '../i18n/ru/errors/auth';
import mainLabelsEn from '../i18n/en/labels/main';
import mainLabelsRu from '../i18n/ru/labels/main';

class I18N {
	@observable lang: 'en' | 'ru' = 'en';

	@computed get errors() {
		if (this.lang === 'en') {
			return {
				auth: authErrorsEn,
			};
		} else {
			return {
				auth: authErrorsRu,
			};
		}
	}

	@computed get m() {
		if (this.lang === 'en') {
			return mainLabelsEn;
		} else {
			return mainLabelsRu;
		}
	}
}

const i18n = new I18N();

export default i18n;
