const ENV = process.env.REACT_APP_TIPZ_ENV as 'demo' | 'local' | 'prod';

console.log('TIPZ_ENV: ', process.env.REACT_APP_TIPZ_ENV);

function pick(vars: Record<'demo' | 'local' | 'prod', string>) {
	return vars[ENV];
}

export const FRONTMAN_API = pick({
	demo: 'https://demo.tipz.ae/api',
	local: 'https://api.tipz.lc',
	prod:
		document.location.host.endsWith('.ae') || document.location.host.endsWith('.lc')
			? 'https://api.tipz.ae'
			: 'https://api.tipz.ge',
});

export const WRITER_API = pick({
	demo: 'https://demo.tipz.ae/api',
	local: 'https://api.tipz.lc',
	prod:
		document.location.host.endsWith('.ae') || document.location.host.endsWith('.lc')
			? 'https://api.tipz.ae'
			: 'https://api.tipz.ge',
});
