import { FC, HTMLAttributes } from 'react';
import cn from 'classnames';

import './style.scss';

export const StatHeader: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className, ...props }) => (
	<section className={cn('StatHeader', className)} {...props}>
		{children}
	</section>
);

export const StatPlate: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className, ...props }) => (
	<section className={cn('StatPlate', className)} {...props}>
		{children}
	</section>
);

export const StatDiv: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className, ...props }) => (
	<section className={cn('StatDiv', className)} {...props}>
		{children}
	</section>
);

export const StatPlateTitle: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className, ...props }) => (
	<section className={cn('StatPlateTitle', className)} {...props}>
		{children}
	</section>
);

export const StatPlateValue: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className, ...props }) => (
	<section className={cn('StatPlateValue', className)} {...props}>
		{children}
	</section>
);
