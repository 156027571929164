import Modal from 'antd/lib/modal/Modal';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { PureComponent } from 'react';
import modals from '../../stores/modals';
import { Form, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import i18n from '../../stores/i18n';

export interface WithdrawModalProps {
	defaultComment?: string;
	onDismiss: () => void;
	onConfirm: (data: { amount: number; comment: string }) => void;
}

@observer
export default class WithdrawModal extends PureComponent<WithdrawModalProps> {
	static async prompt(defaultComment?: string): Promise<{ amount: number; comment: string } | null> {
		return new Promise<{ amount: number; comment: string } | null>((resolve, reject) => {
			modals.show((close: () => void) => (
				<WithdrawModal
					defaultComment={defaultComment}
					onConfirm={data => {
						close();
						resolve(data);
					}}
					onDismiss={() => {
						close();
						resolve(null);
					}}
				/>
			));
		});
	}

	@observable amount: string = '100';
	@observable comment: string = this.props.defaultComment || '';

	render() {
		const isValid = !isNaN(parseFloat(this.amount)) && parseFloat(this.amount) >= 100;

		return (
			<Modal
				title={i18n.m.WITHDRAWAL_MODAL_TITLE}
				okButtonProps={{ disabled: !isValid }}
				onCancel={() => this.props.onDismiss()}
				okText={i18n.m.OK}
				cancelText={i18n.m.CANCEL}
				visible={true}
				closable={false}
				onOk={() => {
					this.props.onConfirm({ amount: parseFloat(this.amount), comment: this.comment });
				}}
			>
				<Form>
					<Form.Item validateStatus={isValid ? 'success' : 'error'} help={i18n.m.WITHDRAWAL_MODAL_HELP}>
						<Input
							autoFocus={true}
							style={{ width: '100%' }}
							placeholder={i18n.m.ENTER_AMOUNT}
							value={this.amount}
							onChange={e => (this.amount = e.target.value)}
						/>
					</Form.Item>
					<Form.Item style={{ marginTop: 20 }} validateStatus={isValid ? 'success' : 'error'}>
						<TextArea
							style={{ width: '100%', height: 150 }}
							placeholder={i18n.m.ENTER_BANK_DETAILS}
							value={this.comment}
							onChange={e => (this.comment = e.target.value)}
						/>
					</Form.Item>
				</Form>
			</Modal>
		);
	}
}
