import Modal from 'antd/lib/modal/Modal';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { PureComponent } from 'react';
import modals from '../../stores/modals';
import { Form, Input, Select } from 'antd';
import UserPlaceRole from '../../web-types/UserPlaceRole';
import user from '../../stores/user';
import InternalRole from '../../web-types/InternalRole';
import { autobind } from 'core-decorators';

export interface EmployeeRoleModalProps {
	title: string;
	myRoleInThisPlace: UserPlaceRole | null;
	defaultRole?: UserPlaceRole;
	defaultTextRole?: string;
	helpText?: string;
	onDismiss: () => void;
	onConfirm: (data: { role: UserPlaceRole; text_role: string }) => void;
}

@observer
export default class EmployeeRoleModal extends PureComponent<EmployeeRoleModalProps> {
	static async prompt(
		title: string,
		myRoleInThisPlace: UserPlaceRole | null,
		defaultRole?: UserPlaceRole,
		defaultTextRole?: string,
		helpText?: string,
	) {
		return new Promise<{
			role: UserPlaceRole;
			text_role: string;
		} | null>((resolve, reject) => {
			modals.show((close: () => void) => (
				<EmployeeRoleModal
					title={title}
					myRoleInThisPlace={myRoleInThisPlace}
					defaultRole={defaultRole}
					defaultTextRole={defaultTextRole}
					helpText={helpText}
					onConfirm={data => {
						close();
						resolve(data);
					}}
					onDismiss={() => {
						close();
						resolve(null);
					}}
				/>
			));
		});
	}

	@observable role: UserPlaceRole = this.props.defaultRole || UserPlaceRole.EMPLOYEE;
	@observable text_role: string = this.props.defaultTextRole || 'Waiter';

	@autobind
	handleEnter(e: React.KeyboardEvent) {
		if (e.key === 'Enter') {
			this.props.onConfirm({
				role: this.role,
				text_role: this.text_role,
			});
		}
	}

	render() {
		return (
			<Modal
				title={this.props.title}
				okButtonProps={{ disabled: false }}
				onCancel={() => this.props.onDismiss()}
				visible={true}
				closable={false}
				onOk={() => {
					this.props.onConfirm({
						role: this.role,
						text_role: this.text_role,
					});
				}}
			>
				<Form>
					<Form.Item>
						<Select value={this.role} onChange={e => (this.role = e)}>
							<Select.Option
								value={UserPlaceRole.EMPLOYEE}
								disabled={
									user.user!.role !== InternalRole.ADMIN &&
									user.user!.role !== InternalRole.SALES &&
									(this.props.myRoleInThisPlace === null ||
										this.props.myRoleInThisPlace === UserPlaceRole.EMPLOYEE)
								}
							>
								Employee
							</Select.Option>
							<Select.Option
								value={UserPlaceRole.ADMIN}
								disabled={
									user.user!.role !== InternalRole.ADMIN &&
									user.user!.role !== InternalRole.SALES &&
									(this.props.myRoleInThisPlace === null ||
										this.props.myRoleInThisPlace !== UserPlaceRole.OWNER)
								}
							>
								Admin
							</Select.Option>
							<Select.Option
								value={UserPlaceRole.OWNER}
								disabled={
									user.user!.role !== InternalRole.ADMIN &&
									user.user!.role !== InternalRole.SALES &&
									(this.props.myRoleInThisPlace === null ||
										this.props.myRoleInThisPlace !== UserPlaceRole.OWNER)
								}
							>
								Owner
							</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item help={this.props.helpText}>
						<Input
							autoFocus={true}
							style={{ width: '100%' }}
							placeholder="Enter employee position"
							value={this.text_role}
							onKeyPress={this.handleEnter}
							onChange={e => (this.text_role = e.target.value)}
						/>
					</Form.Item>
				</Form>
			</Modal>
		);
	}
}
