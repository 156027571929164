export default function ReviewsIcon() {
	return (
		<svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M6.70832 11H13.9821L10.8196 7.71C10.6391 7.52169 10.5377 7.2663 10.5377 7C10.5377 6.7337 10.6391 6.4783 10.8196 6.29C11 6.10169 11.2448 5.99591 11.5 5.99591C11.7552 5.99591 11.9999 6.10169 12.1804 6.29L16.9721 11.29C17.0593 11.3851 17.1277 11.4972 17.1733 11.62C17.2692 11.8635 17.2692 12.1365 17.1733 12.38C17.1277 12.5027 17.0593 12.6149 16.9721 12.71L12.1804 17.71C12.0913 17.8037 11.9853 17.8781 11.8685 17.9289C11.7518 17.9797 11.6265 18.0058 11.5 18.0058C11.3735 18.0058 11.2482 17.9797 11.1314 17.9289C11.0147 17.8781 10.9087 17.8037 10.8196 17.71C10.7297 17.617 10.6585 17.5064 10.6098 17.3846C10.5611 17.2627 10.5361 17.132 10.5361 17C10.5361 16.868 10.5611 16.7373 10.6098 16.6154C10.6585 16.4936 10.7297 16.383 10.8196 16.29L13.9821 13H6.70832C6.45415 13 6.2104 12.8946 6.03068 12.7071C5.85095 12.5196 5.74999 12.2652 5.74999 12C5.74999 11.7348 5.85095 11.4804 6.03068 11.2929C6.2104 11.1054 6.45415 11 6.70832 11Z"
				fill="white"
			/>
		</svg>
	);
}
