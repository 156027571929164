import cn from 'classnames';
import { FC, HTMLAttributes, useRef, useState } from 'react';

const MagicPlate: FC<HTMLAttributes<HTMLDivElement>> = ({ className, children, ...rest }) => {
	const [transform, setTransform] = useState('perspective(500px) scale(1) rotateX(0) rotateY(0)');
	const ref = useRef<HTMLDivElement>(null);

	return (
		<div
			ref={ref}
			className={cn('magic-plate', className)}
			onMouseOut={() => setTransform('perspective(500px) scale(1) rotateX(0) rotateY(0)')}
			onMouseDown={() => setTransform('perspective(500px) scale(0.9) rotateX(0) rotateY(0)')}
			onMouseUp={() => setTransform('perspective(500px) scale(1.1) rotateX(0) rotateY(0)')}
			onMouseMove={e => {
				const height = ref.current!.clientHeight;
				const width = ref.current!.clientWidth;

				const xVal = e.nativeEvent.offsetX;
				const yVal = e.nativeEvent.offsetY;

				const yRotation = 20 * ((xVal - width / 2) / width);
				const xRotation = -20 * ((yVal - height / 2) / height);

				setTransform(
					'perspective(500px) scale(1.05) rotateX(' + xRotation + 'deg) rotateY(' + yRotation + 'deg)',
				);
			}}
			style={{ transform }}
			{...rest}
		>
			{children}
		</div>
	);
};

export default MagicPlate;
