import Modal from 'antd/lib/modal/Modal';
import { observer } from 'mobx-react';
import { PureComponent } from 'react';
import modals from '../../stores/modals';

export interface TipzProModalProps {
	onClose: () => void;
}

@observer
export default class TipzProModal extends PureComponent<TipzProModalProps> {
	static async show(): Promise<void> {
		return new Promise((resolve, reject) => {
			modals.show((close: () => void) => (
				<TipzProModal
					onClose={() => {
						close();
						resolve();
					}}
				/>
			));
		});
	}

	render() {
		return (
			<Modal
				okText={'OK'}
				visible={true}
				closable={true}
				title={'Tipz.Pro'}
				cancelButtonProps={{ style: { display: 'none' } }}
				onOk={() => {
					this.props.onClose();
				}}
			>
				<div style={{ width: '100%' }}>
					<br />
					<b>tipz.me</b> is a tech solution that allows companies to receive instant-cashless tips and reviews
					via QR codes.
					<br />
					<br />
					<b>tipz.menu</b> is a QR menu for all tertiary sector businesses. It allows customers the ease of
					ordering and paying and business-owners the ease of tracking, modifying and much more.
					<br />
					<br />
					If you are interested in getting access to the full scope of our services, please contact your Tipz
					sales manager.
				</div>
			</Modal>
		);
	}
}
