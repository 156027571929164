import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import { PureComponent } from 'react';
import { NavigateFunction, Outlet, useNavigate, useParams } from 'react-router';
import places from '../stores/places';

@observer
class PlaceCatcherInner extends PureComponent<{ placeId: number; navigate: NavigateFunction }> {
	dispose: any;

	componentDidMount() {
		const delayedNavigate = (url: string) => {
			const { navigate } = this.props;
			setTimeout(() => navigate(url), 0);
		};
		this.dispose = autorun(() => {
			const { placeId } = this.props;
			if (!places.places.length) {
				return;
			}
			if (places.currentPlaceId === null) {
				if (!isNaN(placeId)) {
					if (places.places.find(p => p.id === placeId)) {
						places.currentPlaceId = placeId;
					} else {
						return delayedNavigate('/');
					}
				} else {
					return delayedNavigate(`/${places.places[0].id}`);
				}
			} else {
				if (placeId && places.currentPlaceId !== placeId) {
					places.currentPlaceId = placeId;
					return;
				} else if (!places.places.find(p => p.id === placeId)) {
					places.currentPlaceId = null;
					return delayedNavigate('/');
				}
			}
		});
	}

	componentWillUnmount() {
		this.dispose();
	}

	render() {
		if (!places.places.length) {
			return <>Loading</>;
		}
		if (places.currentPlaceId === this.props.placeId && places.place) {
			return <Outlet />;
		} else {
			return null;
		}
	}
}

const PlaceCatcher = () => {
	const params = useParams();
	const navigate = useNavigate();
	const npid = Number(params.placeId);

	return <PlaceCatcherInner navigate={navigate} placeId={npid} />;
};

export default PlaceCatcher;
