const authErrorsEn: Record<string, string> = {
	'AUTH:INVALID_PHONE': 'Phone is incorrect',
	'AUTH:USER_NOT_FOUND': 'User was not found',
	'AUTH:VERIFICATION_EXPIRED': 'Verification code is expired',
	'AUTH:TRY_AGAIN_IN_MINUTE': 'Please, try again in one minute',
	'AUTH:INVALID_CODE': 'Code is incorrect',
	'AUTH:PREVIOUS_CODE_NOT_EXPIRED': 'You can resend the code in 2 minutes',
	'AUTH:DATABASE_ERROR': 'Database error. Please, try again',
};

export default authErrorsEn;
