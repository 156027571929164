import { computed, observable, toJS } from 'mobx';
// import safeJsonParse from '../utils/safeJsonParse';
import CompactUserData from '../web-types/CompactUserData';
import * as api from '../lib/api';

export class UserStore {
	@observable user: null | CompactUserData = null;
	@observable loading = true;

	constructor() {
		this.load();
	}

	async load() {
		try {
			const result = await api.getMe();
			if (result.result) {
				this.user = result.data;
			}
		} catch (err) {}
		this.loading = false;
		// const userDataString = localStorage.getItem('user_data');
		// if (userDataString) {
		// 	this.user = safeJsonParse(userDataString, null);
		// } else {
		// 	this.user = null;
		// }
	}

	save() {
		if (this.user) {
			localStorage.setItem('user_data', JSON.stringify(toJS(this.user)));
		} else {
			localStorage.removeItem('user_data');
		}
	}

	@computed get isAuthorized() {
		return !!this.user;
	}
}

const user = new UserStore();

export default user;
