const mainLabelsRu = {
	//
	OK: 'ОК',
	CANCEL: 'Отмена',
	//
	EDIT_PROFILE: 'Изменить',
	SELECT_PLACE: 'Выбрать заведение',
	PROFILE: 'Профиль',
	LOGOUT: 'Выйти',
	//
	MENU_DASHBOARD: 'Дэшборд',
	MENU_EMPLOYEES: 'Сотрудники',
	MENU_GROUPS: 'Группы',
	MENU_TIPS: 'Чаевые и отзывы',
	MENU_QR_CODES: 'QR-коды',
	MENU_PAYOUTS: 'Выплаты',
	MENU_SETTINGS: 'Настройки',
	MENU_PLACES: 'Заведения',
	MENU_MANAGERS: 'Менеджеры',
	MENU_PAYOUT_REQUESTS: 'Запросы выплат',
	//
	DASHBOARD_TITLE: 'Активность вашего бизнеса',
	DASHBOARD_WITHDRAWAL_MINIMUM: 'Вы можете вывести только свыше 100 рублей',
	WITHDRAWAL_BUTTON: 'Вывести',
	BALANCE: 'Баланс',
	//
	AVG_RATING_30D: 'Средний рейтинг за 30 дней',
	TIPS_VOL_7D: 'Сумма чаевых за 7 дней',
	REVIEWS_COUNT_7D: 'Количество отзывов за 7 дней',
	DASHBOARD_STATISTICS: 'Детальная статистика',
	DASHBOARD_BEST_EMPLOYEE_MONTH: 'Сотрудник месяца',
	//
	WITHDRAWAL_MODAL_TITLE: 'Запрос на вывод',
	WITHDRAWAL_MODAL_HELP: 'Минимальная сумма: 100 руб',
	ENTER_AMOUNT: 'Введите сумму...',
	ENTER_BANK_DETAILS: 'Введите реквизиты вашего счета',
	//
	MY_PROFILE: 'Мой профиль',
	UPLOAD_NEW_AVATAR: 'Загрузить аватар',
	EDIT_NAME: 'Изменить имя',
	EDIT_PHONE: 'Изменить телефон',
	EDIT_PASSWORD: 'Изменить пароль',
	PROFILE_HELPER_TEXT: 'Пожалуйста, используйте кнопки слева для редактирования профиля. Спасибо!',
	ENTER_NEW_PASSWORD: 'Введите новый пароль',
	ENTER_NEW_PHONE: 'Введите новый телефон',
	ENTER_NEW_FIRST_NAME: 'Введите новое имя',
	ENTER_NEW_LAST_NAME: 'Введите новую фамилию',
	//
	EMPLOYEES_OF: 'Сотрудники',
	SEARCH_EMPLOYEE: 'Поиск сотрудника...',
	ADD: 'Добавить',
	NAME: 'Имя',
	ROLE: 'Роль',
	RATING_7D: `⭐ 7 дней`,
	RATING_30D: `⭐ 30 дней`,
	RATING_MONTH: `⭐ Месяц`,
	RATING_WEEK: `⭐ Неделя`,
	ACTIONS: 'Действия',
	ROLE_Employee: 'Сотрудник',
	ROLE_Manager: 'Менеджер',
	ROLE_Admin: 'Администратор',
	ROLE_Owner: 'Владелец',
	//
	ENTER_PHONE_TO_LOGIN: 'Пожалуйста, введите номер телефона для авторизации',
	ENTER_YOUR_PHONE_NUMBER: 'Введите ваш номер телефона',
	INVALID_PHONE_NUMBER: 'Неверный номер телефона',
	PHONE_NUMBER_REQUIRED: 'Требуется номер телефона',
	ENTER_YOUR_PASSWORD: 'Пожалуйста, введите пароль',
	ENTER_PASSWORD: 'Введите пароль',
	LOGIN: 'Войти',
	WELCOME_BACK: 'Добро пожаловать обратно!',
};

export default mainLabelsRu;
