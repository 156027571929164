export default function formatNumber(value: number, d: string = ' ') {
	let s = [];
	const a = Number(Number(value).toFixed(2)).toString();
	const parts = a.split('.');
	const decPart = parts[0];
	let commaPart = '';
	if (parts.length > 1) {
		commaPart = parts[1];
	}
	const len = Math.floor(decPart.length / 3) + ((decPart.length % 3) ? 1 : 0);
	let pos = 0;
	for (let i = 0; i < len; i++) {
		const size = i === 0 ? ((decPart.length % 3) || 3) : 3;
		s.push(decPart.substring(pos, pos + size));
		pos += size;
	}
	return s.join(d) + (commaPart ? `.${commaPart}` : '');
}
