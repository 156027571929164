import { FC, useEffect, useRef } from 'react';
import { Chart } from 'chart.js';
import moment from 'moment';

const LineChart: FC<{ data: { date: string; rating: number }[] }> = ({ data }) => {
	const ref = useRef<HTMLCanvasElement>(null);

	useEffect(() => {
		const chart = new Chart(ref.current!, {
			type: 'line',
			data: {
				labels: data.map(d => moment.utc(d.date).format('DD.MM')),
				datasets: [
					{
						label: 'Rating',
						data: data.map(d => d.rating),
						borderWidth: 1,
						borderColor: '#37a2eb',
						pointStyle: 'circle',
						pointRadius: 5,
						pointHoverRadius: 8,
						cubicInterpolationMode: 'monotone',
					},
				],
			},
			options: {
				plugins: {
					legend: {
						display: false,
					},
				},
				responsive: true,
				layout: {
					padding: {
						top: 10,
						bottom: 10,
						left: 10,
						right: 10,
					},
				},
				scales: {
					xAxes: {
						grid: {
							drawOnChartArea: false,
						},
					},
					yAxes: {
						min: -1,
						max: 6,
						grid: {
							drawOnChartArea: false,
						},
					},
				},
			},
		});
		return () => {
			chart.destroy();
		};
	}, [data]);

	return (
		<canvas ref={ref} style={{ maxHeight: 200 }}>
			test
		</canvas>
	);
};

export default LineChart;
