import React, { PureComponent } from 'react';

import { observer } from 'mobx-react';
import { NavigateFunction } from 'react-router';
import withNavigateParams from '../../utils/withNavigateParams';
import { observable } from 'mobx';
import toaster from '../../stores/toaster';
import { Button, Menu, Popover, Spin, Tabs } from 'antd';
import { UploadOutlined, DeleteOutlined, EditOutlined, UserOutlined, GroupOutlined } from '@ant-design/icons';
import { TPage, TPContent, TPSidebar, TPSidebarHeader } from '../../controls/TPage';
import Avatar from 'antd/lib/avatar/avatar';
import { StatDiv, StatHeader, StatPlate, StatPlateTitle, StatPlateValue } from '../../controls/StatHeader';
import PromptModal from '../../global-modals/PromptModal';
import handleError from '../../utils/handleError';
import ConfirmModal from '../../global-modals/ConfirmModal';
import BackButton from '../../controls/BackButton';
import { autobind } from 'core-decorators';
import { Sorting } from '../../web-types/Sorting';
import TipzTable from '../../controls/TipzTable';
import {
	manager_fetchGroup,
	manager_fetchGroupReviews,
	manager_groupAddEmployee,
	manager_groupRemoveEmployee,
	manager_removeGroup,
	manager_updateGroupName,
} from '../../lib/api-web/groups';
import GroupPauseBehaviour from '../../web-types/GroupPauseBehaviour';
import CompactUserData from '../../web-types/CompactUserData';
import { TableController } from '../../controls/TableController';
import moment from 'moment';
import SelectQRCodeModal from '../../global-modals/SelectQRCodeModal';
import { manager_qrCodeAttachGroup } from '../../lib/api-web/qr-codes';
import QRInfoModal from '../../global-modals/QRInfoModal';
import SelectEmployeeModal from '../../global-modals/SelectEmployeeModal';
import formatMoney from '../../utils/formatMoney';

interface GroupCardProps {
	navigate: NavigateFunction;
	params: { placeId: string; groupId: string };
	state: { backable: boolean } | null;
}

export interface GroupRow {
	id: number;
	created_at: string;
	updated_at: string;
	deleted_at: string;
	placeId: number;
	group_name: string;
	is_uniform: boolean;
	group_pause_behaviour: GroupPauseBehaviour;
}

export interface GroupUserRelationRow {
	groupId: number;
	userId: number;
	created_at: string;
	updated_at: string;
	deleted_at: string;
	percent: number;
	user: CompactUserData;
}

export interface QRCodeRow {
	id: number;
	created_at: string;
	updated_at: string;
	deleted_at: string;
	unique_id: string;
	placeId: number;
	userId: number;
	user: CompactUserData;
	groupId: number | null;
}

export interface GroupExt {
	group: GroupRow;
	relations: GroupUserRelationRow[];
	qrs: QRCodeRow[];
	metadata: {
		sum_7d: number;
		sum_mnth: number;
		sum_week: number;
	};
}

@observer
class GroupCard extends PureComponent<GroupCardProps> {
	@observable loading = false;
	@observable avatarUploading = false;
	@observable nameEditing = false;
	@observable groupDeleting = false;
	@observable imageUrl: string | null = null;
	@observable data: GroupExt | null = null;
	@observable contentVisible = false;
	@observable total: number | null = null;

	@autobind
	handleReviewsTablePropsUpdate(contentVisible: boolean, total: number | null, rowsPerPage: number) {
		this.contentVisible = contentVisible;
		this.total = total;
	}

	async reload() {
		this.loading = true;
		try {
			const result = await manager_fetchGroup(Number(this.props.params.groupId));
			if (result.result) {
				this.data = result.data;
			} else {
				toaster.show({
					type: 'error',
					message: result.error,
				});
			}
		} catch (err) {
			toaster.show({
				type: 'error',
				message: (err as Error).message,
			});
		}
		this.loading = false;
	}

	componentDidMount() {
		this.reload();
	}

	componentDidUpdate(prevProps: GroupCardProps) {
		if (prevProps.params.placeId !== this.props.params.placeId) {
			this.reload();
		}
	}

	handleClick(info: any) {
		console.log('info: ', info);
	}

	async handleAddEmployee() {
		const userId = await SelectEmployeeModal.show(
			Number(this.props.params.placeId),
			Number(this.props.params.groupId),
			null,
			null,
		);
		if (userId) {
			await manager_groupAddEmployee(Number(this.props.params.groupId), userId);
			await this.reload();
		}
	}

	async handleRemoveEmployee(userId: number) {
		if (userId) {
			await manager_groupRemoveEmployee(Number(this.props.params.groupId), userId);
			await this.reload();
		}
	}

	async handleEditName() {
		const newName = await PromptModal.prompt('Enter new group name', this.data!.group.group_name);
		if (newName) {
			this.nameEditing = true;
			try {
				await manager_updateGroupName(Number(this.props.params.groupId), newName);
				await this.reload();
			} catch (err) {
				handleError(err);
			}
			this.nameEditing = false;
		}
	}

	async handleDelete() {
		const descision = await ConfirmModal.prompt(
			'Are you sure?',
			'This action is irreversible. You will lose all the data: employees, transactions, QR codes. Are you absolutely sure?',
			'Yes',
		);
		if (!descision) {
			return;
		}
		this.groupDeleting = true;
		try {
			const response = await manager_removeGroup(Number(this.props.params.groupId));
			if (response.result) {
				this.groupDeleting = false;
				toaster.show({ message: 'You successfully deleted the group', type: 'success' });
				this.props.navigate(`/${this.props.params.placeId}/groups`);
			} else {
				handleError(response.error);
			}
		} catch (err) {
			handleError(err);
		}
	}

	@autobind
	async handleLoadReviews(params: { query: string }, offset: number, limit: number, sorting: Sorting | null) {
		return manager_fetchGroupReviews(Number(this.props.params.groupId), offset, limit, {
			query: params.query,
			sorting,
		});
	}

	render() {
		if (!this.data) {
			return <Spin />;
		}

		return (
			<div className="page">
				<BackButton props={this.props} />
				<div className="page-head">
					<div className="page-title">
						<h2>Group "{this.data.group.group_name}"</h2>
					</div>
					<div className="page-actions"></div>
				</div>
				<TPage className="section">
					<TPSidebar>
						<TPSidebarHeader>
							{this.avatarUploading ? (
								<Avatar size={120} src={null} icon={<Spin />} />
							) : (
								<Avatar size={120} icon={<GroupOutlined />} />
							)}
						</TPSidebarHeader>
						<Menu inlineIndent={12} selectable={false} onClick={this.handleClick} mode="inline">
							<Menu.Item
								key="1"
								icon={<UploadOutlined />}
								onClick={e => {
									e.domEvent.preventDefault();
									this.handleAddEmployee();
								}}
							>
								Add new employee
							</Menu.Item>
							<Menu.Item
								key="2"
								icon={this.nameEditing ? <Spin /> : <EditOutlined />}
								onClick={e => {
									e.domEvent.preventDefault();
									this.handleEditName();
								}}
							>
								Edit name
							</Menu.Item>
							<Menu.Divider />
							<Menu.Item
								key="3"
								danger
								icon={<DeleteOutlined />}
								onClick={e => {
									e.domEvent.preventDefault();
									this.handleDelete();
								}}
							>
								Remove group
							</Menu.Item>
						</Menu>
					</TPSidebar>
					<TPContent>
						<StatHeader>
							<StatPlate>
								<StatPlateTitle>Tips for last 7 days</StatPlateTitle>
								<StatPlateValue>{formatMoney(this.data.metadata.sum_7d, 'AED')}</StatPlateValue>
							</StatPlate>
							<StatDiv />
							<StatPlate>
								<StatPlateTitle>Tips for current month</StatPlateTitle>
								<StatPlateValue>{formatMoney(this.data.metadata.sum_mnth, 'AED')}</StatPlateValue>
							</StatPlate>
							<StatDiv />
							<StatPlate>
								<StatPlateTitle>Tips for current week</StatPlateTitle>
								<StatPlateValue>{formatMoney(this.data.metadata.sum_week, 'AED')}</StatPlateValue>
							</StatPlate>
						</StatHeader>
						<Tabs defaultActiveKey="1" type="card">
							<Tabs.TabPane tab="Employees" key="1">
								<TipzTable
									sorting={null}
									onSortingChange={s => null}
									onRowClick={({ userId }) => {
										this.props.navigate(`/${this.props.params.placeId}/employees/${userId}/`, {
											state: { backable: true },
										});
									}}
									loading={this.loading}
									head={[
										{
											key: 'user.avatarUrl',
											title: '',
											width: 50,
											render: value => <Avatar size={50} src={value} icon={<UserOutlined />} />,
										},
										{
											key: 'user.firstName',
											title: 'Name',
											sortable: true,
											render: (_, row) => `${row.user.firstName} ${row.user.lastName}`,
										},
										{
											key: 'percent',
											title: 'Percent',
											sortable: true,
											render: v => parseFloat((v * 100).toFixed(2)) + '%',
										},
										{
											key: 'qr',
											title: 'Personal QR card',
											render: (v, r) => {
												const qr = this.data!.qrs.find(qr => qr.userId === r.user.id);
												return (
													<>
														{qr && (
															<Button
																style={{ marginRight: 10 }}
																onClick={async e => {
																	e.preventDefault();
																	await QRInfoModal.show(qr.unique_id);
																	await this.reload();
																}}
															>
																Show {qr.unique_id}
															</Button>
														)}
														<Button
															type={qr ? 'default' : 'primary'}
															onClick={async e => {
																e.stopPropagation();
																const qrId = await SelectQRCodeModal.show(
																	Number(this.props.params.placeId),
																	r.user.id,
																	'null',
																);
																if (qrId) {
																	await manager_qrCodeAttachGroup(
																		qrId,
																		Number(this.props.params.groupId),
																	);
																	await this.reload();
																}
															}}
														>
															{qr ? 'Change QR' : 'Attach QR'}
														</Button>
													</>
												);
											},
										},
										{
											key: 'id',
											title: 'Actions',
											render: (v, r) => {
												return (
													<Popover content="Remove employee from this group">
														<Button
															onClick={e => {
																e.stopPropagation();
																this.handleRemoveEmployee(r.userId);
															}}
															danger
															type="text"
															icon={<DeleteOutlined />}
														/>
													</Popover>
												);
											},
										},
									]}
									rows={this.data.relations}
								/>
							</Tabs.TabPane>
							<Tabs.TabPane tab="Tips &amp; Reviews" key="2">
								<div style={{ paddingLeft: 10 }}>
									<TableController
										noSection
										params={{ query: '' }}
										load={this.handleLoadReviews}
										onTablePropsUpdate={this.handleReviewsTablePropsUpdate}
										columns={[
											{
												key: 'rating',
												title: '',
												render: value => (
													<div
														style={{
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															fontSize: 20,
															width: 40,
															height: 40,
															borderRadius: 20,
															background: value === 4 ? '#deeec9' : '#c9eec9',
															border: '1px solid #e0e0e0',
														}}
													>
														<span style={{ color: value === 4 ? '#818f00' : '#008f07' }}>
															{value}
														</span>
													</div>
												),
												width: 50,
											},
											{
												key: 'user.avatarUrl',
												titleClass: 'cell-center',
												cellClass: 'cell-center',
												title: '',
												render: value => {
													return (
														<img
															alt=""
															src={value}
															style={{ width: 50, height: 50, borderRadius: '50%' }}
														/>
													);
												},
												width: 70,
											},
											{
												key: 'user.firstName',
												title: 'Employee name',
												sortable: true,
												render: (_, row) => `${row.user.firstName} ${row.user.lastName}`,
											},
											{
												key: 'text',
												title: 'Review',
												width: 500,
												sortable: true,
												render: value => value || <i>No text</i>,
											},
											{
												key: 'incomingTx.amount',
												title: 'Tip left',
												sortable: true,
											},
											{
												key: 'created_at',
												title: 'Date',
												sortable: true,
												render: value => <>{moment(value).format('DD.MM HH:mm')}</>,
											},
										]}
									/>
								</div>
							</Tabs.TabPane>
						</Tabs>
					</TPContent>
				</TPage>
			</div>
		);
	}
}

export default withNavigateParams(GroupCard);
