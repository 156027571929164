const authErrorsRu: Record<string, string> = {
	'AUTH:INVALID_PHONE': 'Некорректный телефон',
	'AUTH:USER_NOT_FOUND': 'Пользователь не найден',
	'AUTH:VERIFICATION_EXPIRED': 'Проверочный код истёк',
	'AUTH:TRY_AGAIN_IN_MINUTE': 'Пожалуйста, попробуйте ещё раз через минуту',
	'AUTH:INVALID_CODE': 'Неправильный код',
	'AUTH:PREVIOUS_CODE_NOT_EXPIRED': 'Вы сможете получить новый код через 2 минуты после предыдущего',
	'AUTH:DATABASE_ERROR': 'Ошибка базы данных. Пожалуйста, попробуйте ещё раз',
};

export default authErrorsRu;
