import { PureComponent } from 'react';

import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react';

import cn from 'classnames';

import { DatePicker, Input } from 'antd';
import { TableController } from '../../controls/TableController';
import { autobind } from 'core-decorators';
import { admin_fetchReviews } from '../../lib/api-web/reviews';

import './style.scss';

import withNavigateParams from '../../utils/withNavigateParams';
import { NavigateFunction } from 'react-router';

import { Sorting } from '../../web-types/Sorting';
import moment from 'moment';
import dayjs from 'dayjs';
import places from '../../stores/places';
import { EntitySelect } from '../../controls/EntitySelect';
import { admin_fetchPlaces } from '../../lib/api-web';
import formatMoney from '../../utils/formatMoney';

const { RangePicker } = DatePicker;

@observer
class AdminIncomingTxsPage extends PureComponent<{ navigate: NavigateFunction; params: any; state: any }> {
	@observable contentVisible = false;
	@observable total: number | null = null;
	@observable params: { query: string; startDate: string | null; endDate: string | null; placeId: number | null } = {
		query: '',
		startDate: null,
		endDate: null,
		placeId: null,
	};

	@autobind
	handleTablePropsUpdate(contentVisible: boolean, total: number | null, rowsPerPage: number) {
		this.contentVisible = contentVisible;
		this.total = total;
	}

	@autobind
	async handleLoad(
		params: { query: string; startDate: string | null; endDate: string | null },
		offset: number,
		limit: number,
		sorting: Sorting | null,
	) {
		return admin_fetchReviews(offset, limit, {
			query: params.query,
			startDate: params.startDate,
			endDate: params.endDate,
			placeId: this.params.placeId ? Number(this.params.placeId) : null,
			sorting,
		});
	}

	render() {
		return (
			<div key="two" className={cn('page', { hidden: !this.contentVisible })}>
				<div className="page-head">
					<div className="page-title">
						<h2>
							Tips and reviews of {places.place?.name} {this.total !== null ? `(${this.total})` : ``}
						</h2>
					</div>
					<div className="page-actions">
						<RangePicker
							ranges={{
								'Today': [dayjs(), dayjs()],
								'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
							}}
							onChange={value => {
								if (value) {
									this.params.startDate = value[0]?.toISOString() || null;
									this.params.endDate = value[1]?.toISOString() || null;
								} else {
									this.params.startDate = null;
									this.params.endDate = null;
								}
							}}
						/>
						<EntitySelect
							loader={async (query, id) => {
								const resp = await admin_fetchPlaces(0, 1000, {
									query,
									sorting: { key: 'name', direction: 'ASC' },
								});
								if (!resp.result) {
									return [];
								}
								return resp.data.rows.map(r => ({
									id: r.id,
									title: r.name,
									avatarUrl: r.logoUrl,
								}));
							}}
							selectedId={this.params.placeId}
							onSelect={id => {
								if (this.params.placeId !== id) {
									this.params = { ...toJS(this.params), placeId: id };
								}
							}}
						/>
						<Input.Search
							type="search"
							onSearch={value => (this.params.query = value)}
							placeholder="Search review..."
						/>
					</div>
				</div>
				<section className="section">
					<TableController
						noSection
						// onRowClick={({ id }) => {
						// 	this.props.navigate(`/${this.props.params.placeId}/employees/${id}/`, {
						// 		state: { backable: true },
						// 	});
						// }}
						params={this.params}
						load={this.handleLoad}
						onTablePropsUpdate={this.handleTablePropsUpdate}
						columns={[
							{
								key: 'rating',
								title: <span style={{ fontSize: 16 }}>⭐</span>,
								sortable: true,
								titleClass: 'cell-center',
								cellClass: 'cell-center',
								render: value => (
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											fontSize: 20,
											width: 40,
											height: 40,
											borderRadius: 20,
											background: value === 4 ? '#deeec9' : '#c9eec9',
											border: '1px solid #e0e0e0',
										}}
									>
										<span style={{ color: value === 4 ? '#818f00' : '#008f07' }}>{value}</span>
									</div>
								),
								width: 64,
							},
							{
								key: 'user.avatarUrl',
								titleClass: 'cell-center',
								cellClass: 'cell-center',
								title: '',
								render: value => {
									return (
										<img
											alt=""
											src={value}
											style={{ width: 50, height: 50, borderRadius: '50%' }}
										/>
									);
								},
								width: 70,
							},
							{
								key: 'place.name',
								title: 'Place',
								sortable: true,
								render: (_, row) => `${row.place.name}`,
							},
							{
								key: 'user.firstName',
								title: 'Employee name',
								sortable: true,
								render: (_, row) => `${row.user.firstName} ${row.user.lastName}`,
							},
							{
								key: 'text',
								title: 'Review',
								maxWidth: 600,
								minWidth: 120,
								sortable: true,
								render: value => value || <i>No text</i>,
							},
							{
								key: 'incomingTx.amount',
								title: 'Tip left',
								minWidth: 100,
								sortable: true,
								render: (value, row) => <>{formatMoney(value, 'AED')}</>,
							},
							{
								key: 'incomingTx.status',
								title: 'Status',
								minWidth: 100,
								sortable: true,
								render: (value, row) => <span style={{ color: value === 'routed' ? 'green' : 'red' }}>{value}</span>,
							},
							{
								key: 'created_at',
								title: 'Date',
								sortable: true,
								render: (value, row) => <>{moment(value).format('DD.MM HH:mm')}</>,
							},
						]}
					/>
				</section>
			</div>
		);
	}
}

export default withNavigateParams(AdminIncomingTxsPage);
