import Modal from 'antd/lib/modal/Modal';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { PureComponent } from 'react';
import modals from '../../stores/modals';
import { Form, Input, Select } from 'antd';
import UserPlaceRole from '../../web-types/UserPlaceRole';
import user from '../../stores/user';
import InternalRole from '../../web-types/InternalRole';

export interface AddEmployeeModalProps {
	title: string;
	myRoleInThisPlace: UserPlaceRole | null;
	defaultValue?: string;
	helpText?: string;
	validator?: (t: string) => boolean;
	onDismiss: () => void;
	onConfirm: (data: { role: UserPlaceRole; phone: string }) => void;
}

@observer
export default class AddEmployeeModal extends PureComponent<AddEmployeeModalProps> {
	static async prompt(
		title: string,
		myRoleInThisPlace: UserPlaceRole | null,
		defaultValue?: string,
		validator?: (t: string) => boolean,
		helpText?: string,
	): Promise<{ role: UserPlaceRole; phone: string } | null> {
		return new Promise<{ role: UserPlaceRole; phone: string } | null>((resolve, reject) => {
			modals.show((close: () => void) => (
				<AddEmployeeModal
					title={title}
					myRoleInThisPlace={myRoleInThisPlace}
					validator={validator}
					defaultValue={defaultValue}
					helpText={helpText}
					onConfirm={url => {
						close();
						resolve(url);
					}}
					onDismiss={() => {
						close();
						resolve(null);
					}}
				/>
			));
		});
	}

	@observable value: string = this.props.defaultValue || '';
	@observable role: UserPlaceRole = UserPlaceRole.EMPLOYEE;

	render() {
		const isValid = this.props.validator ? this.props.validator(this.value) : !!this.value.length;

		return (
			<Modal
				title={this.props.title}
				okButtonProps={{ disabled: !isValid }}
				onCancel={() => this.props.onDismiss()}
				visible={true}
				closable={false}
				onOk={() => {
					this.props.onConfirm({ role: this.role, phone: this.value });
				}}
			>
				<Form>
					<Form.Item>
						<Select value={this.role} onChange={e => (this.role = e)}>
							<Select.Option
								value={UserPlaceRole.EMPLOYEE}
								disabled={
									user.user!.role !== InternalRole.ADMIN &&
									user.user!.role !== InternalRole.SALES &&
									(this.props.myRoleInThisPlace === null ||
										this.props.myRoleInThisPlace === UserPlaceRole.EMPLOYEE)
								}
							>
								Employee
							</Select.Option>
							<Select.Option
								value={UserPlaceRole.ADMIN}
								disabled={
									user.user!.role !== InternalRole.ADMIN &&
									user.user!.role !== InternalRole.SALES &&
									(this.props.myRoleInThisPlace === null ||
										this.props.myRoleInThisPlace === UserPlaceRole.OWNER)
								}
							>
								Admin
							</Select.Option>
							<Select.Option
								value={UserPlaceRole.OWNER}
								disabled={
									user.user!.role !== InternalRole.ADMIN && user.user!.role !== InternalRole.SALES
								}
							>
								Owner
							</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item validateStatus={isValid ? 'success' : 'error'} help={this.props.helpText}>
						<Input
							autoFocus={true}
							style={{ width: '100%' }}
							placeholder="Enter value..."
							value={this.value}
							onKeyPress={e =>
								e.key === 'Enter' ? this.props.onConfirm({ role: this.role, phone: this.value }) : null
							}
							onChange={e => (this.value = e.target.value)}
						/>
					</Form.Item>
				</Form>
			</Modal>
		);
	}
}
