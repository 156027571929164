import React, { PureComponent } from 'react';

import { observer } from 'mobx-react';
import { NavigateFunction } from 'react-router';
import withNavigateParams from '../../utils/withNavigateParams';
import { observable } from 'mobx';
import {
	admin_deletePlace,
	admin_fetchPlace,
	manager_introducePlaceEmployee,
	manager_invitePlaceEmployee,
	manager_requestWithdrawal,
	manager_updatePlaceName,
	manager_updatePlacePaymentData,
	manager_uploadPlaceAvatar,
} from '../../lib/api-web';
import toaster from '../../stores/toaster';
import AdminPlaceData from '../../web-types/AdminPlaceData';
import { Button, Menu, Spin, Typography } from 'antd';
import {
	PlusOutlined,
	HomeOutlined,
	UploadOutlined,
	DeleteOutlined,
	EditOutlined,
	DollarCircleOutlined,
} from '@ant-design/icons';
import { TPage, TPContent, TPSidebar, TPSidebarHeader } from '../../controls/TPage';
import Avatar from 'antd/lib/avatar/avatar';
import AvatarUploadModal from '../../global-modals/AvatarUploadModal';
import dataUriToBlob from '../../utils/dataUriToBlob';
import { StatDiv, StatHeader, StatPlate, StatPlateTitle, StatPlateValue } from '../../controls/StatHeader';
import PromptModal from '../../global-modals/PromptModal';
import handleError from '../../utils/handleError';
import { AdminPlaceMetaData } from '../../web-types/AdminPlaceMetaData';
import formatNumber from '../../utils/formatNumber';
import formatMoney from '../../utils/formatMoney';
import isValidPhone from '../../utils/isValidPhone';
import ConfirmModal from '../../global-modals/ConfirmModal';
import BackButton from '../../controls/BackButton';
import AddEmployeeModal from '../../global-modals/AddEmployeeModal';
import QRModal from '../../global-modals/QRModal';
import user from '../../stores/user';
import SimpleEmployeeModal from '../../global-modals/SimpleEmployeeModal';
import WithdrawModal from '../../global-modals/WithdrawModal';

interface ChainPlaceCardProps {
	navigate: NavigateFunction;
	params: { placeId: string; chainId: string };
	state: { backable: boolean } | null;
}

@observer
class ChainPlaceCard extends PureComponent<ChainPlaceCardProps> {
	@observable loading = false;
	@observable avatarUploading = false;
	@observable nameEditing = false;
	@observable employeeInviting = false;
	@observable paymentDataEditing = false;
	@observable placeDeleting = false;
	@observable data: AdminPlaceData | null = null;
	@observable metadata: AdminPlaceMetaData | null = null;
	@observable imageUrl: string | null = null;

	async reload() {
		this.loading = true;
		try {
			const result = await admin_fetchPlace(Number(this.props.params.placeId));
			if (result.result) {
				this.data = result.data.data;
				this.metadata = result.data.metadata;
			} else {
				toaster.show({
					type: 'error',
					message: result.error,
				});
			}
		} catch (err) {
			toaster.show({
				type: 'error',
				message: (err as Error).message,
			});
		}
		this.loading = false;
	}

	componentDidMount() {
		this.reload();
	}

	componentDidUpdate(prevProps: ChainPlaceCardProps) {
		if (prevProps.params.placeId !== this.props.params.placeId) {
			this.reload();
		}
	}

	handleClick(info: any) {
		console.log('info: ', info);
	}

	async handleAvatarUpload() {
		const newAvatarDataUri = await AvatarUploadModal.prompt();
		if (newAvatarDataUri) {
			this.avatarUploading = true;
			try {
				await manager_uploadPlaceAvatar(
					Number(this.props.params.placeId),
					await dataUriToBlob(newAvatarDataUri),
				);
				await this.reload();
			} catch (err) {
				handleError(err);
			}
			this.avatarUploading = false;
		}
	}

	async handleEditName() {
		const newName = await PromptModal.prompt('Change place name', this.data!.name);
		if (newName) {
			this.nameEditing = true;
			try {
				await manager_updatePlaceName(Number(this.props.params.placeId), newName);
				await this.reload();
			} catch (err) {
				handleError(err);
			}
			this.nameEditing = false;
		}
	}

	async handleEditPaymentDetails() {
		const newPaymentData = await PromptModal.prompt(
			'Change place payment details',
			this.data!.payment_data,
			undefined,
			undefined,
			'textarea',
		);
		if (newPaymentData) {
			this.paymentDataEditing = true;
			try {
				await manager_updatePlacePaymentData(Number(this.props.params.placeId), newPaymentData);
				await this.reload();
			} catch (err) {
				handleError(err);
			}
			this.paymentDataEditing = false;
		}
	}

	async handleDelete() {
		const descision = await ConfirmModal.prompt(
			'Are you sure?',
			'This action is irreversible. You will lose all the data: employees, transactions, QR codes. Are you absolutely sure?',
			'Yes',
		);
		if (!descision) {
			return;
		}
		this.placeDeleting = true;
		try {
			const response = await admin_deletePlace(Number(this.props.params.placeId));
			if (response.result) {
				this.placeDeleting = false;
				toaster.show({ message: 'You successfully deleted the place', type: 'success' });
				this.props.navigate(`/chain/${this.props.params.chainId}/places/`);
			} else {
				handleError(response.error);
			}
		} catch (err) {
			handleError(err);
		}
	}

	async handleAddEmployee() {
		const result = await AddEmployeeModal.prompt(
			'Enter employee phone number',
			user.user!.places.find(p => p.id === Number(this.props.params.placeId))?.role || null,
			'+971',
			isValidPhone,
			'Please, enter UAE phone number of employee',
		);
		if (result) {
			const { role, phone } = result;
			this.employeeInviting = true;
			try {
				const response = await manager_invitePlaceEmployee(Number(this.props.params.placeId), phone, role);
				if (response.result) {
					const url = `${document.location.origin}/invite/${response.data}/`;
					await QRModal.prompt('Show this QR code to your employee', url);
				} else {
					handleError(response.error);
				}
			} catch (err) {
				handleError(err);
			}
			this.employeeInviting = false;
		}
	}

	async handleSimpleAddEmployee() {
		const phoneRole = await AddEmployeeModal.prompt(
			'Enter employee phone number',
			user.user!.places.find(p => p.id === Number(this.props.params.placeId))?.role || null,
			'+971',
			isValidPhone,
			'Please, enter UAE phone number of the employee',
		);
		if (phoneRole === null) {
			return;
		}
		const firstName = await PromptModal.prompt('Enter employee first name', '');
		if (firstName === null) {
			return;
		}
		const lastName = await PromptModal.prompt('Enter employee last name', '');
		if (lastName === null) {
			return;
		}
		this.employeeInviting = true;
		try {
			const response = await manager_introducePlaceEmployee(
				Number(this.props.params.placeId),
				phoneRole.phone,
				firstName,
				lastName,
				phoneRole.role,
			);
			if (response.result) {
				await this.reload();
			} else {
				handleError(response.error);
			}
		} catch (err) {
			handleError(err);
		}
		this.employeeInviting = false;
	}

	async handleExtraSimpleAddEmployee() {
		const result = await SimpleEmployeeModal.prompt(
			'Add employee',
			user.user!.places.find(p => p.id === Number(this.props.params.placeId))?.role || null,
		);
		if (!result) {
			return;
		}
		this.employeeInviting = true;
		try {
			const response = await manager_introducePlaceEmployee(
				Number(this.props.params.placeId),
				result.phone,
				result.firstName,
				result.lastName,
				result.role,
			);
			if (response.result) {
				await this.reload();
			} else {
				handleError(response.error);
			}
		} catch (err) {
			handleError(err);
		}
		this.employeeInviting = false;
	}

	async requestWithdrawal() {
		const result = await WithdrawModal.prompt(this.data?.payment_data);
		if (!result) {
			return;
		}
		try {
			await manager_requestWithdrawal(Number(this.props.params.placeId), result.amount, result.comment);

			await this.reload();
		} catch (err) {
			handleError(err);
			return;
		}
	}

	render() {
		if (!this.data || !this.metadata) {
			return <Spin />;
		}

		return (
			<div className="page">
				<div className="page-head">
					<div className="page-title">
						<Typography.Title
							level={4}
							style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
						>
							<BackButton props={this.props} /> Place {this.data.name}
						</Typography.Title>
					</div>
					<div className="page-actions"></div>
				</div>
				<TPage className="section">
					<TPSidebar>
						<TPSidebarHeader>
							{this.avatarUploading ? (
								<Avatar size={120} src={null} icon={<Spin />} />
							) : (
								<Avatar size={120} src={this.data.logoUrl} icon={<HomeOutlined />} />
							)}
						</TPSidebarHeader>
						<Menu inlineIndent={12} selectable={false} onClick={this.handleClick} mode="inline">
							<Menu.Item
								key="1"
								icon={<UploadOutlined />}
								onClick={e => {
									e.domEvent.preventDefault();
									this.handleAvatarUpload();
								}}
							>
								Upload new image
							</Menu.Item>
							<Menu.Item
								key="2"
								icon={this.nameEditing ? <Spin /> : <EditOutlined />}
								onClick={e => {
									e.domEvent.preventDefault();
									this.handleEditName();
								}}
							>
								Edit name
							</Menu.Item>
							<Menu.Item
								key="5"
								icon={this.paymentDataEditing ? <Spin /> : <DollarCircleOutlined />}
								onClick={e => {
									e.domEvent.preventDefault();
									this.handleEditPaymentDetails();
								}}
							>
								Edit payout details
							</Menu.Item>
							<Menu.Item
								key="3"
								icon={<PlusOutlined />}
								onClick={e => {
									e.domEvent.preventDefault();
									this.handleExtraSimpleAddEmployee();
								}}
							>
								Add new employee
							</Menu.Item>
							<Menu.Divider />
							<Menu.Item
								key="4"
								danger
								icon={<DeleteOutlined />}
								onClick={e => {
									e.domEvent.preventDefault();
									this.handleDelete();
								}}
							>
								Delete place
							</Menu.Item>
						</Menu>
					</TPSidebar>
					<TPContent>
						<StatHeader>
							<StatPlate>
								<StatPlateTitle>Employees</StatPlateTitle>
								<StatPlateValue>{formatNumber(this.metadata.employees_count)}</StatPlateValue>
							</StatPlate>
							<StatDiv />
							<StatPlate>
								<StatPlateTitle>7d tips volume</StatPlateTitle>
								<StatPlateValue>{formatMoney(this.metadata.tips_volume_7d)}</StatPlateValue>
							</StatPlate>
							<StatDiv />
							<StatPlate>
								<StatPlateTitle>Avg. tip volume</StatPlateTitle>
								<StatPlateValue>{formatMoney(this.metadata.average_tip_30d)}</StatPlateValue>
							</StatPlate>
							<StatPlate>
								<StatPlateTitle>
									Balance{' '}
									<Button
										size="small"
										onClick={e => {
											this.requestWithdrawal();
										}}
									>
										Withdraw
									</Button>
								</StatPlateTitle>
								<StatPlateValue>{formatMoney(this.metadata.balance)}</StatPlateValue>
							</StatPlate>
						</StatHeader>
					</TPContent>
				</TPage>
			</div>
		);
	}
}

export default withNavigateParams(ChainPlaceCard);
