export default function getSearchParams(): Record<string, string> {
	const s = document.location.search;
	if (s) {
		const res = new URLSearchParams(s);
		const obj: Record<string, string> = {};
		for (let [key, value] of res.entries()) {
			obj[key] = value;
		}
		return obj;
	} else {
		return {};
	}
}
