export default function isValidPhone(phone: string) {
	const clean = phone
		.replaceAll('+', '')
		.replaceAll('-', '')
		.replaceAll(' ', '')
		.replaceAll('(', '')
		.replaceAll(')', '');
	if (phone.startsWith('+7') && clean.length !== 11) {
		return false;
	}
	if (clean.length < 9) {
		return false;
	}
	return true;
}
