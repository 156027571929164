import { autorun, computed, observable } from 'mobx';
import CompactPlace from '../web-types/CompactPlace';
import user from './user';

export class PlacesStore {
	@observable currentPlaceId: null | number = null;
	@observable places: CompactPlace[] = [];

	@observable currentPlaceRelation: null | CompactPlace = null;

	selectPlaceId(placeId: number, navigate: (to: string) => void) {
		const [, , , _placeId, section, ...rest] = document.location.href.split('/');
		if (!isNaN(Number(_placeId))) {
			if (Number(_placeId) !== placeId) {
				console.log('rest: ', rest);
				if (section) {
					navigate(`/` + [placeId, section, ...rest].join('/'));
				} else {
					navigate(`/${placeId}`);
				}
			}
		} else {
			navigate(['', placeId, ''].join('/'));
		}
	}

	constructor() {
		autorun(() => {
			if (user.user) {
				this.places = user.user.places;
			}
		});
	}

	@computed get place() {
		return (this.currentPlaceId ? this.places.find(p => p.id === this.currentPlaceId) : null) || null;
	}
}

const places = new PlacesStore();
//@ts-ignore
window.places = places;
export default places;
