import { PureComponent } from 'react';

import { observable } from 'mobx';
import { observer } from 'mobx-react';
import cn from 'classnames';

import withNavigateParams from '../../utils/withNavigateParams';
import { NavigateFunction, Outlet } from 'react-router';
import places from '../../stores/places';

import { Sorting } from '../../web-types/Sorting';

import './style.scss';
import moment from 'moment';
import { TableController } from '../../controls/TableController';
import { autobind } from 'core-decorators';
import { Button, Input, Popover } from 'antd';
import QRScanModal from '../../global-modals/QRScanModal';
import { manager_fetchQRCodes } from '../../lib/api-web/qr-codes';
import { EditOutlined } from '@ant-design/icons';
import QRInfo from '../../global-modals/QRInfoModal';
import MessageModal from '../../global-modals/MessageModal';

@observer
class QRCodesPage extends PureComponent<{ navigate: NavigateFunction; params: any; state: any }> {
	@observable contentVisible = false;
	@observable total: number | null = null;
	@observable params: { query: string } = { query: '' };

	@observable employeeInviting = false;

	@autobind
	handleTablePropsUpdate(contentVisible: boolean, total: number | null, rowsPerPage: number) {
		this.contentVisible = contentVisible;
		this.total = total;
	}

	@autobind
	async handleLoad(params: { query: string }, offset: number, limit: number, sorting: Sorting | null) {
		return manager_fetchQRCodes(this.props.params.placeId, 'any', 'any', offset, limit, {
			query: params.query,
			sorting,
		});
	}

	@autobind
	async handleScanQR() {
		const qrLink = await QRScanModal.prompt();
		if (qrLink) {
			const pathname = new URL(qrLink).pathname;
			if (!pathname.startsWith('/r/')) {
				await MessageModal.show(
					'Error',
					`You've scanned one-time QR code printed on the receipt. Please, scan card with QR code provided to you by Tipz.`,
				);
			} else {
				const unique_id = pathname.split('/r/')[1];
				await QRInfo.show(unique_id);
			}
		}
	}

	render() {
		if (this.props.params.employeeId) {
			return <Outlet />;
		}
		return (
			<div key="two" className={cn('page', { hidden: !this.contentVisible })}>
				<div className="page-head">
					<div className="page-title">
						<h2>
							QR codes of {places.place?.name} {this.total !== null ? `(${this.total})` : ``}
						</h2>
					</div>
					<div className="page-actions">
						<Input.Search
							style={{ marginRight: 15 }}
							type="search"
							onSearch={value => (this.params.query = value)}
							placeholder="Search code..."
						/>
						<Button type="primary" onClick={this.handleScanQR}>
							Scan QR
						</Button>
					</div>
				</div>
				<section className="section">
					<TableController
						noSection
						onRowClick={({ unique_id }) => {
							QRInfo.show(unique_id);
						}}
						params={this.params}
						load={this.handleLoad}
						onTablePropsUpdate={this.handleTablePropsUpdate}
						columns={[
							{
								key: 'user.avatarUrl',
								titleClass: 'cell-center',
								cellClass: 'cell-center',
								title: '',
								render: value => {
									return (
										<img
											alt=""
											src={value}
											style={{ width: 50, height: 50, borderRadius: '50%' }}
										/>
									);
								},
								width: 70,
							},
							{
								key: 'user.firstName',
								title: 'Employee name',
								sortable: true,
								render: (value, row) =>
									row.user ? `${row.user.firstName} ${row.user.lastName}` : <i>User not assigned</i>,
							},
							{
								key: 'unique_id',
								title: 'Unique ID',
								sortable: true,
							},
							{
								key: 'last_tx_date',
								title: 'Last tx date',
								minWidth: 120,
								sortable: true,
								render: (value, row) => (value ? moment(value).format('DD.MM HH:mm') : 'Never'),
							},
							{
								key: 'id',
								title: 'Actions',
								width: 90,
								render: (id, { unique_id }) => (
									<>
										<Popover content="Reattach QR code">
											<Button
												type="text"
												onClick={e => {
													e.stopPropagation();
													QRInfo.show(unique_id);
												}}
												icon={<EditOutlined />}
											/>
										</Popover>
									</>
								),
							},
						]}
					/>
				</section>
			</div>
		);
	}
}

export default withNavigateParams(QRCodesPage);
