import { autorun, computed, observable } from 'mobx';
import CompactChain from '../web-types/CompactChain';
import user from './user';

export class ChainsStore {
	@observable currentChainId: null | number = null;
	@observable chains: CompactChain[] = [];

	@observable currentChainRelation: null | CompactChain = null;

	selectChainId(chainId: number, navigate: (to: string) => void) {
		const [, , , , _chainId, section, ...rest] = document.location.href.split('/');
		if (!isNaN(Number(_chainId))) {
			if (Number(_chainId) !== chainId) {
				console.log('rest: ', rest);
				if (section) {
					navigate(`/chain/` + [chainId, section, ...rest].join('/'));
				} else {
					navigate(`/chain/${chainId}`);
				}
			}
		} else {
			navigate(['chain', chainId, ''].join('/'));
		}
	}

	constructor() {
		autorun(() => {
			if (user.user) {
				this.chains = user.user.chains;
			}
		});
	}

	@computed get chain() {
		return (this.currentChainId ? this.chains.find(p => p.id === this.currentChainId) : null) || null;
	}
}

const chains = new ChainsStore();
//@ts-ignore
window.chains = chains;
export default chains;
