import React, { PureComponent } from 'react';

import { observable } from 'mobx';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { Avatar, Button, Input, Popover, Typography } from 'antd';
import { EditOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';

import withNavigateParams from '../../utils/withNavigateParams';
import { NavigateFunction, Outlet } from 'react-router';
import places from '../../stores/places';

import { Sorting } from '../../web-types/Sorting';

import './style.scss';
import MetricChange from '../../controls/MetricChange';
import { EmployeeRow, manager_fetchEmployees } from '../../lib/api-web/employees';
import { TableController } from '../../controls/TableController';
import { autobind } from 'core-decorators';
import AddEmployeeModal from '../../global-modals/AddEmployeeModal';
import QRModal from '../../global-modals/QRModal';
import { manager_introducePlaceEmployee, manager_invitePlaceEmployee } from '../../lib/api-web';
import user from '../../stores/user';
import handleError from '../../utils/handleError';
import isValidPhone from '../../utils/isValidPhone';
import PromptModal from '../../global-modals/PromptModal';
import BackButton from '../../controls/BackButton';
import i18n from '../../stores/i18n';

@observer
class EmployeesPage extends PureComponent<{ navigate: NavigateFunction; params: any; state: any }> {
	@observable contentVisible = false;
	@observable total: number | null = null;
	@observable params: { query: string } = { query: '' };

	@observable employeeInviting = false;

	controller = React.createRef<TableController<{ query: string }, EmployeeRow>>();

	@autobind
	handleTablePropsUpdate(contentVisible: boolean, total: number | null, rowsPerPage: number) {
		this.contentVisible = contentVisible;
		this.total = total;
	}

	@autobind
	async handleLoad(params: { query: string }, offset: number, limit: number, sorting: Sorting | null) {
		return manager_fetchEmployees(
			{ offset, limit, sorting },
			{ placeId: this.props.params.placeId, query: params.query, notInGroupId: null, notInPlaceId: null },
		);
	}

	async handleSimpleAddEmployee() {
		const phoneRole = await AddEmployeeModal.prompt(
			'Enter employee phone number',
			user.user!.places.find(p => p.id === Number(this.props.params.placeId))?.role || null,
			'+971',
			isValidPhone,
			'Please, enter UAE phone number of the employee',
		);
		if (phoneRole === null) {
			return;
		}
		const firstName = await PromptModal.prompt('Enter employee first name', '');
		if (firstName === null) {
			return;
		}
		const lastName = await PromptModal.prompt('Enter employee last name', '');
		if (lastName === null) {
			return;
		}
		this.employeeInviting = true;
		try {
			const response = await manager_introducePlaceEmployee(
				Number(this.props.params.placeId),
				phoneRole.phone,
				firstName,
				lastName,
				phoneRole.role,
			);
			if (response.result) {
				await this.controller.current?.load();
			} else {
				handleError(response.error);
			}
		} catch (err) {
			handleError(err);
		}
		this.employeeInviting = false;
	}

	async handleAddEmployee() {
		const result = await AddEmployeeModal.prompt(
			'Enter employee phone number',
			user.user!.places.find(p => p.id === Number(this.props.params.placeId))?.role || null,
			'+971',
			isValidPhone,
			'Please, enter UAE phone number of employee',
		);
		if (result) {
			const { role, phone } = result;
			this.employeeInviting = true;
			try {
				const response = await manager_invitePlaceEmployee(Number(this.props.params.placeId), phone, role);
				if (response.result) {
					const url = `${document.location.origin}/invite/${response.data}/`;
					await QRModal.prompt('Show this QR code to your employee', url);
				} else {
					handleError(response.error);
				}
			} catch (err) {
				handleError(err);
			}
			this.employeeInviting = false;
		}
	}

	render() {
		if (this.props.params.employeeId) {
			return <Outlet />;
		}
		return (
			<div key="two" className={cn('page', { hidden: !this.contentVisible })}>
				<div className="page-head">
					<div className="page-title">
						<Typography.Title
							level={4}
							style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
						>
							<BackButton props={this.props} /> {i18n.m.EMPLOYEES_OF} {places.place?.name}{' '}
							{this.total !== null ? `(${this.total})` : ``}
						</Typography.Title>
					</div>
					<div className="page-actions">
						<Input.Search
							style={{ marginRight: 15 }}
							type="search"
							onSearch={value => (this.params.query = value)}
							placeholder={i18n.m.SEARCH_EMPLOYEE}
						/>
						<Button
							type="primary"
							icon={<PlusOutlined />}
							onClick={e => {
								e.preventDefault();
								this.handleSimpleAddEmployee();
							}}
						>
							{i18n.m.ADD}
						</Button>
					</div>
				</div>
				<TableController
					ref={this.controller}
					onRowClick={({ id }) => {
						this.props.navigate(`/${this.props.params.placeId}/employees/${id}/`, {
							state: { backable: true },
						});
					}}
					params={this.params}
					load={this.handleLoad}
					onTablePropsUpdate={this.handleTablePropsUpdate}
					columns={[
						{
							key: 'avatarUrl',
							title: '',
							render: value => <Avatar size={50} src={value} icon={<UserOutlined />} />,
							width: 50,
						},
						{
							key: 'firstName',
							title: i18n.m.NAME,
							sortable: true,
							render: (value, row) => `${row.firstName} ${row.lastName}`,
						},
						{
							key: 'up_text_role',
							title: i18n.m.ROLE,
							sortable: true,
							render: (value, row) => (i18n.m as any)['ROLE_' + value] || value,
						},
						{
							key: 'rating_7d',
							width: 100,
							titleClass: 'cell-center',
							cellClass: 'cell-center',
							title: i18n.m.RATING_7D,
							sortable: true,
							render: (value, row) => (
								<MetricChange
									className="table-rating"
									toFixed={2}
									trimZeroz={false}
									newValue={row.rating_7d}
									oldValue={0}
								/>
							),
						},
						{
							key: 'rating_30d',
							width: 100,
							titleClass: 'cell-center',
							cellClass: 'cell-center',
							title: i18n.m.RATING_30D,
							sortable: true,
							render: (value, row) => (
								<MetricChange
									className="table-rating"
									toFixed={2}
									trimZeroz={false}
									newValue={row.rating_30d}
									oldValue={0}
								/>
							),
						},
						{
							key: 'rating_mnth',
							width: 100,
							titleClass: 'cell-center',
							cellClass: 'cell-center',
							title: i18n.m.RATING_MONTH,
							sortable: true,
							render: (value, row) => (
								<MetricChange
									className="table-rating"
									toFixed={2}
									trimZeroz={false}
									newValue={row.rating_mnth}
									oldValue={0}
								/>
							),
						},
						{
							key: 'rating_week',
							width: 100,
							titleClass: 'cell-center',
							cellClass: 'cell-center',
							title: i18n.m.RATING_WEEK,
							sortable: true,
							render: (value, row) => (
								<MetricChange
									className="table-rating"
									toFixed={2}
									trimZeroz={false}
									newValue={row.rating_week}
									oldValue={0}
								/>
							),
						},
						{
							key: 'id',
							title: i18n.m.ACTIONS,
							width: 70,
							render: id => (
								<>
									<Popover content="Edit employee">
										<Button type="text" icon={<EditOutlined />} />
									</Popover>
								</>
							),
						},
					]}
				/>
			</div>
		);
	}
}

export default withNavigateParams(EmployeesPage);
