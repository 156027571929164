import formatNumber from './formatNumber';

export default function formatMoney(value: number, currency: 'AED' | '$' = 'AED') {
	const v = formatNumber(value || 0, currency === '$' ? ',' : ' ');
	if (currency === '$') {
		return '$' + v;
	} else {
		return v + ' AED';
	}
}
