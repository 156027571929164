export default function TotalTipsVolumeIcon() {
	return (
		<svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M20.125 4V18.721C20.1251 18.818 20.0982 18.913 20.0474 18.9943C19.9967 19.0756 19.9244 19.1398 19.8394 19.179L11.5 23.03L3.16056 19.18C3.07521 19.1407 3.00272 19.0762 2.95199 18.9945C2.90125 18.9128 2.87449 18.8174 2.87498 18.72V4H0.958313V2H22.0416V4H20.125ZM4.79165 4V17.745L11.5 20.845L18.2083 17.745V4H4.79165ZM7.66665 8H15.3333V10H7.66665V8ZM7.66665 12H15.3333V14H7.66665V12Z"
				fill="white"
			/>
		</svg>
	);
}
