import React, { PureComponent } from 'react';

import { observer } from 'mobx-react';
import { NavigateFunction } from 'react-router';
import withNavigateParams from '../../utils/withNavigateParams';
import { observable, toJS } from 'mobx';
import toaster from '../../stores/toaster';
import { Button, Menu, Popover, Spin, Tabs, Typography } from 'antd';
import {
	UploadOutlined,
	DeleteOutlined,
	EditOutlined,
	UserOutlined,
	CrownOutlined,
	LockOutlined,
	UsergroupAddOutlined,
} from '@ant-design/icons';
import { TPage, TPContent, TPSidebar, TPSidebarHeader } from '../../controls/TPage';
import Avatar from 'antd/lib/avatar/avatar';
import AvatarUploadModal from '../../global-modals/AvatarUploadModal';
import dataUriToBlob from '../../utils/dataUriToBlob';
import PromptModal from '../../global-modals/PromptModal';
import handleError from '../../utils/handleError';
import ConfirmModal from '../../global-modals/ConfirmModal';
import BackButton from '../../controls/BackButton';
import {
	admin_fetchEmployee,
	admin_updateEmployeeName,
	admin_updateEmployeePassword,
	EmployeeRowExt,
	manager_fetchEmployeePlaces,
	manager_uploadEmployeeAvatar,
} from '../../lib/api-web/employees';
import { autobind } from 'core-decorators';
import { Sorting } from '../../web-types/Sorting';
import { TableController } from '../../controls/TableController';
import {
	manager_attachEmployee,
	manager_attachEmployeeToChain,
	manager_detachEmployee,
	manager_detachEmployeeFromChain,
} from '../../lib/api-web';
import EmployeeRoleModal from '../../global-modals/EmployeeRoleModal';
import UserPlaceRole from '../../web-types/UserPlaceRole';
import SelectPlaceModal from '../../global-modals/SelectPlaceModal';
import SelectChainModal from '../../global-modals/SelectChainModal';
import UserChainRole from '../../web-types/UserChainRole';

interface ChainManagerCardProps {
	navigate: NavigateFunction;
	params: { chainId: string; employeeId: string };
	state: { backable: boolean } | null;
}

@observer
class ChainManagerCard extends PureComponent<ChainManagerCardProps> {
	@observable loading = false;
	@observable avatarUploading = false;
	@observable nameEditing = false;
	@observable employeeDeleting = false;
	@observable imageUrl: string | null = null;
	@observable data: EmployeeRowExt | null = null;
	@observable reviewsContentVisible = false;
	@observable reviewsTotal: number | null = null;
	@observable transactionsContentVisible = false;
	@observable transactionsTotal: number | null = null;

	@observable placesParams = { query: '' };

	placesTCRef = React.createRef<any>();

	@autobind
	handleReviewsTablePropsUpdate(reviewsContentVisible: boolean, reviewsTotal: number | null, rowsPerPage: number) {
		this.reviewsContentVisible = reviewsContentVisible;
		this.reviewsTotal = reviewsTotal;
	}

	@autobind
	handlePlacesTablePropsUpdate() {}

	@autobind
	handleTransactionsTablePropsUpdate(
		transactionsContentVisible: boolean,
		transactionsTotal: number | null,
		rowsPerPage: number,
	) {
		this.transactionsContentVisible = transactionsContentVisible;
		this.transactionsTotal = transactionsTotal;
	}

	async reload() {
		this.loading = true;
		try {
			const result = await admin_fetchEmployee(Number(this.props.params.employeeId));
			if (result.result) {
				this.data = result.data;
				this.placesParams = { ...toJS(this.placesParams) };
				this.placesTCRef.current?.load();
			} else {
				toaster.show({
					type: 'error',
					message: result.error,
				});
			}
		} catch (err) {
			toaster.show({
				type: 'error',
				message: (err as Error).message,
			});
		}
		this.loading = false;
	}

	componentDidMount() {
		this.reload();
	}

	componentDidUpdate(prevProps: ChainManagerCardProps) {
		if (prevProps.params.employeeId !== this.props.params.employeeId) {
			this.reload();
		}
	}

	handleClick(info: any) {
		console.log('info: ', info);
	}

	async handleAvatarUpload() {
		const newAvatarDataUri = await AvatarUploadModal.prompt();
		if (newAvatarDataUri) {
			this.avatarUploading = true;
			try {
				await manager_uploadEmployeeAvatar(
					Number(this.props.params.chainId),
					Number(this.props.params.employeeId),
					await dataUriToBlob(newAvatarDataUri),
				);
				await this.reload();
			} catch (err) {
				handleError(err);
			}
			this.avatarUploading = false;
		}
	}

	async handleAttachToPlace() {
		const placeId = await SelectPlaceModal.show(Number(this.props.params.chainId), null);
		if (!placeId) {
			return;
		}
		const data = await EmployeeRoleModal.prompt('Select employee role', UserPlaceRole.OWNER);
		if (!data) {
			return;
		}
		this.nameEditing = true;
		try {
			const response = await manager_attachEmployee(
				Number(this.props.params.employeeId),
				placeId,
				data.role,
				data.text_role,
			);
			if (response.result) {
				this.nameEditing = false;
				toaster.show({ message: 'You successfully attached the employee', type: 'success' });
				await this.reload();
			} else {
				handleError(response.error);
			}
		} catch (err) {
			handleError(err);
		}
	}

	async handleAttachToChain() {
		const chainId = await SelectChainModal.show(null);
		if (!chainId) {
			return;
		}
		const data = await EmployeeRoleModal.prompt('Select employee role', UserPlaceRole.OWNER);
		if (!data) {
			return;
		}
		this.nameEditing = true;
		try {
			const response = await manager_attachEmployeeToChain(
				Number(this.props.params.employeeId),
				chainId,
				data.role as any as UserChainRole,
				data.text_role,
			);
			if (response.result) {
				this.nameEditing = false;
				toaster.show({ message: 'You successfully attached the employee', type: 'success' });
				await this.reload();
			} else {
				handleError(response.error);
			}
		} catch (err) {
			handleError(err);
		}
	}

	//
	async handleEditPassword() {
		const newPassword = await PromptModal.prompt('Enter new password', '');
		if (newPassword) {
			this.nameEditing = true;
			try {
				await admin_updateEmployeePassword(Number(this.props.params.employeeId), newPassword);
				await this.reload();
				toaster.show({ message: 'You successfully changed employee password', type: 'success' });
			} catch (err) {
				handleError(err);
			}
			this.nameEditing = false;
		}
	}

	async handleEditName() {
		const newFirstName = await PromptModal.prompt('Enter employee first name', this.data!.firstName);
		if (newFirstName) {
			const newLastName = await PromptModal.prompt('Enter employee last name', this.data!.lastName);
			if (newLastName) {
				this.nameEditing = true;
				try {
					await admin_updateEmployeeName(Number(this.props.params.employeeId), newFirstName, newLastName);
					await this.reload();
				} catch (err) {
					handleError(err);
				}
				this.nameEditing = false;
			}
		}
	}

	async handleDelete() {
		const descision = await ConfirmModal.prompt(
			'Are you sure?',
			'This action is irreversible. Are you absolutely sure?',
			'Yes',
		);
		if (!descision) {
			return;
		}
		this.employeeDeleting = true;
		try {
			const response = await manager_detachEmployeeFromChain(
				Number(this.props.params.employeeId),
				Number(this.props.params.chainId),
			);
			if (response.result) {
				this.employeeDeleting = false;
				toaster.show({ message: 'You successfully deleted the employee', type: 'success' });
				this.props.navigate(`/chain/${Number(this.props.params.chainId)}/managers/`);
			} else {
				handleError(response.error);
			}
		} catch (err) {
			handleError(err);
		}
	}

	@autobind
	async handleLoadPlaces(params: { query: string }, offset: number, limit: number, sorting: Sorting | null) {
		return manager_fetchEmployeePlaces(Number(this.props.params.employeeId), offset, limit, {
			query: params.query,
			sorting,
			chainId: null,
		});
	}

	render() {
		if (!this.data) {
			return <Spin />;
		}

		return (
			<div className="page">
				<div className="page-head">
					<div className="page-title">
						<Typography.Title
							level={4}
							style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
						>
							<BackButton props={this.props} /> Chain manager {this.data.firstName} {this.data.lastName},{' '}
							{this.data.up_text_role}
						</Typography.Title>
					</div>
					<div className="page-actions"></div>
				</div>
				<TPage className="section">
					<TPSidebar>
						<TPSidebarHeader>
							{this.avatarUploading ? (
								<Avatar size={120} src={null} icon={<Spin />} />
							) : (
								<Avatar size={120} src={this.data.avatarUrl} icon={<UserOutlined />} />
							)}
							<div style={{ marginTop: 15 }}>
								{this.data.phone} <Button type="dashed" size="small" icon={<EditOutlined />} />
							</div>
						</TPSidebarHeader>
						<Menu inlineIndent={12} selectable={false} onClick={this.handleClick} mode="inline">
							<Menu.Item
								key="1"
								icon={<UploadOutlined />}
								onClick={e => {
									e.domEvent.preventDefault();
									this.handleAvatarUpload();
								}}
							>
								Upload new avatar
							</Menu.Item>
							<Menu.Item
								key="2"
								icon={this.nameEditing ? <Spin /> : <EditOutlined />}
								onClick={e => {
									e.domEvent.preventDefault();
									this.handleEditName();
								}}
							>
								Edit name
							</Menu.Item>
							<Menu.Item
								key="3"
								icon={this.nameEditing ? <Spin /> : <LockOutlined />}
								onClick={e => {
									e.domEvent.preventDefault();
									this.handleEditPassword();
								}}
							>
								Update password
							</Menu.Item>
							<Menu.Item
								key="4"
								icon={this.nameEditing ? <Spin /> : <UsergroupAddOutlined />}
								onClick={e => {
									e.domEvent.preventDefault();
									this.handleAttachToPlace();
								}}
							>
								Attach to place
							</Menu.Item>
							<Menu.Divider />
							<Menu.Item
								key="5"
								danger
								icon={<DeleteOutlined />}
								onClick={e => {
									e.domEvent.preventDefault();
									this.handleDelete();
								}}
							>
								Remove employee
							</Menu.Item>
						</Menu>
					</TPSidebar>
					<TPContent>
						<Tabs defaultActiveKey="1" type="card">
							<Tabs.TabPane tab="Places" key="places">
								<div style={{ paddingLeft: 10 }}>
									<TableController
										noSection
										ref={this.placesTCRef}
										params={this.placesParams}
										load={this.handleLoadPlaces}
										onTablePropsUpdate={this.handlePlacesTablePropsUpdate}
										columns={[
											{
												key: 'logoUrl',
												title: '',
												render: value => (
													<img
														src={value}
														alt=""
														style={{ width: 50, height: 50, borderRadius: '50%' }}
													/>
												),
												width: 50,
											},
											{
												key: 'name',
												title: 'Place',
												sortable: true,
											},
											{
												key: 'upr_role',
												title: 'Role',
												sortable: true,
											},
											{
												key: 'upr_text_role',
												title: 'Position',
												sortable: true,
											},
											{
												key: 'id',
												title: 'Actions',
												width: 70,
												render: (id, row) => (
													<div
														style={{
															display: 'flex',
															flexDirection: 'row',
															alignItems: 'center',
														}}
													>
														<Popover content="Change role">
															<Button
																type="text"
																icon={<CrownOutlined />}
																onClick={async e => {
																	e.stopPropagation();
																	e.preventDefault();
																	const data = await EmployeeRoleModal.prompt(
																		'Select employee role',
																		UserPlaceRole.OWNER,
																		(row as any).upr_role,
																		(row as any).upr_text_role,
																	);
																	if (!data) {
																		return;
																	}
																	try {
																		const response = await manager_attachEmployee(
																			Number(this.props.params.employeeId),
																			Number(id),
																			data.role,
																			data.text_role,
																		);
																		if (response.result) {
																			toaster.show({
																				message:
																					'You successfully changed the employee role',
																				type: 'success',
																			});
																			await this.reload();
																		} else {
																			handleError(response.error);
																		}
																	} catch (err) {
																		handleError(err);
																	}
																}}
															/>
														</Popover>
														<Popover content="Remove from place">
															<Button
																type="text"
																danger
																icon={<DeleteOutlined />}
																onClick={async e => {
																	e.preventDefault();
																	e.stopPropagation();
																	const descision = await ConfirmModal.prompt(
																		'Are you sure?',
																		'You will be able to attach employee to place again, if needed.',
																		'Yes',
																	);
																	if (!descision) {
																		return;
																	}
																	this.employeeDeleting = true;
																	try {
																		const response = await manager_detachEmployee(
																			Number(this.props.params.employeeId),
																			Number(id),
																		);
																		if (response.result) {
																			this.employeeDeleting = false;
																			toaster.show({
																				message:
																					'You successfully detached the employee',
																				type: 'success',
																			});
																			this.reload();
																		} else {
																			handleError(response.error);
																		}
																	} catch (err) {
																		handleError(err);
																	}
																}}
															/>
														</Popover>
													</div>
												),
											},
										]}
									/>
								</div>
							</Tabs.TabPane>
						</Tabs>
					</TPContent>
				</TPage>
			</div>
		);
	}
}

export default withNavigateParams(ChainManagerCard);
