enum OutgoingTxStatus {
	CREATED = 'created',
	AUTO_APPROVED = 'auto-approved',
	NEED_MANUAL_APPROVE = 'need-manual-approve',
	MANUAL_APPROVED = 'manual-approved',
	PROCESSING = 'processing',
	SUCCESS = 'success',
	FAILED = 'failed',
	REJECTED = 'rejected',
}

export default OutgoingTxStatus;
