import { frontmanGet, writerPost } from '.';
import objectToFormData from '../../utils/objectToFormData';
import AdminChainData from '../../web-types/AdminChainData';
import OwnerChainMetaData from '../../web-types/OwnerChainMetaData';
import { Sorting } from '../../web-types/Sorting';
import NetworkResponse from '../responses/NetworkResponse';
import NetworkTableResponse from '../responses/NetworkTableResponse';
import { AdminChainMetaData } from '../../web-types/AdminPlaceMetaData';

export function admin_fetchChains(
	offset: number,
	limit: number,
	params: { query: string; sorting: Sorting | null },
): NetworkTableResponse<AdminChainData> {
	return frontmanGet('/chains/admin/list', { offset, limit, params: JSON.stringify(params) });
}

export function admin_fetchChain(
	chainId: number,
): Promise<NetworkResponse<{ data: AdminChainData; metadata: AdminChainMetaData }>> {
	return frontmanGet(`/chains/admin/${chainId}`);
}

export function admin_createChain(name: string): Promise<NetworkResponse<number>> {
	return writerPost('POST', '/chains/admin/create', null, { name });
}

export function admin_deleteChain(chainId: number): Promise<NetworkResponse<void>> {
	return writerPost('DELETE', `/chains/admin/${chainId}/`);
}

export function manager_fetchChainStatistics(chainId: number): Promise<NetworkResponse<OwnerChainMetaData>> {
	return frontmanGet(`/dashboard/chain/${chainId}/statistics`);
}

export function manager_uploadChainAvatar(chainId: number, avatar: Blob): Promise<NetworkResponse<string>> {
	return writerPost('POST', `/chains/${chainId}/avatar`, null, objectToFormData({ avatar }));
}

export function manager_updateChainName(chainId: number, newName: string) {
	return writerPost('POST', `/chains/${chainId}/name/`, null, { newName });
}

export function manager_fetchChain(chainId: number): Promise<NetworkResponse<AdminChainData>> {
	return frontmanGet(`/chains/${chainId}`);
}

export function manager_detachEmployeeFromChain(userId: number, chainId: number): Promise<NetworkResponse<any>> {
	return writerPost('POST', `/chains/${chainId}/detach/${userId}/`);
}
