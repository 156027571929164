import Modal from 'antd/lib/modal/Modal';
import { observer } from 'mobx-react';
import { PureComponent } from 'react';
import modals from '../../stores/modals';
import QRCodeImg from '../../controls/QRCode';

export interface QRModalProps {
	title: string;
	data: string;
	onDismiss: () => void;
}

@observer
export default class QRModal extends PureComponent<QRModalProps> {
	static async prompt(title: string, data: string): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			modals.show((close: () => void) => (
				<QRModal
					title={title}
					data={data}
					onDismiss={() => {
						close();
						resolve();
					}}
				/>
			));
		});
	}

	render() {
		return (
			<Modal
				title={this.props.title}
				onCancel={() => this.props.onDismiss()}
				visible={true}
				closable={false}
				onOk={() => {
					this.props.onDismiss();
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						width: '100%',
					}}
				>
					<QRCodeImg style={{ width: 300, height: 300 }} data={this.props.data} />
					{this.props.data.startsWith('http') ? (
						<div style={{ marginTop: 0, marginBottom: 20, textAlign: 'center' }}>
							<div>Or share this link:</div>
							<div>{this.props.data}</div>
						</div>
					) : null}
				</div>
			</Modal>
		);
	}
}
