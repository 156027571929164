import { frontmanGet } from '.';
import NetworkResponse from '../responses/NetworkResponse';

export interface PlaceStatistics {
	placeId: number;
	logoUrl: string | null;
	name: string;
	employeeCount: number;
	days: {
		pageViews: number;
		reviews: number;
		tipsCount: number;
		tipsVolume: number;
	}[];
}

export function admin_fetchPlacesStatistics({
	chainId,
}: {
	chainId?: number;
}): Promise<NetworkResponse<PlaceStatistics[]>> {
	return frontmanGet(`/dashboard/place-statistics`, { chainId });
}
