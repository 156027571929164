import Modal from 'antd/lib/modal/Modal';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { PureComponent } from 'react';
import modals from '../../stores/modals';
import { Form, InputNumber } from 'antd';
import { EntitySelect } from '../../controls/EntitySelect';
import { admin_fetchPlaces } from '../../lib/api';

export interface AddQRModalProps {
	title: string;
	onDismiss: () => void;
	onConfirm: (data: { placeId: number; amount: number; startsFrom: number }) => void;
}

@observer
export default class AddQRModal extends PureComponent<AddQRModalProps> {
	static async prompt(title: string): Promise<{ placeId: number; amount: number; startsFrom: number } | null> {
		return new Promise<{ placeId: number; amount: number; startsFrom: number } | null>((resolve, reject) => {
			modals.show((close: () => void) => (
				<AddQRModal
					title={title}
					onConfirm={data => {
						close();
						resolve(data);
					}}
					onDismiss={() => {
						close();
						resolve(null);
					}}
				/>
			));
		});
	}

	@observable placeId: number | null = null;
	@observable amount: number = 20;
	@observable startsFrom: number = 100100;

	render() {
		const isValid = this.placeId !== null;

		return (
			<Modal
				title={this.props.title}
				okButtonProps={{ disabled: !isValid }}
				onCancel={() => this.props.onDismiss()}
				visible={true}
				closable={false}
				onOk={() => {
					this.props.onConfirm({ placeId: this.placeId!, amount: this.amount, startsFrom: this.startsFrom });
				}}
			>
				<Form>
					<Form.Item label="Select place" validateStatus={isValid ? 'success' : 'error'}>
						<EntitySelect
							loader={async (query, id) => {
								const resp = await admin_fetchPlaces(0, 1000, {
									query,
									sorting: { key: 'name', direction: 'ASC' },
								});
								if (!resp.result) {
									return [];
								}
								return resp.data.rows.map(r => ({
									id: r.id,
									title: r.name,
									avatarUrl: r.logoUrl,
								}));
							}}
							selectedId={this.placeId}
							onSelect={id => (this.placeId = id)}
						/>
					</Form.Item>
					<Form.Item label="Enter amount">
						<InputNumber
							autoFocus={true}
							style={{ width: '100%' }}
							placeholder="Enter value..."
							value={this.amount}
							onChange={e => (this.amount = e!)}
						/>
					</Form.Item>
					<Form.Item label="Starts from">
						<InputNumber
							autoFocus={true}
							style={{ width: '100%' }}
							placeholder="Enter value..."
							value={this.startsFrom}
							onChange={e => (this.startsFrom = e!)}
						/>
					</Form.Item>
				</Form>
			</Modal>
		);
	}
}
