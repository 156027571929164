import React from 'react';

import { observer } from 'mobx-react';

import { PureComponent } from 'react';
import cn from 'classnames';

import './style.scss';
import withNavigateParams from '../../utils/withNavigateParams';
import { NavigateFunction } from 'react-router';
import { Avatar, Button, Menu, Spin } from 'antd';
import { observable } from 'mobx';
import handleError, { handleResponseError } from '../../utils/handleError';
import {
	EmployeeRowExt,
	manager_fetchProfile,
	manager_updateProfileName,
	manager_updateProfilePassword,
	manager_updateProfilePhone,
	manager_uploadProfileAvatar,
} from '../../lib/api-web/employees';
import user from '../../stores/user';
import { UserOutlined, EditOutlined, UploadOutlined, PhoneOutlined, LockOutlined } from '@ant-design/icons';
import { TPage, TPSidebar, TPSidebarHeader, TPContent } from '../../controls/TPage';
import AvatarUploadModal from '../../global-modals/AvatarUploadModal';
import PromptModal from '../../global-modals/PromptModal';
import dataUriToBlob from '../../utils/dataUriToBlob';
import isValidPhone from '../../utils/isValidPhone';
import i18n from '../../stores/i18n';

@observer
class ProfilePage extends PureComponent<{ navigate: NavigateFunction; params: any; state: any }> {
	@observable loading = true;
	@observable showSpinner = false;
	@observable contentVisible = false;
	@observable avatarUploading = false;
	@observable nameEditing = false;
	@observable passwordEditing = false;
	@observable phoneEditing = false;
	@observable imageUrl: string | null = null;
	@observable data!: EmployeeRowExt;
	firstTime: boolean = true;

	async reload() {
		this.loading = true;
		this.contentVisible = false;
		let spinnerTimeout = null;
		spinnerTimeout = setTimeout(() => {
			spinnerTimeout = null;
			this.showSpinner = true;
		}, 1000);
		try {
			const response = await manager_fetchProfile(user.user!.id);
			if (!response.result) {
				handleResponseError(response);
			} else {
				this.data = response.data;
				if (spinnerTimeout) {
					clearTimeout(spinnerTimeout);
				}
				setTimeout(() => {
					this.contentVisible = true;
				}, 100);
			}
		} catch (err) {
			handleError(err);
			return;
		}
		this.loading = false;
		this.showSpinner = false;
	}

	componentDidMount() {
		this.reload();
	}

	async updateProfile() {
		// const result = await WithdrawModal.prompt();
		// if (!result) {
		// 	return;
		// }
		// this.withdrawalLoading = true;
		// try {
		// 	await manager_requestWithdrawal(this.props.params.placeId, result.amount, result.comment);
		// 	await this.loadPlaceData(this.props.params.placeId);
		// } catch (err) {
		// 	handleError(err);
		// 	return;
		// } finally {
		// 	this.withdrawalLoading = false;
		// }
	}

	async handleAvatarUpload() {
		const newAvatarDataUri = await AvatarUploadModal.prompt();
		if (newAvatarDataUri) {
			this.avatarUploading = true;
			try {
				await manager_uploadProfileAvatar(Number(user.user!.id), await dataUriToBlob(newAvatarDataUri));
				await this.reload();
			} catch (err) {
				handleError(err);
			}
			this.avatarUploading = false;
		}
	}

	async handleEditName() {
		const newFirstName = await PromptModal.prompt(i18n.m.ENTER_NEW_FIRST_NAME, this.data!.firstName);
		if (newFirstName) {
			const newLastName = await PromptModal.prompt(i18n.m.ENTER_NEW_LAST_NAME, this.data!.lastName);
			if (newLastName) {
				this.nameEditing = true;
				try {
					await manager_updateProfileName(Number(user.user!.id), newFirstName, newLastName);
					await this.reload();
				} catch (err) {
					handleError(err);
				}
				this.nameEditing = false;
			}
		}
	}

	async handleEditPhone() {
		const newPhone = await PromptModal.prompt(i18n.m.ENTER_NEW_PHONE, this.data.phone, isValidPhone);
		if (newPhone) {
			this.phoneEditing = true;
			try {
				await manager_updateProfilePhone(Number(user.user!.id), newPhone);
				await this.reload();
			} catch (err) {
				handleError(err);
			}
			this.phoneEditing = false;
		}
	}

	async handleEditPassword() {
		const newPassword = await PromptModal.prompt(i18n.m.ENTER_NEW_PASSWORD, '');
		if (newPassword) {
			this.passwordEditing = true;
			try {
				await manager_updateProfilePassword(Number(user.user!.id), newPassword);
				await this.reload();
			} catch (err) {
				handleError(err);
			}
			this.passwordEditing = false;
		}
	}

	render() {
		if (this.loading) {
			return (
				<div key="one" className="page">
					{this.showSpinner ? <Spin /> : null}
				</div>
			);
		}
		return (
			<div key="two" className={cn('page', { hidden: !this.contentVisible })}>
				<div className="page-head">
					<div className="page-title">
						<h2>{i18n.m.MY_PROFILE}</h2>
					</div>
					<div className="page-actions">
						{/* <Tooltip
							title={
								this.placeStatistics.balance < 100
									? 'You can withdraw only more than 100 AED at once'
									: ''
							}
						>
							<Button
								loading={this.withdrawalLoading}
								onClick={() => {
									this.requestWithdrawal();
								}}
								disabled={this.placeStatistics.balance < 100}
							>
								Withdraw
							</Button>
						</Tooltip> */}
					</div>
				</div>
				<TPage className="section">
					<TPSidebar>
						<TPSidebarHeader>
							{this.avatarUploading ? (
								<Avatar size={120} src={null} icon={<Spin />} />
							) : (
								<Avatar size={120} src={this.data.avatarUrl} icon={<UserOutlined />} />
							)}
							<div style={{ marginTop: 15 }}>
								{this.data.phone}{' '}
								<Button
									type="dashed"
									size="small"
									icon={<EditOutlined />}
									onClick={() => this.handleEditPhone()}
									loading={this.phoneEditing}
								/>
							</div>
						</TPSidebarHeader>
						<Menu inlineIndent={12} selectable={false} mode="inline">
							<Menu.Item
								key="1"
								icon={<UploadOutlined />}
								onClick={e => {
									e.domEvent.preventDefault();
									this.handleAvatarUpload();
								}}
							>
								{i18n.m.UPLOAD_NEW_AVATAR}
							</Menu.Item>
							<Menu.Item
								key="2"
								icon={this.nameEditing ? <Spin /> : <EditOutlined />}
								onClick={e => {
									e.domEvent.preventDefault();
									this.handleEditName();
								}}
							>
								{i18n.m.EDIT_NAME}
							</Menu.Item>
							<Menu.Item
								key="3"
								icon={this.phoneEditing ? <Spin /> : <PhoneOutlined />}
								onClick={e => {
									e.domEvent.preventDefault();
									this.handleEditPhone();
								}}
							>
								{i18n.m.EDIT_PHONE}
							</Menu.Item>
							<Menu.Item
								key="4"
								icon={this.passwordEditing ? <Spin /> : <LockOutlined />}
								onClick={e => {
									e.domEvent.preventDefault();
									this.handleEditPassword();
								}}
							>
								{i18n.m.EDIT_PASSWORD}
							</Menu.Item>
						</Menu>
					</TPSidebar>
					<TPContent>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
								flexGrow: 1,
							}}
						>
							{i18n.m.PROFILE_HELPER_TEXT}
						</div>
					</TPContent>
				</TPage>
			</div>
		);
	}
}

export default withNavigateParams(ProfilePage);
