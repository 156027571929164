import { autorun, observable } from "mobx";
import * as api from "../lib/api";
import user from "./user";

export interface Notification {
	id: number;
	text: string;
	date: string;
	link: string;
	unread: boolean;
}

class NotificationsStore {

	@observable nots: Notification[] = [];

	constructor() {
		autorun(() => {
			if (user.user?.id) {
				api.fetchNotifications().then(({ data }) => this.nots = data);
			}
		})
	}

}

const notifications = new NotificationsStore();

export default notifications;