import querystring from 'querystring';

import { FRONTMAN_API, WRITER_API } from '../../constants';
import { ITableQuery } from '../../web-types/ITableQuery';
import NetworkResponse from '../responses/NetworkResponse';

export function frontmanGetUrl(url: string, qs?: null | Record<string, any>, baseUrl?: string) {
	let qss = '';
	if (qs) {
		qss = '?' + querystring.stringify(qs);
	}
	return (baseUrl || FRONTMAN_API) + url + qss;
}

export async function frontmanGet(url: string, qs?: null | Record<string, any>, baseUrl?: string) {
	let qss = '';
	if (qs) {
		qss = '?' + querystring.stringify(qs);
	}
	return fetch((baseUrl || FRONTMAN_API) + url + qss, {
		method: 'GET',
		credentials: 'include',
		headers: {
			'Content-Type': 'application/json',
		},
	}).then(response => {
		if (response.status < 200 || response.status >= 300) {
			throw new Error(response.statusText);
		}
		return response.json();
	});
}

export async function tableGet<T>(
	url: string,
	tableQuery: ITableQuery,
	qs?: null | Record<string, any>,
): Promise<NetworkResponse<{ total: number; rows: T[] }>> {
	return await frontmanGet(url, {
		limit: tableQuery.limit,
		offset: tableQuery.offset,
		sorting: JSON.stringify(tableQuery.sorting),
		...(qs || {}),
	});
}

export async function writerPost(
	method: 'POST' | 'DELETE' | 'PATCH' | 'PUT',
	url: string,
	qs?: null | Record<string, any>,
	data?: any,
	baseUrl?: string,
) {
	let qss = '';
	if (qs) {
		qss = '?' + querystring.stringify(qs);
	}
	return fetch((baseUrl || WRITER_API) + url + qss, {
		method,
		credentials: 'include',
		headers:
			data && !(data instanceof FormData)
				? {
						'Content-Type': 'application/json',
				  }
				: {},
		body: data ? (data instanceof FormData ? data : JSON.stringify(data)) : void 0,
	}).then(response => {
		if (response.status < 200 || response.status >= 300) {
			throw new Error(response.statusText);
		}
		return response.json();
	});
}

export * from './auth';
export * from './places';
export * from './chains';
export * from './common';
