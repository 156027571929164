import { frontmanGet, writerPost } from '.';
import { GroupRow } from '../../pages/Groups/GroupCard';
import CompactPlace from '../../web-types/CompactPlace';
import CompactUserData from '../../web-types/CompactUserData';
import { ITableQuery } from '../../web-types/ITableQuery';
import { Sorting } from '../../web-types/Sorting';
import NetworkResponse from '../responses/NetworkResponse';
import NetworkTableResponse from '../responses/NetworkTableResponse';

export interface QRCodeRow {
	id: number;
	created_at: string;
	updated_at: string;
	deleted_at: string;
	unique_id: string;
	placeId: number | null;
	place: CompactPlace | null;
	userId: number | null;
	user: CompactUserData | null;
	groupId: number | null;
	group: GroupRow | null;
	last_tx_date: string;
}

export function manager_fetchQRCodes(
	placeId: number,
	userId: 'any' | 'null' | number,
	groupId: 'any' | 'null' | number,
	offset: number,
	limit: number,
	params: { query: string; sorting: Sorting | null },
): NetworkTableResponse<QRCodeRow> {
	return frontmanGet(`/qr-codes/${placeId}/list/${userId}/${groupId}`, {
		offset,
		limit,
		params: JSON.stringify(params),
	});
}

export function manager_fetchChainQRCodes(
	chainId: number,
	userId: 'any' | 'null' | number,
	groupId: 'any' | 'null' | number,
	offset: number,
	limit: number,
	params: { query: string; sorting: Sorting | null },
): NetworkTableResponse<QRCodeRow> {
	return frontmanGet(`/qr-codes/chain/${chainId}/list/${userId}/${groupId}`, {
		offset,
		limit,
		params: JSON.stringify(params),
	});
}

export function manager_fetchOutgoingTxs(
	placeId: number,
	offset: number,
	limit: number,
	params: { query: string; sorting: Sorting | null },
): NetworkTableResponse<any> {
	return frontmanGet(`/outgoing-txs/${placeId}/list`, {
		offset,
		limit,
		params: JSON.stringify(params),
	});
}

export function manager_fetchEmployeesOutgoingTxs(
	placeId: number,
	offset: number,
	limit: number,
	params: { query: string; sorting: Sorting | null },
): NetworkTableResponse<any> {
	return frontmanGet(`/outgoing-txs/${placeId}/employees`, {
		offset,
		limit,
		params: JSON.stringify(params),
	});
}

export function employee_fetchMyOutgoingTxs(
	offset: number,
	limit: number,
	params: { query: string; sorting: Sorting | null },
): NetworkTableResponse<any> {
	return frontmanGet(`/outgoing-txs/my-list`, {
		offset,
		limit,
		params: JSON.stringify(params),
	});
}

export function employee_getMyBalance(): Promise<
	NetworkResponse<{
		incoming_balance: number;
		outgoing_balance: number;
		balance: number;
	}>
> {
	return frontmanGet(`/employees/my-balance`);
}

export function employee_fetchOutgoingTxs(
	employeeId: number,
	offset: number,
	limit: number,
	params: { query: string; sorting: Sorting | null },
): NetworkTableResponse<any> {
	return frontmanGet(`/employee/${employeeId}/list`, {
		offset,
		limit,
		params: JSON.stringify(params),
	});
}

export function manager_fetchChainOutgoingTxs(
	chainId: number,
	offset: number,
	limit: number,
	params: { query: string; sorting: Sorting | null },
): NetworkTableResponse<any> {
	return frontmanGet(`/outgoing-txs/chain/${chainId}/list`, {
		offset,
		limit,
		params: JSON.stringify(params),
	});
}

export function manager_fetchQRCode(uniqueId: string): Promise<NetworkResponse<QRCodeRow>> {
	return frontmanGet(`/qr-codes/get/${uniqueId}`);
}

export function manager_qrCodeAttachEmployee(qrId: number, employeeId: number): Promise<NetworkResponse<void>> {
	return writerPost('POST', `/qr-codes/employee-attach/${qrId}/${employeeId}`);
}

export function manager_qrCodeAttachGroup(qrId: number, groupId: number): Promise<NetworkResponse<void>> {
	return writerPost('POST', `/qr-codes/group-attach/${qrId}/${groupId}`);
}

export function manager_qrCodeAttachPlace(qrId: number, placeId: number): Promise<NetworkResponse<void>> {
	return writerPost('POST', `/qr-codes/place-attach/${qrId}/${placeId}`);
}

export function manager_qrCodeDetachEmployee(qrId: number): Promise<NetworkResponse<void>> {
	return writerPost('POST', `/qr-codes/employee-detach/${qrId}`);
}

export function manager_qrCodeDetachGroup(qrId: number): Promise<NetworkResponse<void>> {
	return writerPost('POST', `/qr-codes/group-detach/${qrId}`);
}

export function manager_qrCodeDetachPlace(qrId: number): Promise<NetworkResponse<void>> {
	return writerPost('POST', `/qr-codes/place-detach/${qrId}`);
}

export function admin_createQrs(placeId: number, amount: number, startsFrom: number): Promise<NetworkResponse<void>> {
	return writerPost('POST', `/qr-codes/create/${placeId}`, {}, { amount, startsFrom });
}

export function admin_fetchQrs(
	tableQuery: ITableQuery,
	params: { notInGroupId: number | null; placeId: number | null; query: string },
): NetworkTableResponse<QRCodeRow> {
	return frontmanGet(`/qr-codes/list`, {
		offset: tableQuery.offset,
		limit: tableQuery.limit,
		params: JSON.stringify({
			sorting: tableQuery.sorting,
			...params,
		}),
	});
	// return tableGet(`/qr-codes/list`, tableQuery);
}
