import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react';

import { PureComponent } from 'react';

import './style.scss';

import withNavigateParams from '../../utils/withNavigateParams';
import { NavigateFunction } from 'react-router';

import { Sorting } from '../../web-types/Sorting';
import { Button, Popover } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { autobind } from 'core-decorators';
import AddQRModal from '../../global-modals/AddQRModal';
import { admin_createQrs, admin_fetchQrs, QRCodeRow } from '../../lib/api-web/qr-codes';
import { handleResponseError } from '../../utils/handleError';
import React from 'react';
import { TableController } from '../../controls/TableController';
import { EntitySelect } from '../../controls/EntitySelect';
import { admin_fetchPlaces } from '../../lib/api-web';
import QRInfoModal from '../../global-modals/QRInfoModal';
import moment from 'moment';

@observer
class AdminQrCodesPage extends PureComponent<{ navigate: NavigateFunction; params: any; state: any }> {
	@observable contentVisible = false;
	@observable total: number | null = null;
	@observable params: { query: string; byPlace: number | null } = { query: '', byPlace: null };

	@observable qrsCreating = false;

	controller = React.createRef<TableController<{ query: string; byPlace: number | null }, QRCodeRow>>();

	@autobind
	handleTablePropsUpdate(contentVisible: boolean, total: number | null, rowsPerPage: number) {
		this.contentVisible = contentVisible;
		this.total = total;
	}

	@autobind
	async handleLoad(
		params: { query: string; byPlace: number | null },
		offset: number,
		limit: number,
		sorting: Sorting | null,
	) {
		return admin_fetchQrs(
			{ offset, limit, sorting },
			{ query: params.query, notInGroupId: null, placeId: params.byPlace },
		);
	}

	@autobind
	async handleCreateQrs() {
		const result = await AddQRModal.prompt('Add QRs');
		if (!result) {
			return;
		}
		this.qrsCreating = true;
		try {
			const resp = await admin_createQrs(result.placeId, result.amount, result.startsFrom);
			if (!resp.result) {
				handleResponseError(resp);
			} else {
				await this.controller.current?.load();
			}
		} finally {
			this.qrsCreating = false;
		}
	}

	render() {
		return (
			<div className="page">
				<div className="page-head">
					<div className="page-title">
						<h2>QR Codes</h2>
					</div>
					<div className="page-actions">
						<EntitySelect
							loader={async (query, id) => {
								const resp = await admin_fetchPlaces(0, 1000, {
									query,
									sorting: { key: 'name', direction: 'ASC' },
								});
								if (!resp.result) {
									return [];
								}
								return resp.data.rows.map(r => ({
									id: r.id,
									title: r.name,
									avatarUrl: r.logoUrl,
								}));
							}}
							selectedId={this.params.byPlace}
							onSelect={id => {
								if (this.params.byPlace !== id) {
									this.params = { ...toJS(this.params), byPlace: id };
								}
							}}
						/>
						<Button
							style={{ marginLeft: 12 }}
							loading={this.qrsCreating}
							icon={<PlusOutlined />}
							onClick={this.handleCreateQrs}
						>
							Create QRs
						</Button>
					</div>
				</div>
				<TableController
					onRowClick={({ unique_id }) => {
						QRInfoModal.show(unique_id);
					}}
					params={this.params}
					load={this.handleLoad}
					onTablePropsUpdate={this.handleTablePropsUpdate}
					columns={[
						{
							key: 'user.avatarUrl',
							titleClass: 'cell-center',
							cellClass: 'cell-center',
							title: '',
							render: value => {
								return (
									<img alt="" src={value} style={{ width: 50, height: 50, borderRadius: '50%' }} />
								);
							},
							width: 70,
						},
						{
							key: 'user.firstName',
							title: 'Employee name',
							sortable: true,
							render: (value, row) =>
								row.user ? `${row.user.firstName} ${row.user.lastName}` : <i>User not assigned</i>,
						},
						{
							key: 'unique_id',
							title: 'Unique ID',
							sortable: true,
						},
						{
							key: 'last_tx_date',
							title: 'Last tx date',
							minWidth: 120,
							sortable: true,
							render: (value, row) => (value ? moment(value).format('DD.MM HH:mm') : 'Never'),
						},
						{
							key: 'id',
							title: 'Actions',
							width: 90,
							render: (id, { unique_id }) => (
								<>
									<Popover content="Reattach QR code">
										<Button
											type="text"
											onClick={e => {
												e.stopPropagation();
												QRInfoModal.show(unique_id);
											}}
											icon={<EditOutlined />}
										/>
									</Popover>
								</>
							),
						},
					]}
				/>
			</div>
		);
	}
}

export default withNavigateParams(AdminQrCodesPage);
